import './styles/styles.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { CssBaseline } from '@material-ui/core';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import * as BctDtos from './api/bct.dtos';
import { Provider } from 'react-redux';
import bcrapReducer from './api/bcrapReducer'
import createReduxStore from './api/createReduxStore';

const breakpointValues = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1536
}

// Use this colour for light background 
// #ffffff
// Use this colour for light background 
// #f1f1f1

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#EC008C"
        },
        secondary:{
            main: "#560089"
        },
        info: {
            main: "#000000",
            light: "#4A4A4A"
        }
    },
    breakpoints: {
        values: breakpointValues
    }
});

export interface INewsletterForm extends Partial<BctDtos.TrialSubscription> {
}

const store = createReduxStore(bcrapReducer)

ReactDOM.render(
    
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
            <App />
        </Provider>
    </ThemeProvider>,
    document.getElementById('root') as HTMLElement
    
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
