/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the reducer registry class used to control adding reducers
 * and logic to the redux store at run time.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for typing:
 *      - Reducer
 */
import { Reducer } from 'redux';

/*
 * Used for typing:
 *      - Logic
 */
import { Logic } from 'redux-logic';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for typing:
 *      - IDictionary
 */
import { IDictionary } from '../api/types/IDictionary';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * Function interface for an on reducer register callback.
 */
export interface IOnRegister {
    (reducers: IReducers, logics: ILogics): void;
}

/**
 * A dictionary of reducers
 */
export interface IReducers extends IDictionary<Reducer> {}

/**
 * A dictionary of logics
 */
export interface ILogics extends IDictionary<Logic[]> {}

/**
 * This class handles the registering and storing of redux reducers and their 
 * associated logic
 */
export class ReducerRegistry {
    private onRegister: IOnRegister | null;
    private reducers: IReducers;
    private logics: ILogics

    constructor() {
        this.onRegister = null;
        this.reducers = {};
        this.logics = {};
    }
    
    /**
     * This function gets the reducers currently registered.
     * @returns All the reducers currently registered
     */
    getReducers(): IReducers {
      return { ...this.reducers };
    }
  
    /**
     * This function gets the logics currently registered.
     * @returns All the logics currently registered
     */
    getLogics(): ILogics {
      return { ...this.logics };
    }
  
    /**
     * This function registers a reducer and its associated logic.
     * @param name The name of the reducer (where it will be placed in the store)
     * @param reducer The reducer
     * @param logic The associated logics
     */
    register(name: string, reducer?: Reducer, logic?: Logic[]): void {
        // Add reducer if it has been provided, if it exists already then replace 
        // the existing reducer.
        if (reducer) {
            this.reducers = { ...this.reducers, [name]: reducer };
        }

        // Add logics if they have been provided, if they exist already then replace 
        // the existing logics.
        if (logic) {
            this.logics = { ...this.logics, [name]: logic };
        }

        // Fire off on register callback if it has been set.
        if (this.onRegister) {
            this.onRegister(this.getReducers(), this.getLogics());
        }
    }
  
    /**
     * This function sets the callback function for when a reducer is registered.
     * @param onRegister The on reducer registration callback.
     */
    setOnRegister(onRegister: IOnRegister): void {
        this.onRegister = onRegister;
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

/**
 * Singleton reducer registery responsibly for controlling all reducers that are in
 * the redux store.
 */
const reducerRegistry = new ReducerRegistry();

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default reducerRegistry;