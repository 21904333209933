import React from 'react';
import IconEnum from '../enumerations/icons';
import Icons from '../fileImports/icons';
import ImgEnum from '../enumerations/img';
import Images from '../fileImports/images';
import { Theme, makeStyles } from '@material-ui/core';

interface ISlideProps {
    heading?: string;
    headingColour?: string;
    textRight?: boolean;
    icon?: IconEnum;
    iconSecondary?: boolean 
    image: ImgEnum;
    imageMobile: ImgEnum;
}

interface ISlideStyleProps {
    backgroundImg: string;
    backgroundImgMobile: string;
    textRight: boolean;
    headingColour: string;
    image: ImgEnum;
    icon: string;
}


const useStyles = makeStyles<Theme, ISlideStyleProps>((theme: Theme) => ({
    SlideRoot: {
        display: 'block',
        width: '100%',
        paddingBottom: props => props.image === ImgEnum.Slide3 ? '45.83%' : '56.25%',
        height: props => props.image === ImgEnum.Slide3 ? '880px' : 'auto',
        backgroundPositionX: props => props.image === ImgEnum.Slide3 ? '30%' : 'auto',
        backgroundImage: props => `url(${props.backgroundImg})`,
        backgroundSize: '100% 100%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    SlideRootMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '-14%',
        },
    },
    body: {
        position: 'absolute',
        padding: `${theme.spacing(4)}px ${theme.spacing(5)}px`,
        right: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        width: '96%',
        height: '96%',
        zIndex: 2,
        backgroundColor: 'white',
        '& h2': {
            fontSize: '66px',
            color: theme.palette.primary.main,
            [theme.breakpoints.down('lg')]: {
                fontSize: props => props.image === ImgEnum.Slide2 ? '4.5vmax' : props.image === ImgEnum.Slide3 ? '4.5em' : '3.5vw',
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '3.6em',
            },
            fontFamily: 'GTPressura, sans-serif',
            lineHeight: 1,
        },
        '@media (max-width:1900px)': {
            padding: theme.spacing(4),
        },
        [theme.breakpoints.down('md')]: {
            padding: props => props.image === ImgEnum.Slide3 ? theme.spacing(4) : theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            width: props => '100%',
            marginLeft: props => '5vw',
            marginRight: props => '2vw',
            paddingLeft: '0px',
            paddingTop: props => props.image === ImgEnum.Slide5 ? '40vw' : props.image === ImgEnum.Slide2 ? '10vw' : '22vw',
        }
    },
    icon: {
        marginLeft: '0.5rem',
        marginRight: '1vw',
        backgroundImage: props => `url(${props.icon})`,
        backgroundSize: '100% 100%',
        width: props => props.image === ImgEnum.Slide6 ? '6vw' : '5.5vw',
        height: props => props.image === ImgEnum.Slide6 ? '6vw' : '5.5vw',
        [theme.breakpoints.down('md')]: {
            marginLeft: props => props.image === ImgEnum.Slide4 ? '-1vw' : '0',
            marginRight: props => props.image === ImgEnum.Slide4 ? '0' : '1vw'
        },
        [theme.breakpoints.down('xs')]: {
            width: props => props.image === ImgEnum.Slide5 || props.image === ImgEnum.Slide6 ? '15vw' :'16vw',
            height: props => props.image === ImgEnum.Slide5 || props.image === ImgEnum.Slide6 ? '15vw' : '16vw',
            marginRight: props => props.image === ImgEnum.Slide5 || props.image === ImgEnum.Slide6 ? '4vw' : '0'
        }
    },
    header: {
        display: 'inline-flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
            alignItems: 'center'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'left',
            backgroundColor: props => props.image === ImgEnum.Slide5 ? '#FAFAFABA' : null,
            marginLeft: props => props.image === ImgEnum.Slide5 ? '-5vw' : null,
            paddingLeft: props => props.image === ImgEnum.Slide5 ? '5vw' : null
        }
    },
    block: {
        width: '61%',
        height: '70%',
        position: 'absolute',
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.primary.main,
        zIndex: 1,
        [theme.breakpoints.down('lg')]: {
            width: props => props.image === ImgEnum.Slide3 ? '70%' : '24vw',
            height: props => props.image === ImgEnum.Slide3 ? '70%' : '26vw',
            minWidth: '0',
        },
        [theme.breakpoints.down('sm')]: {
            left: 0,
            bottom: '-6%',
            height: props => '84%',
            minWidth: '45%',
            maxWidth: '45%'
        }
    },
    container: {
        position: 'relative',
        width: props => props.textRight ? '45rem' : '793px',
        height: '46rem',
        float: props => props.textRight ? 'right' : 'left',
        marginRight: props => props.textRight ? '15%' : '0',
        marginLeft: props => props.textRight ? '0' : '15%',
        marginTop: props => props.textRight ? '9%' : theme.spacing(10),
        '@media (max-width:1900px)': {
            width: props => props.textRight ? '45rem' : '42rem',
            height: '44rem',
            marginTop: props => props.textRight ? '10%' : theme.spacing(12),
        },
        [theme.breakpoints.down('lg')]: {
            width: props => props.textRight ? '43rem' : '',
            height: props => props.textRight ? '36rem' : '',
        },
        [theme.breakpoints.down('md')]: {
            width: props => props.textRight ? '30rem' : '',
            height: props => props.textRight ? '31rem' : '',
            marginTop: props => props.textRight ? '5%' : '',
            marginRight: props => props.textRight ? '10%' : '',
        },
    },
    mobileContainerTop: {
        position: 'relative',
        width: '100%',
        height: 'auto',
        '& img': {
            width: '97%',
            height: '95%',
            top: 0,
            right: 0,
            position: 'absolute'
        }
    },
    mobileImageContainer: {
        position: 'relative',
        paddingBottom: '56.25%',
        zIndex: 2
    },
    mobileBlock: {
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        width: '45%',
        height: '77%',
        left: 0,
        bottom: 0,
        zIndex: 1
    },
    mobileBody: {
        padding: theme.spacing(3),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        '& h2': {
            fontSize: '2.5em',
            color: theme.palette.primary.main,
            fontFamily: 'GTPressura, sans-serif',
        },
    }
}))

const Slide: React.FunctionComponent<ISlideProps> = props => {

    const backgroundImg = Images[props.image];
    const backgroundImgMobile = Images[props.imageMobile];
    const icon = Icons[props.icon];
    const textRight = props.textRight;
    const headingColour = props.headingColour;
    const image = props.image;
    const classes = useStyles({ backgroundImg, backgroundImgMobile, textRight, headingColour, image, icon });

    return (
        <>
            <div className={classes.SlideRoot}>
                <div className={classes.container}>
                    <div className={classes.body}>
                        <div className={classes.header} dangerouslySetInnerHTML={{ __html: `<h2>${props.heading}</h2>` }}>
                        </div>
                         {props.children}
                    </div>
                    <div className={ classes.block}>
                    </div>
                </div>
            </div>
            <div className={classes.SlideRootMobile}>
                <div className={classes.mobileContainerTop}>
                    <div className={classes.mobileImageContainer}>
                        <img src={backgroundImg} alt="" />
                    </div>
                    <div className={classes.mobileBlock}>
                    </div>
                </div>
                <div className={classes.mobileBody}>
                    <div className={classes.mobileHeader}>
                        <div className={classes.header} dangerouslySetInnerHTML={{ __html: `<h2>${props.heading}</h2>` }}>
                        </div>
                    </div>
                    {props.children}
                </div>
            </div>
        </>
    );
};

export default Slide;
