import React from 'react';
import { makeStyles, Theme, SvgIcon } from '@material-ui/core';
import { MouseIconPath, PhoneIconPath } from '../fileImports/iconPaths';
import { BRCAP_URL } from '../constants/Urls';


interface IMobileInterestProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: theme.spacing(3),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        color: theme.palette.secondary.main,
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(5)
        }
    },
    donateButton: {
        fontFamily: 'GTPressura, sans-serif',
        textAlign: 'center',
        fontWeight: 'lighter',
        fontSize: '1.7rem',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '2.75rem',
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textDecoration: 'none',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            transitionProperty: 'color, background-color',
            transitionDuration: '150ms',
            transitionTimingFunction: 'easi-in-out',
            WebkitTransitionProperty: 'width, background-color',
            WebkitTransitionDuration: '150ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        }
    },
    iconContainer: {
        fontSize: '1.3rem',
        display: 'flex',
        paddingRight: theme.spacing(1),
        '& .MuiSvgIcon-root': {
            width: '1.5em',
            height: '1.4em',
        }
    },
    hover: {
        '&:hover': {
            textDecoration: 'none',
        }
    }
}));


const MobileInterest: React.FunctionComponent<IMobileInterestProps> = () => {

    const classes = useStyles({});

    return (
        <div className={ classes.container}>
            <h2>Would like to know more about the study, or interested in joining?</h2>
            <a href="tel:1800777253" className={ classes.hover}>
                <div className={classes.donateButton}>
                    <div className={classes.iconContainer}>
                        <SvgIcon className="icon" fontSize="inherit">
                            <path d={PhoneIconPath} />
                        </SvgIcon>
                    </div>
                    1800 777 253
                </div>
            </a>
            <a href={`${BRCAP_URL}eoi`} className={classes.hover}>
                <div className={classes.donateButton}>
                    <div className={classes.iconContainer}>
                        <SvgIcon className="icon" fontSize="inherit" viewBox="4 7 32 16">
                            <path d={MouseIconPath} />
                        </SvgIcon>
                    </div>
                    REGISTER ONLINE
                </div>
            </a>
        </div>
        )
};


export default MobileInterest;