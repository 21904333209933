enum ImgEnum {
    Slide1 = 0,
    Slide2 = 1,
    Slide3 = 2,
    Slide4 = 3,
    Slide5 = 4,
    Slide6 = 5,
    Slide1Mobile = 6,
    Slide2Mobile = 7,
    Slide3Mobile = 8,
    Slide4Mobile = 9,
    Slide5Mobile = 10,
    Slide6Mobile = 11
}

export default ImgEnum;