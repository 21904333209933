const FemaleSymbol = require('../assets/icons/Icon_01_FemaleSymbol.svg');
const Gene = require('../assets/icons/Icon_02_Gene.svg');
const Star = require('../assets/icons/Icon_03_JewishStar.svg');
const Breasts = require('../assets/icons/Icon_04_Breasts.svg');
const Women = require('../assets/icons/Icon_05_Women_XljxXQ.svg');
const Nurse = require('../assets/icons/Icon_06_Nurse.svg');
const Microscope = require('../assets/icons/Icon_07_Microscope.svg');
const Donate = require('../assets/icons/Icon_08_Donate.svg');
const Ribbon = require('../assets/icons/Icon_09_Ribbon.svg');
const Mail = require('../assets/icons/Icon_10_Mail.svg');
const Location = require('../assets/icons/Icon_11_Location.svg');
const Hospitals = require('../assets/icons/Icon_12_Hospitals.svg');
const Arrow = require('../assets/icons/Icon_13_Arrow.svg');
const Comma = require('../assets/icons/Icon_13_Comma.svg');
const VideoPlayButton = require('../assets/icons/Icon_14_VideoPlayButton.svg');
const Woman = require('../assets/icons/Icon_15_Woman.svg');
const Share = require('../assets/icons/Icon_16_Share.svg');
const StaticWomen = require('../assets/staticIcons/Icon_05_Women_XljxXQ.svg');
const StaticWoman = require('../assets/staticIcons/Icon_15_Woman.svg');
const StaticNurse = require('../assets/staticIcons/Icon_06_Nurse.svg');
const StaticGene = require('../assets/staticIcons/Icon_02_Gene.svg');
const StaticBreasts = require('../assets/staticIcons/Icon_04_Breasts.svg');
const StaticHospitals = require('../assets/staticIcons/Icon_12_Hospitals.svg');
const Shirt = require('../assets/staticIcons/Icon_17_Shirt.svg');
const Phone = require('../assets/icons/v2/Icon_01_Phone.svg');

export default [
    FemaleSymbol,
    Gene,
    Star,
    Breasts,
    Women,
    Nurse,
    Microscope,
    Donate,
    Ribbon,
    Mail,
    Location,
    Hospitals,
    Arrow,
    Comma,
    VideoPlayButton,
    Woman,
    Share,
    StaticWomen,
    StaticWoman,
    StaticNurse,
    StaticGene,
    StaticBreasts,
    StaticHospitals,
    Shirt,
    Phone
]

