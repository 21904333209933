import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import {  FormikBag, withFormik, Form as FormikForm, FormikProps, Field } from 'formik';
import { INewsletterForm } from '../'
import { newsletterActions, INewsletterState, RequestState } from '../api/bcrapReducer';
import * as BctDtos from '../api/bct.dtos';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

interface IFormProps {
    register?: typeof newsletterActions.register;
    registration?: BctDtos.TrialSubscription;
    formSubmitted?: RequestState;
    darkButton?: boolean;
};


interface IFormStyleProps {
    errorFlag: boolean;
    darkButton: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    component: {
    },
    textInput1: {
        color: theme.palette.secondary.main,
        fontWeight: 500,
        padding: '15px',
        margin: '5px 0',
        marginRight: '5px',
        borderRadius: theme.spacing(4),
        border: 'none',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '1vw',
        width: "50%",
        display: "inline-flex",
        '&::placeholder': {
            color: (props: IFormStyleProps) => props.errorFlag ? "#bb0000" : theme.palette.secondary.main,
        },
        '&:focus': {
            outline: 'none',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5vw',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2vw',
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: '0',
            width: '100%',
            marginTop: '4vw',
            fontSize: '5vw',
        }
    },
    textInput2: {
        color: theme.palette.secondary.main,
        padding: '15px',
        fontWeight: 500,
        margin: '5px 0',
        marginLeft: '5px',
        borderRadius: theme.spacing(4),
        border: 'none',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '1vw',
        width: "50%",
        display: "inline-flex",
        '&::placeholder': {
            color: (props: IFormStyleProps) => props.errorFlag ? "#bb0000" : theme.palette.secondary.main,
        },
        '&:focus': {
            outline: 'none',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5vw',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2vw',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0',
            width: '100%',
            fontSize: '5vw'
        }
    },
    formButton: {
        color: (props: IFormStyleProps) => props.darkButton ? theme.palette.secondary.main : 'white',
        backgroundColor: "transparent",
        borderColor: (props: IFormStyleProps) => props.darkButton ? theme.palette.secondary.main : 'white',
        borderStyle: "solid",
        borderRadius: theme.spacing(4),
        marginLeft: 0,
        padding: theme.spacing(2.5),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        margin: theme.spacing(1),
        fontFamily: 'Roboto',
        fontSize: '1.3rem',
        fontStretch: 'condensed',
        lineHeight: '.8rem',
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: (props: IFormStyleProps) => props.darkButton ? theme.palette.secondary.main : 'white',
            color: (props: IFormStyleProps) => props.darkButton ? "white" : theme.palette.primary.main,
            transitionProperty: 'color, background-color, border-color',
            transitionDuration: '150ms',
            transitionTimingFunction: 'easi-in-out',
            WebkitTransitionProperty: 'width, background-color',
            WebkitTransitionDuration: '150ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        },
        '&:active': {
            backgroundColor: (props: IFormStyleProps) => props.darkButton ? theme.palette.secondary.main : '#ffc0e5',
            borderColor: (props: IFormStyleProps) => props.darkButton ? theme.palette.secondary.main : '#ffc0e5',
            color: "white",
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '5px',
            fontSize: '5.5vw',
            fontWeight: '500',
            fontStretch: 'condensed',
            padding: '4.5vw',
            paddingLeft: '6vw',
            paddingRight: '6vw',
        }
    },
    formNames: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('xs')]:{
            flexDirection: "column",
        }
    },
    spinner: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    registerMessage: {
        color: (props: IFormStyleProps) => props.darkButton ? "#000000c2" : "white",
        fontSize: "large",
        height: "15vw",
        [theme.breakpoints.down('xs')]: {
            marginTop: '6vw',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '7vw',
            marginLeft: '3vw'
        }

    },
    desktopOnly: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    mobileOnly: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    }

}));

type FormProps = FormikProps<INewsletterForm> & IFormProps;

const Form: React.FunctionComponent<FormProps> = ({
    values,
    submitForm,
    formSubmitted,
    darkButton
}) => {


    const [emailValid, setemailValid] = React.useState(true);
    const [formClicked, setFormClicked] = React.useState(false);
    const [errorFlag, setErrorFlag] = React.useState(false);

    const classes = useStyles({errorFlag, darkButton});

    const handleSubmit = React.useCallback((event: React.SyntheticEvent<HTMLButtonElement>) => {
        submitForm();
    }, [submitForm])

    const onSubmit = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        if (formClicked) {
            setErrorFlag(!validateForm(values));
            if (validateForm(values)) {
                if (validateEmail(values.emailAddress)) {
                    setemailValid(true);
                    handleSubmit(e)
                }
                else {
                    setemailValid(false)
                }
                
            }
        }
        else {
            setErrorFlag(true);
        }
    };



    return (
        <div className={classes.component}>
            {

                formSubmitted === undefined || formSubmitted === RequestState.None || formSubmitted === RequestState.Cancel ?
                    <>
                        {
                            formSubmitted === RequestState.Failure ?
                                <>
                                    <div className={classes.registerMessage}>
                                        <p>
                                            Your registration was unsuccessful!
                                            <div className={classes.desktopOnly}>Please confirm the details entered are correct and try again</div>
                                        </p>    
                                    </div>
                            </>
                            : ""
                        }
                        
                        <FormikForm
                            translate="yes"
                        >
                            <div className={classes.formNames}>
                                <Field
                                    autoComplete="none"
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    className={classes.textInput1}
                                    placeholder={errorFlag ? " * First Name" : "First Name"}
                                    disabled={false}
                                    onClick={() => setFormClicked(true)}
                                />
                                <Field
                                    autoComplete="none"
                                    type="text"
                                    id="surName"
                                    name="surName"
                                    className={classes.textInput2}
                                    placeholder={errorFlag ? " * Last Name" : "Last Name"}
                                    disabled={false}
                                    onClick={() => setFormClicked(true)}
                                />
                            </div>
                            <Field
                                autoComplete="none"
                                type="text"
                                id="emailAddress"
                                name="emailAddress"
                                className={classes.textInput2}
                                placeholder={errorFlag ? " * Email" : "Email"}
                                disabled={false}
                                style={{ width: '100%', marginLeft: '0', display: "flex", boxSizing: 'border-box' }}
                                onClick={() => setFormClicked(true)}
                            />
                            {
                                !emailValid ?
                                <label style={{ color: "white" }} htmlFor="email">
                                    Please enter a valid email address
                                </label> : ""
                            }

                        </FormikForm>
                        <button className={classes.formButton}
                            onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => onSubmit(e)}
                            type="button"
                        >
                            SIGN UP
                        </button>
                    </> :
                        formSubmitted === RequestState.Success ?
                        <>
                            <div className={classes.registerMessage} style={{ display: 'flex', alignItems: 'center' }}>
                                <div className={classes.desktopOnly}>
                                    <p>
                                        Thank you for registering to receive the BRCA-P Newsletter!
                                        You will receive an email confirming your registration shortly
                                    </p>
                                </div>
                                <div className={classes.mobileOnly}>
                                    <p>
                                        Thank you!
                                    </p>
                                </div>
                            </div>
                            </> :
                        formSubmitted === RequestState.Pending ?
                            <div className={classes.spinner}>
                                <CircularProgress color="primary" />
                            </div>
                            : ""
            }
        </div>
    );
};

const validateForm = (values: INewsletterForm): boolean => {
    let valid: boolean;
    valid = (values.firstName && values.firstName.length > 0) &&
        (values.surName && values.surName.length > 0) &&
        (values.emailAddress && values.emailAddress.length > 0) ? true : false;
    return valid;
     
};

const validateEmail = (email): boolean => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return (true)
    }
    return (false)
}

const handleSubmit = (
    values: INewsletterForm,
    formikBag: FormikBag<IFormProps, INewsletterForm>
): void => {
    const { setSubmitting } = formikBag;
    const { register } = formikBag.props

    validateForm(values)

    if (register) {
        setSubmitting(true);

        const registration = {
            ...formikBag.props.registration,
            firstName: values.firstName,
            surName: values.surName,
            emailAddress: values.emailAddress
        } as BctDtos.TrialSubscription;

        register(registration);
    }
};

const mapPropsToValues = (props: IFormProps): INewsletterForm => {

    const { registration } = props;

    if (registration) {
        return {
            emailAddress: registration.emailAddress,
            firstName: registration.firstName,
            surName: registration.surName,
        }
    }
    return {
        emailAddress: "",
        firstName: "",
        surName: ""
    };
};

const mapStateToProps = (
    state: INewsletterState,
): Partial<IFormProps> => {
    return {
        registration: state.registration?.data,
        formSubmitted: state.registration?.registrationState.state
    };
};

const mapDispatchToProps = (dispatch: Dispatch): Partial<IFormProps> => {
    return bindActionCreators(
        {
            register: newsletterActions.register,
        },
        dispatch
    );
}

const formikEnhancer = withFormik<(IFormProps), INewsletterForm>({
    mapPropsToValues: mapPropsToValues,
    handleSubmit: handleSubmit,
    isInitialValid: true,
    validateOnChange: false,
});

const EnhancedForm: React.ComponentType<IFormProps> = formikEnhancer(
    Form
) as any;


export default connect<ReturnType<typeof mapStateToProps>,
    typeof mapDispatchToProps,
    IFormProps,
    INewsletterState>(mapStateToProps, mapDispatchToProps)(EnhancedForm);
