import React from 'react';
import { Container, makeStyles, Theme } from '@material-ui/core';
import Donate from '../assets/icons/Icon_08_Donate.svg';
import Wear from '../assets/icons/v2/Icon_09_WaysToHelp_Wear.svg';
import Ribbon from '../assets/icons/Icon_09_Ribbon.svg';
import Share from '../assets/icons/Icon_16_Share.svg';
import appSettings from '../helpers/appSettings';
const { detect } = require('detect-browser');

interface IInfoGraphicContainer2Props {
    heading?: string;
}

interface IInfoGraphicContainer2StyleProps {
    browserName: string
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    infoGraphicRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fafafa',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(12)
        },
    },
    container: {
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '5rem',
        maxWidth: '90%',
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        paddingBottom: '8rem',
        [theme.breakpoints.down('md')]: {
            maxWidth: 'none',
            padding: '0',
        }
    },
    desktop: {
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    infoGraphicContainerBody: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6)
        },
        [theme.breakpoints.down('sm')]: {
            display: 'inline-flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            maxWidth: (props: IInfoGraphicContainer2StyleProps) => props.browserName === "ie" ? '' : '50%',
            width: (props: IInfoGraphicContainer2StyleProps) => props.browserName === "ie" ? '50%' : '',
            marginTop: '5%'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '10%'
        }
    },
    header: {
        color: theme.palette.primary.main,
        fontFamily: 'GTPressura, sans-serif',
        fontSize: '66px',
        paddingTop: '3rem',
        paddingBottom: '3rem',
        textAlign: 'center',
        '@media (max-width:1900px)': {
            fontSize: '4rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: (props: IInfoGraphicContainer2StyleProps) => props.browserName !== "ie" ? '7vw' : '3rem',
            padding: '0',
            paddingTop: '1.5rem'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: (props: IInfoGraphicContainer2StyleProps) => props.browserName !== "ie" ? '9vw' : '3rem',
        },
    },
    infoGraphicBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        width: '400px',
        '@media (max-width:1900px)': {
            width: 'auto'
        },
        [theme.breakpoints.down('md')]: {
            padding: 0,
            width: '14rem',
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5)
        }
    },
    iconContainer: {
        height: '10rem',
        width: '15rem',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(6),
        '& object': {
            height: '100%'
        }
    },
    shareIconContainer: {
        height: '10rem',
        width: '13rem',
        display: 'flex',
        justifyContent: 'end',
        marginBottom: theme.spacing(6),
        '& object': {
            height: '100%'
        }
    },
    iconHeading: {
        color: theme.palette.secondary.main,
        fontFamily: 'Roboto, sans-serif',
        '& h3': {
            fontWeight: '600',
            [theme.breakpoints.down('sm')]: {
                fontSize: '2.5rem',
            }
        }
    },
    iconBody: {
        padding: theme.spacing(3),
        fontSize: '21px',
        lineHeight: '28px',
        textAlign: 'center',
        fontFamily: 'Roboto, sans-serif',
        width: '17rem',
        fontWeight: 300,
        '@media (max-width:1900px)': {
            fontSize: '1.5em',
            lineHeight: 'normal',
        },
        [theme.breakpoints.down('sm')]: {
            width: '20rem',
            fontSize: '1.9em'
        }
    },
    iconButton: {
        display: 'flex',
        justifyContent: 'center'
    },
    hollowButton: {
        fontFamily: 'Roboto, sans-serif',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.2rem',
        backgroundColor: 'transparent',
        borderColor: theme.palette.secondary.main,
        border: 'solid 2px',
        borderRadius: '2.75rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
        paddingTop: '0.6rem',
        paddingBottom: '0.6rem',
        textDecoration: 'none',
        transform: 'scale(.9, 1.1)',
        color: theme.palette.secondary.main,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'none',
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            transitionProperty: 'color, background-color, border-color',
            transitionDuration: '150ms',
            transitionTimingFunction: 'easi-in-out',
            WebkitTransitionProperty: 'width, background-color',
            WebkitTransitionDuration: '150ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem'
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '8vw',
            paddingRight: '8vw',
            paddingTop: '2.2vw',
            paddingBottom: '2.2vw',
        }
    },
    hollowButtonContainer: {
        display: 'inline-flex',
        marginTop: '1rem',
        height: '4rem',
        alignItems: 'center',
        width: 'fit-content',
        marginBottom: '1vw',
        minWidth: '18rem',
        justifyContent: 'center'
    }
}))

const InfoGraphicContainer2: React.FunctionComponent<IInfoGraphicContainer2Props> = props => {

    const browser = detect();
    const browserName = browser.name;
    const classes = useStyles({ browserName });

    const donateLink = appSettings.donateUrl;
    const merchandiseLink = appSettings.merchandiseUrl;
    const fundraiseLink = appSettings.fundraiseUrl;

    return (
        <div className={classes.infoGraphicRoot}>
            <Container className={classes.container}>
                <div className={classes.desktop}>
                    <h2 className={classes.header}>
                        Other ways to help
                    </h2>
                    <div className={classes.infoGraphicContainerBody}>
                        <div className={classes.infoGraphicBody}>
                            <div className={ classes.iconContainer}>
                                {
                                    browser.name === "ie" || browser.name === "edge" ?
                                        <img src={Donate} alt="Donate" className={classes.image} />
                                        : <object id="donate" data={Donate} type="image/svg+xml" className={classes.image} >Donate</object>
                                }
                            </div>
                            <div className={classes.iconHeading}>
                                <h3>DONATE</h3>
                            </div>
                            <div className={classes.iconBody}>
                                Help prevent breast cancer for future generations.
                            </div>
                            <div className={classes.iconButton}>
                                <div className={classes.hollowButtonContainer}>
                                    <a href={donateLink} className={classes.hollowButton} target="_blank" rel="noopener noreferrer">
                                        MAKE A GIFT NOW
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={classes.infoGraphicBody}>
                            <div className={classes.iconContainer}>
                                {
                                    browser.name === "ie" || browser.name === "edge" ?
                                        <img src={Wear} alt="Wear" className={classes.image} />
                                        : <object id="wear" data={Wear} type="image/svg+xml" className={classes.image}>Wear</object>
                                }
                            </div>
                            <div className={classes.iconHeading}>
                                <h3>WEAR</h3>
                            </div>
                            <div className={classes.iconBody}>
                                Show your support by wearing the Breastolution Tee.
                            </div>
                            <div className={classes.iconButton}>
                                <div className={classes.hollowButtonContainer}>
                                    <a href={merchandiseLink} className={classes.hollowButton} target="_blank" rel="noopener noreferrer">
                                        SHOP MERCH
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={classes.infoGraphicBody}>
                            <div className={classes.iconContainer}>
                                {
                                    browser.name === "ie" || browser.name === "edge" ?
                                        <img src={Ribbon} alt="Fundraise" className={classes.image} />
                                        : <object id="fundraise" data={Ribbon} type="image/svg+xml" className={classes.image}>Fundraise</object>
                                }
                            </div>
                            <div className={classes.iconHeading}>
                                <h3>FUNDRAISE</h3>
                            </div>
                            <div className={classes.iconBody}>
                                Have a great idea to raise funds? Fantastic! We can help.
                            </div>
                            <div className={classes.iconButton}>
                                <div className={classes.hollowButtonContainer}>
                                    <a href={fundraiseLink} className={classes.hollowButton} target="_blank" rel="noopener noreferrer">
                                        REGISTER TODAY
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={classes.infoGraphicBody}>
                            <div className={classes.shareIconContainer}>
                                {
                                    browser.name === "ie" || browser.name === "edge" ?
                                        <img src={Share} alt="Share" className={classes.image} />
                                        : <object id="share" data={Share} type="image/svg+xml" className={classes.image}>Share</object>
                                }
                            </div>
                            <div className={classes.iconHeading}>
                                <h3>SHARE</h3>
                            </div>
                            <div className={classes.iconBody}>
                                Together we can change the future one share at a time!
                            </div>
                            <div className={classes.iconButton}>
                                <div className={classes.hollowButtonContainer}>
                                    <a href="https://www.instagram.com/" className={classes.hollowButton} target="_blank" rel="noopener noreferrer">
                                        SPREAD THE WORD!
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default InfoGraphicContainer2;
