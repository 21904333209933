import React from 'react';
import { LazyLoadComponent, trackWindowScroll } from 'react-lazy-load-image-component';
import { GetInstagramPosts } from '../api/bct.dtos';
import useBctGet, { RequestState } from '../hooks/useBctGet';
import { makeStyles, Theme, CircularProgress } from '@material-ui/core';
const { detect } = require('detect-browser');

interface ISupporterMessageProps {
    scrollPosition: any
}

interface ISupporterMessageStyleProps {
    browserName: string;
}

const instagramRequest = new GetInstagramPosts();

instagramRequest.hashtag = "breastolution";
instagramRequest.limit = 30;
instagramRequest.randomise = true;
instagramRequest.enabled = true;

const useStyles = makeStyles<Theme, ISupporterMessageStyleProps>((theme: Theme) => ({
    supporterContainer: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
        }
    },
    instagramFeedContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        paddingLeft: theme.spacing(16),
        paddingRight: theme.spacing(16),
        [theme.breakpoints.down('md')]: {
            padding: '5% 3% 3% 3%',
            alignItems: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingBottom: '5%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            padding: '10% 0% 6% 0%',
        }
    },
    instagramFeed: {
        display: 'flex',
        justifyContent: 'space-between',
        
        [theme.breakpoints.down('md')]: {
            display: props => props.browserName !== "ie" && props.browserName !== "edge" ? 'grid' : '-ms-grid',
            gridTemplateColumns: 'min-content min-content min-content',
            msGridColumns: 'min-content 1.9vw min-content 1.9vw min-content',
            msGridRows: 'auto 1.9vw auto',
            gridGap: '1.9vw',
            marginBottom: '3vw',
            '& div:nth-of-type(1)': {
                msGridRow: 1,
                msGridColumn: 1,
            },
            '& div:nth-of-type(2)': {
                msGridRow: 1,
                msGridColumn: 3,
            },
            '& div:nth-of-type(3)': {
                msGridRow: 1,
                msGridColumn: 5,
            },
            '& div:nth-of-type(4)': {
                msGridRow: 3,
                msGridColumn: 1,
            },
            '& div:nth-of-type(5)': {
                msGridRow: 3,
                msGridColumn: 3,
            },
            '& div:nth-of-type(6)': {
                msGridRow: 3,
                msGridColumn: 5,
            },
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '5vw',
            gridGap: '2.5vw',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '5vw',
            gridGap: '2.5vw',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '6vw',
            gridGap: '2.5vw',
        },

    },
    instagramImage: {
        width: '100%',
        height: 'auto'
    },
    instagramImageContainer: {
        width: '12rem',
        height: '12rem',
        overflow: 'hidden',
        [theme.breakpoints.down('lg')]: {
            width: '12vw',
            height: '12vw',
        },
        [theme.breakpoints.down('md')]: {
            width: '18vw',
            height: '18vw',
        },
        [theme.breakpoints.down('sm')]: {
            width: '24vw',
            height: '24vw',
        },
        [theme.breakpoints.down('xs')]: {
            width: '32vw',
            height: '32vw',
        }

        
    },
    supportMessages: {
        textAlign: "left",
        backgroundColor: '#f1f1f1',
        width: '50%',
        padding: '5% 13% 3% 3%',
        [theme.breakpoints.down('md')]: {
            width: '80%',
            textAlign: 'center',
            paddingRight: '3%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingBottom: '7%'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '8% 4% 10% 4%',
        }

    },
    heading: {
        textAlign: 'left',
        fontSize: '54px',
        fontFamily: 'GTPressura, sans-serif',
        color: theme.palette.primary.main,
        marginBottom: '5%',
        [theme.breakpoints.down('lg')]: {
            fontSize: '3vw',
        },
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            fontSize: '3.5vw'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '4.5vw'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '9vw',
            marginBottom: '10%',
        }
    },
    spinner: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        justifyContent: 'center'
    },
    messageContainer: {
        display: props => props.browserName !== "ie" ? 'grid' : '-ms-grid',
        gridTemplateColumns: 'min-content auto',
        msGridColumns: 'min-content auto',

        marginBottom: '1.5vw',
        [theme.breakpoints.down('md')]: {
            marginBottom: '2vw'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '3vw'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '6vw'
        },

        '& div:nth-of-type(1)': {
            msGridRow: 1,
            msGridColumn: 1,
        },
        '& div:nth-of-type(2)': {
            msGridRow: 1,
            msGridColumn: 2,
        },
    },
    messageImage: {
        marginRight: '1.5vw',
        width: '7vw',
        height: 'max-content',
        [theme.breakpoints.down('md')]: {
            width: '12vw',
            marginRight: '2vw'
        },
        [theme.breakpoints.down('sm')]: {
            width: '16vw',
            marginRight: '3vw'
        },
        [theme.breakpoints.down('xs')]: {
            width: '26vw',
            marginRight: '4vw'
        }
    },
    imageClass: {
        width: '100%',
        height: '100%'
    },
    message: {
        borderTop: `solid 1px ${theme.palette.primary.main}`,
        display: 'flex',
        flexDirection: 'column'
    },
    messageName: {
        marginTop: '0.5vw',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            '& h4': {
                fontSize: '2.5vw',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '3vw',
                    marginBottom: '0.2rem'
                },
                [theme.breakpoints.down('xs')]: {
                    fontSize: '4.5vw'
                }
            }
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start'
        }
    },
    name: {
        color: theme.palette.secondary.main,
        fontWeight: 400
    },
    date: {
        color: theme.palette.primary.main,
        fontWeight: 400,
    },
    messageBody: {
        textAlign: 'left',
        marginTop: '1vw',
        fontSize: '0.8vw',
        maxWidth: props =>  props.browserName === "ie" ? '60%' : '',
        [theme.breakpoints.down('md')]: {
            fontSize: '2vw'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.8vw'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '4.2vw'
        },
    },
    hollowButton: {
        fontFamily: 'Roboto, sans-serif',
        textAlign: 'center',
        fontWeight: 500,
        fontSize: '1.2rem',
        backgroundColor: 'transparent',
        borderColor: theme.palette.secondary.main,
        border: 'solid 2px',
        borderRadius: '2.75rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
        paddingTop: '0.6rem',
        paddingBottom: '0.6rem',
        textDecoration: 'none',
        transform: 'scale(1, 1.1)',
        color: theme.palette.secondary.main,
        '&:hover': {
            textDecoration: 'none',
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            transitionProperty: 'color, background-color, border-color',
            transitionDuration: '150ms',
            transitionTimingFunction: 'easi-in-out',
            WebkitTransitionProperty: 'width, background-color',
            WebkitTransitionDuration: '150ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '8vw',
            paddingRight: '8vw',
            paddingTop: '2.2vw',
            paddingBottom: '2.2vw',
            border: 'solid 1.5px',
            fontSize: '1rem'
        }
    },
    hollowButtonContainer: {
        display: 'inline-flex',
        marginTop: '3.5vw',
        height: '4rem',
        alignItems: 'center',
        width: 'fit-content',
        marginBottom: '1vw',
    },
    header: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

const SupporterMessage: React.FunctionComponent<ISupporterMessageProps> = ({ scrollPosition }) => {

    const [postsState, postsResponse] = useBctGet(instagramRequest);
    const browser = detect();
    const browserName = browser.name;
    const classes = useStyles({ browserName });

    return (
        <div className={classes.supporterContainer}>
            <div className={classes.instagramFeedContainer}>
                <div className={ classes.header}>
                    <h3 className={classes.heading}>#Breastolution</h3>
                </div>
                {
                    postsState === RequestState.Pending ?
                        <div className={classes.spinner}>
                            <CircularProgress color="primary" />
                        </div>
                        : ""
                }
                {
                    postsState === RequestState.Failed && (
                        "Error!"
                    )
                }
                {
                    postsResponse && postsResponse.posts.length > 0 ?
                        <LazyLoadComponent
                            scrollPosition={scrollPosition}
                        >
                        <div className={classes.instagramFeed}>
                            {
                                !!postsResponse && !!postsResponse.posts && (
                                    postsResponse.posts.map((post, i) => (

                                        <div className={classes.instagramImageContainer} key={i}>
                                            <a href={postLink(post.postUrl)} >
                                                    <img className={classes.instagramImage} src={post.thumbnailUrl} alt={post.thumbnailUrl} />
                                            </a> 
                                        </div>
                                    )
                                    ))
                            }
                            </div>
                        </LazyLoadComponent>
                        : null
                }
                <div className={classes.header}>
                    <div className={classes.hollowButtonContainer}>
                        <a href="https://www.instagram.com/explore/tags/breastolution/" className={classes.hollowButton} target="_blank" rel="noopener noreferrer">
                            VIEW ALL
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );

    function postLink(url: string) {
        return "https://instagram.com/p/" + url;
    }
    
};

export default trackWindowScroll(SupporterMessage);
