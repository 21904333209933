import { Container, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import Tick from './assets/icons/v2/Icon_04_Tick.svg';
import TickMd from './assets/icons/v2/Icon_04_Tick_Md.svg';
import About from './components/About';
import Banner from './components/Banner';
import Faq from './components/Faq';
import Footer from './components/Footer';
import Header from './components/Header';
import InfoGraphicContainer from './components/InfoGraphicContainer';
import InfoGraphicContainer2 from './components/InfoGraphicContainer2';
import MobileInterest from './components/MobileInterest';
import Newsletter from './components/Newsletter';
import Participate from './components/Participate';
import Segment from './components/Segment';
import Slide from './components/Slide';
import SupporterMessage from './components/SupporterMessages';
import ImgEnum from './enumerations/img';
import appSettings from './helpers/appSettings';


interface IAppProps {

}

interface IAppStyleProps {
}

const useStyles = makeStyles<IAppStyleProps>((theme: Theme) => ({
    containerBody: {
        maxWidth: '100%',
        width: '100%',
        padding: 0
    },
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'hidden'
    },
    componentClass: {
        //zIndex: 1,
    },
    bodyRoot: {
        maxWidth: '1919px',
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(0),
        margin: theme.spacing(0),
        overflowX: 'auto',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            overflow: 'hidden',
        },

        overflowY: 'hidden'
    },
    desktopStories: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    mobileStories: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            marginBottom: '4vw'
        },
        '& .swiper-container': {
            width: '100%',
            paddingBottom: '6%'

        },
        '& .swiper-pagination, .swiper-pagination-clickable, .swiper-pagination-bullets': {
            bottom: '50px',
            [theme.breakpoints.down('xs')]: {
                bottom: '0px'
            }
        },
        '& .swiper-pagination-bullet': {
            width: '15px',
            height: '15px',
            margin: '0 6px',
            backgroundColor: 'white',
            border: '1px #cccccc solid',
            opacity: 1,
            '&:focus': {
                outline: 'none'
            },
            [theme.breakpoints.down('xs')]: {
                width: '11px',
                height: '11px',
            }
        },
        '& .swiper-pagination-bullet-active': {
            backgroundColor: '#cccccc'
        },
        '& .swiper-button-next': {
            color: '#FFFFFF',
            opacity: 1,
            top: '60%',
            right: '18px',
            '&:after': {
                fontSize: '60px',
            }
        },
        '& .swiper-button-prev': {
            color: '#FFFFFF',
            opacity: 1,
            top: '60%',
            left: '18px',
            '&:after': {
                fontSize: '60px',
            }
        },
        '& .swiper-button-disabled': {
            color: '#cccccc',
            opacity: .1,
        },
    },
    openQuote: {

    },
    closeQuote: {
        transform: 'rotate(180deg)',
        marginLeft: '10px'
    },
    quoteContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    nonMobile: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    mobileOnly: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    slideList: {
        fontFamily: 'Roboto, sans-serif',
        lineHeight: '30px',
        fontWeight: 400,
        color: theme.palette.info.light,
        marginTop: theme.spacing(4),
        fontSize: '24px',
        '@media (max-width:1900px)': {
            marginTop: theme.spacing(4),
            fontSize: '1.6em',
            lineHeight: 1.6,
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.3em',
            marginTop: theme.spacing(3)
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1.1em',
            marginTop: theme.spacing(1)
        },
        [theme.breakpoints.down('sm')]: {
            lineHeight: 1.6
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.25em',
            marginTop: '0'
        },
        '& ul': {
            paddingInlineStart: '35px',
            '& li': {
                paddingBottom: theme.spacing(1),
                '@media (max-width:1900px)': {
                    paddingBottom: '0',
                },
                [theme.breakpoints.down('sm')]: {
                    paddingBottom: '6px',
                },
                fontStyle: 'italic',
                listStyleImage: `url(${Tick})`,
                '& div': {
                    paddingLeft: theme.spacing(1),
                },
                [theme.breakpoints.down('md')]: {
                    listStyleImage: `url(${TickMd})`,
                }
            }
        }
    },
    slideListTwo: {
        fontFamily: 'Roboto, sans-serif',
        lineHeight: 1.4,
        fontWeight: 400,
        color: theme.palette.info.light,
        marginTop: theme.spacing(3),
        fontSize: '2em',

        [theme.breakpoints.down('lg')]: {
            fontSize: '1.6em',
            marginTop: theme.spacing(3)
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1.4em',
            marginTop: theme.spacing(1)
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2em',
            lineHeight: 1.6,
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.3em',
        },

        '& ul': {
            paddingInlineStart: '30px',
            '& li': {
                paddingBottom: theme.spacing(1),
                '@media (max-width:1900px)': {
                    paddingBottom: '0',
                },
                [theme.breakpoints.down('sm')]: {
                    paddingBottom: '6px',
                },
                listStyleImage: `url(${Tick})`,
                '& div': {
                    paddingLeft: theme.spacing(1),
                },
                [theme.breakpoints.down('md')]: {
                    listStyleImage: `url(${TickMd})`,
                }
            }
        }
    },
    pink: {
        color: theme.palette.primary.main
    },
    donateButton: {
    fontFamily: 'GTPressura, sans-serif',
    textAlign: 'center',
    fontWeight: 'lighter',
    fontSize: '1.2rem',
    backgroundColor: 'none',
    border: 'solid 2px',
    borderColor: theme.palette.secondary.main,
    borderRadius: '2.75rem',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    '&:hover': {
        textDecoration: 'none',
        cursor: 'pointer',
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        transitionProperty: 'color, background-color, border-color',
        transitionDuration: '150ms',
        transitionTimingFunction: 'easi-in-out',
        WebkitTransitionProperty: 'width, background-color',
        WebkitTransitionDuration: '150ms',
        WebkitTransitionTimingFunction: 'ease-in-out',
        },

    },
    hollowButton: {
        '&:hover': {
            textDecoration: 'none',
        }
    },
    slideBody: {
        fontFamily: 'Roboto, sans-serif',
        color: theme.palette.info.light,
        marginTop: theme.spacing(5),
        marginLeft: '4px',
        marginRight: '4px',
        fontSize: '18px',
        lineHeight: '30px',
        '@media (max-width:1900px)': {
            fontSize: '1.13em',
            lineHeight: 1.7,
        },
        [theme.breakpoints.down('lg')]: {
            marginTop: theme.spacing(3),
            fontWeight: 500,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2em',
            lineHeight: 1.6
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.25em',
        }
    },
    hollowButtonContainer: {
        marginTop: theme.spacing(4),
        width: theme.spacing(22)
    }
}))


const App: React.FunctionComponent<IAppProps> = () => {

    const classes = useStyles({ });

    return (
        <div className={classes.root}>
            <div className={classes.bodyRoot}>
                <Header />
                <Container className={classes.containerBody}>
                    <Banner />
                    <div className={classes.mobileOnly}>
                        <MobileInterest />
                    </div>
                    <Segment segmentNo={1} />
                    <div id="who">
                        <Slide
                            heading="We are actively recruiting women to participate in the trial"
                            headingColour="primary"
                            textRight={true}
                            iconSecondary={true}
                            image={ImgEnum.Slide1}
                            imageMobile={ImgEnum.Slide1Mobile}>
                            <div>
                                <div className={classes.slideList}>
                                    You may be suitable if:
                                    <br />
                                    <br />
                                    <ul>
                                        <li><div>You carry the BRCA1 gene mutation</div></li>
                                        <li><div>You are aged between 25-55 years and unaffected by breast or ovarian cancer</div></li>
                                        <li><div>You may be either pre or post-menopausal</div></li>
                                        <li><div>You are not pregnant, or trying to become pregnant, right now</div></li>
                                        <li><div>You have not had preventative prophylactic breast surgery</div></li>
                                        <li><div>You are not taking any breast cancer preventative agents such as Tamoxifen or an Aromatase Inhibitor</div></li>
                                    </ul>
                                </div>
                            </div>
                        </Slide>
                    </div>
                    <Participate />
                    <About />
                    <div>
                        <Slide
                            heading="What's involved in the <span style=&quot;white-space: nowrap&quot;>BRCA-P</span> trial?"
                            headingColour="primary"
                            textRight={true}
                            iconSecondary={true}
                            image={ImgEnum.Slide2}
                            imageMobile={ImgEnum.Slide2Mobile}>
                            <div>
                                <div className={classes.slideListTwo}>
                                    <ul>
                                        <li><div>Free to participate</div></li>
                                        <li><div>15 locations Australia wide</div></li>
                                        <li><div>Uses a drug already in use to treat osteoporosis (Denosumab)</div></li>
                                        <li><div>Only 2 injections a year</div></li>
                                        <li><div>Team of health professionals monitoring your progress</div></li>
                                        <li><div>5 years of post trial assessments</div></li>
                                        <li><div>Help change the world and contribute to <span style={{ whiteSpace: 'nowrap' }}>life-changing</span> research</div></li>
                                    </ul>
                                </div>
                            </div>
                        </Slide>
                    </div>
                    <Segment segmentNo={2} />
                    <div id="hear">
                        <Segment segmentNo={3} />
                    </div>
                    <InfoGraphicContainer />
                    <div id="faq">
                        <Faq />
                    </div>
                    <Newsletter />
                    <InfoGraphicContainer2 />
                    <div id="about-bct">
                        <Slide
                            heading="About <span style=&quot;white-space: nowrap&quot;>Breast Cancer Trials</span>"
                            headingColour="primary"
                            textRight={false}
                            iconSecondary={true}
                            image={ImgEnum.Slide3}
                            imageMobile={ImgEnum.Slide3Mobile}>
                            <div className={classes.slideBody}>
                                <p>
                                    Breast Cancer Trials is a group of world-leading breast cancer doctors and
                                    researchers based in Australia and New Zealand, who are committed to
                                    exploring and finding better treatments and prevention strategies for breast
                                    cancer through clinical trials research.
                                </p>
                                <p>
                                    Since 1978, we have conducted a breast cancer research program which has
                                    improved the treatment of this disease, led to changes in the way breast cancer
                                    is managed and has <span className={classes.pink}>saved millions of lives through research collaboration.</span>
                                </p>
                                <p>
                                    Our clinical trials involve a unique collaboration between our researchers,
                                    people who participate in our clinical trials and our supporters, who are all
                                    committed to improving outcomes for people and families affected by breast
                                    cancer, so that they can get on with living and loving their lives.
                                </p>
                                <div className={classes.hollowButtonContainer}>
                                    <a href={appSettings.aboutUrl} className={classes.hollowButton}>
                                        <div className={classes.donateButton}>
                                            LEARN MORE
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </Slide>
                    </div>
                    <SupporterMessage />
                </Container>
                <Footer />
            </div>
        </div>
    );
};

export default App;
