
declare var require: any
const Slide1 = require('../assets/img/SliderImage_01.jpg');
const Slide2 = require('../assets/img/v2/desktop/SliderImage_03.jpg');
const Slide3 = require('../assets/img/v2/desktop/SliderImage_06.jpg');
const Slide4 = require('../assets/img/SliderImage_04.jpg');
const Slide5 = require('../assets/img/SliderImage_5_2.jpg');
const Slide6 = require('../assets/img/SliderImage_06.jpg');
const Slide1Mobile = require('../assets/img/x2/Participate_Image_x2.jpg');
const Slide2Mobile = require('../assets/img/x2/Donate_Image_x2.jpg');
const Slide3Mobile = require('../assets/img/x2/Share_Image_x2.jpg');
const Slide4Mobile = require('../assets/img/x2/Fundraise_Image_x2.jpg');
const Slide5Mobile = require('../assets/img/x2/Signup_Image_x2_2.jpg');
const Slide6Mobile = require('../assets/img/x2/TShirts_Mobile.jpg');

export default [
    Slide1,
    Slide2,
    Slide3,
    Slide4,
    Slide5,
    Slide6,
    Slide1Mobile,
    Slide2Mobile,
    Slide3Mobile,
    Slide4Mobile,
    Slide5Mobile,
    Slide6Mobile,
]