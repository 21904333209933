import React from 'react';
import { makeStyles, Theme, SvgIcon } from '@material-ui/core';
import { MouseIconPath, PhoneIconPath } from '../fileImports/iconPaths';
import { BRCAP_URL } from '../constants/Urls';

interface IParticipateProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '1022px',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(15),
        color: theme.palette.info.light,
        fontFamily: 'Roboto, sans-serif',
        '& h2': {
            color: theme.palette.primary.main,
            fontSize: '66px',
            fontFamily: 'GTPressura, sans-serif',
            [theme.breakpoints.down('xs')]: {
                fontSize: '3em',
            }
        },
        '& p': {
            fontWeight: '400',
            fontSize: '37.49px',
            lineHeight: '1.4',
            marginTop: theme.spacing(7),
            marginBottom: theme.spacing(9),
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.8em',
            },
            [theme.breakpoints.down('xs')]: {
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(5),
                fontSize: '1.3em',
            }
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            backgroundColor: '#f1f1f1'
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
        }
    },
    buttonHeading: {
        fontWeight: 'bold',
        fontSize: '33px',
        fontFamily: 'Roboto, sans-serif',
        color: theme.palette.secondary.main,
        [theme.breakpoints.down('xs')]: {
            fontWeight: 'normal',
            fontSize: '1.75em'
        }
    },
    donateButton: {
        fontFamily: 'GTPressura, sans-serif',
        textAlign: 'center',
        fontWeight: 'lighter',
        fontSize: '30px',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '2.75rem',
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textDecoration: 'none',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            transitionProperty: 'color, background-color',
            transitionDuration: '150ms',
            transitionTimingFunction: 'easi-in-out',
            WebkitTransitionProperty: 'width, background-color',
            WebkitTransitionDuration: '150ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            width: 'fit-content'
        }
    },
    iconContainer: {
        display: 'flex',
        paddingRight: theme.spacing(2),
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '90%',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& a': {
            width: '96%',
            [theme.breakpoints.down('xs')]: {
                width: 'fit-content !important'
            },
            "&:hover": {
                textDecoration: 'none'
            }
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(4)
        }
    }
}));


const Participate: React.FunctionComponent<IParticipateProps> = () => {

    const classes = useStyles({});

    return (
        <div className={classes.root} id="enrolment">
            <div className={classes.container}>
                <h2><span style={{ whiteSpace: 'nowrap' }}>How to participate</span></h2>
                <p>If you live in Australia and are interested in participating in the
                BRCA-P clinical trial, please speak with
                your doctor, call our BRCA-P trial hotline
                or register your interest online.</p>
                <div className={classes.buttonsContainer}>
                    <div className={classes.buttonContainer}>
                        <div className={classes.buttonHeading}>Call the BRCA-P hotline</div>
                        <a href="tel:1800777253" style={{ width: '94%' }}>
                            <div className={classes.donateButton}>
                                <div className={classes.iconContainer}>
                                    <SvgIcon className="icon" fontSize="inherit">
                                        <path d={PhoneIconPath} />
                                    </SvgIcon>
                                </div>
                                1800 777 253
                            </div>
                        </a>
                    </div>
                    <div className={classes.buttonContainer}>
                        <div className={classes.buttonHeading}>Expression of interest</div>
                        <a href={`${BRCAP_URL}eoi`} style={{ width: '98%' }}>
                            <div className={classes.donateButton}>
                                <div className={classes.iconContainer}>
                                    <SvgIcon className="icon" fontSize="inherit" viewBox="4 7 32 16">
                                        <path d={MouseIconPath} />
                                    </SvgIcon>
                                </div>
                                REGISTER ONLINE
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
};


export default Participate;