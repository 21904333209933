import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import 'font-awesome/css/font-awesome.min.css';
import Form from './Form';
import BackgroundImage from '../assets/img/v2/desktop/SliderImage_05.jpg';
import MobileBackgroundImage from '../assets/img/v2/mobile/SliderImage_04.jpg';
import Envelope from '../assets/icons/v2/Icon_08_SignUp_Newsletter_White.svg';
import appSettings from '../helpers/appSettings';
import SignUpButton from './SignUpButton';
const { detect } = require('detect-browser');

interface INewsletterProps {

}

const useStyles = makeStyles((theme: Theme) => ({
    newsletter: {
        display: 'flex',
        flexDirection: 'row',
        height: '1080px',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: '8%',
            paddingRight: '8%',
            height: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0',
            paddingRight: '0',
        }
    },
    newsletterContainer: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(8),
        marginLeft: '10%',
        [theme.breakpoints.down('lg')]: {
            marginLeft: '5%',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.spacing(180)
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '10vw',
            paddingTop: '8vw'
        }
    },
    newsletterLeft: {
        width: '50%',
        height: '100%',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        },
        [theme.breakpoints.down('md')]: {
            width: '80%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    newsletterRight: {
        backgroundColor: theme.palette.primary.main,
        display: 'inline-flex',
        flexDirection: 'column',
        width: "50%",
        minWidth: '800px',
        padding: '8%',
        '& h2': {
            color: 'white',
            fontFamily: 'GTPressura, sans-serif',
            fontSize: '80px',
            lineHeight: '90px',
            '@media (max-width:1900px)': {
                fontSize: '5em',
                lineHeight: 'normal',
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '4.5em',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '10vw',
            },
        },
        '& p': {
            color: 'white',
            fontSize: '28px',
            lineHeight: '38px',
            paddingRight: theme.spacing(3),
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(2),
            '@media (max-width:1900px)': {
                lineHeight: 'normal',
                fontSize: '1.5rem',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.2rem',
            },
        },
        [theme.breakpoints.down('md')]: {
            width: '80%',
            minWidth: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    newsletterImageContainer: {
        height: 'auto',
        width: '16rem',
        '& img': {
            width: '100%'
        },
        marginRight: theme.spacing(5)

    },
    newsletterImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    contactInfoContainer: {
        display: 'inline-flex',
        flexDirection: 'column',

    },
    contactInfo: {
        color: 'white',
        fontSize: 'large',
        display: "flex",
    },
    icon: {
        width: '96px',
        marginBlock: theme.spacing(3),
        '& object': {
            width: '100%'
        },
        '& img': {
            width: '100%'
        },
        '@media (max-width:1900px)': {
            width: '7em',
        },
        [theme.breakpoints.down('sm')]: {
            width: '6em'
        },
        [theme.breakpoints.down('xs')]: {
            width: '4em'
        }
    },
    contactLine: {
        display: 'flex',
        flexDirection: 'row',
        "& div": {
            color: "white"
        },
        padding: theme.spacing(.5)
    },
    bottom: {
        color: "white"
    },
    form: {
        paddingRight: theme.spacing(6),
        width: '90%',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0)
        },
    },
    desktop: {
        display: 'block',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block'
        }
    }
}));

const Newsletter: React.FunctionComponent<INewsletterProps> = () => {

    const browser = detect();
    const browserName = browser.name;
    const classes = useStyles({ browserName });

    const showSignupForm = appSettings.showSignupForm;

    return (
        <div className={classes.newsletter}>
            <div className={classes.newsletterLeft}>
                <img src={BackgroundImage} className={classes.desktop} alt="" />
                <img src={MobileBackgroundImage} className={classes.mobile} alt="" />
            </div>
            <div className={classes.newsletterRight}>
                <div className={ classes.icon}>
                    {
                        browser.name === "ie" || browser.name === "edge" ?
                            <img src={Envelope} alt="Envelope" className={classes.image} />
                            : <object id="envelop" data={Envelope} type="image/svg+xml" className={classes.image}>Envelope</object>
                    }
                </div>
                <h2>Sign up to the <span style={{ whiteSpace: 'nowrap' }}>BRCA-P Newsletter</span></h2>
                <p>Keep informed about this game-changing research into the prevention of breast cancer.</p>
                <div className={classes.form}>
                    {
                        showSignupForm ?
                            <Form /> :
                            <SignUpButton />
                    }
                </div>
            </div>
        </div>
    );
};

export default Newsletter;