import { AppBar, makeStyles, Theme, useScrollTrigger } from '@material-ui/core';
import { classNames } from '@servicestack/client';
import { JssStyle } from 'jss';
import React, { useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import appSettings from '../helpers/appSettings';
const PhoneGraphicDesktop = require('../assets/img/v2/desktop/HeaderGraphic_CTA.svg');
const { detect } = require('detect-browser');

interface IHeaderProps {
}

interface IHeaderStyleProps {
    scrolled: boolean;
    browserName: string;
}

const headerLogoImg = require('../assets/img/bct.png');
const headerLogoStickyImg = require('../assets/img/white-logo-transparent.png');

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        background: (props: IHeaderStyleProps) => props.scrolled ? theme.palette.primary.main : 'transparent',
        padding: (props: IHeaderStyleProps) => props.scrolled ? theme.spacing(1) : theme.spacing(4),
        paddingLeft: (props: IHeaderStyleProps) => props.scrolled ? theme.spacing(4) : theme.spacing(6),
        paddingBottom: (props: IHeaderStyleProps) => props.scrolled ? props.browserName === "ie" ? '3px' : theme.spacing(1) : theme.spacing(6),
        transition: 'background 0.2s linear',
        display: 'flex',
        alignItems: (props: IHeaderStyleProps) => props.browserName === "ie" ? 'flex-start' : 'center',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            padding: (props: IHeaderStyleProps) => '8px',
            justifyContent: (props: IHeaderStyleProps) => 'space-between'
        },
        maxWidth: '1919px',
        right: 'auto'
    },
    headerLogo: {
        [theme.breakpoints.down('lg')]: {
            width: (props: IHeaderStyleProps) => props.scrolled ? "9rem" : "200px",
        },
        [theme.breakpoints.down('sm')]: {
            width: (props: IHeaderStyleProps) => "25%"
        },
        [theme.breakpoints.down('xs')]: {
            width: (props: IHeaderStyleProps) => "40%"
        },
        position: "sticky",
        top: (props: IHeaderStyleProps) => props.scrolled ? "0" : "",
        width: (props: IHeaderStyleProps) => props.scrolled ? "9rem" : "264px",
        transition: 'width 0.2s ease-in-out',
        display: (props: IHeaderStyleProps) => props.browserName === "ie" ? 'inline' : 'inline-flex',

    },
    headerImg: {
        //maxHeight: "100%",
        //maxWidth: "100%"
    },
    headerItems: {
        position: 'absolute',
        right: '2rem',
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'inline-flex'
        },
    },
    navMenu: {
        display: 'inline-flex',
        height: '4rem',
        alignItems: 'center',
        width: 'fit-content',
    },
    navMenuItem: {
        margin: theme.spacing(2),
        fontFamily: 'GTPressura, sans-serif',
        textDecorationLine: 'none',
        fontSize: '1.15rem',
        fontWeight: 'lighter',
        transform: 'scale(.9, 1.1)',
        color: (props: IHeaderStyleProps) => props.scrolled ? 'white' : theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.secondary.main,
            textDecorationLine: 'none',
        }
    },
    mobileNavMenu: {
        display: 'inline-flex',
        height: '4rem',
        alignItems: 'center',
        width: 'fit-content',
        flexDirection: 'column',
    },
    mobileNavMenuItem: {
        fontFamily: 'GTPressura, sans-serif',
        margin: theme.spacing(2),
        marginTop: theme.spacing(4),
        borderBottom: '1px solid white',
        textDecorationLine: 'none',
        fontSize: '3vw',
        transform: 'scale(.9, 1.1)',
        color: 'white',
        '&:hover': {
            color: theme.palette.secondary.main,
            textDecorationLine: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '3.5vw'
        },
        [theme.breakpoints.down('xs')]: {
            borderBottomWidth: '0.5px',
            fontSize: '6vw'
        }
    },
    headerBurger: {
        display: 'none',
        //background: theme.palette.primary.dark,
        '& .bm-burger-bars': {
            background: theme.palette.secondary.main,
            height: '3px !important'
        },
        '& .bm-burger-button': {
            position: 'absolute',
            width: '36px',
            [theme.breakpoints.down('sm')]: {
                width: '30px',
                height: '25px'
            },
            [theme.breakpoints.down('xs')]: {
                width: '23px',
                height: '18px'
            },
            height: '30px',
            right: theme.spacing(3),
            top: '41%',
            display: 'block',
        },
        '& .bm-menu': {
            height: '100%',
            paddingTop: theme.spacing(1),
            //overflowY: 'hidden !important' as 'hidden',
            backgroundColor: theme.palette.primary.main
        },
        '& .bm-menu-wrap': {
            top: 0,
            height: '100%',
            [theme.breakpoints.down('xs')]: {
                width: '70% !important',
            }
        },
        '& .bm-cross-button': {
            position: 'absolute',
            width: '36px !important',
            height: '36px !important',
            right: '24px !important',
            top: '24px !important',
            display: 'block',

        },
        '& .bm-cross': {
            color: theme.palette.secondary.main,
            height: '24px !important',

            //background: '#bdc3c7'
        },
        '& .bm-overlay': {
            background: theme.palette.primary.main,
            right: 0,
            top: 0,
        },
        [theme.breakpoints.down('md')]: {
            display: 'flex'
        }
    },
    menu: {
        overflow: 'visible',
    },
    donateButton: {
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'center',
        fontWeight: 'lighter',
        fontSize: '1.3rem',
        backgroundColor: theme.palette.secondary.main,
        borderColor: 'transparent',
        borderRadius: '2.75rem',
        paddingLeft: '.3rem',
        paddingRight: '2.5rem',
        paddingTop: '.8rem',
        paddingBottom: '.8rem',
        textDecoration: 'none',
        transform: 'scale(1, 1.1)',
        color: 'white',
        fontFamily: 'GTPressura, sans-serif',
        [theme.breakpoints.up('lg')]: {
            //width: '13rem'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(2)
        },
        '&:hover': {
            textDecoration: 'none',
            color: (props: IHeaderStyleProps) => props.scrolled ? theme.palette.primary.main : 'white',
            backgroundColor: (props: IHeaderStyleProps) => props.scrolled ? 'white' : theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            transitionProperty: 'color, background-color, border-color',
            transitionDuration: '150ms',
            transitionTimingFunction: 'easi-in-out',
            WebkitTransitionProperty: 'width, background-color',
            WebkitTransitionDuration: '150ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
            paddingLeft: '1.9rem',
            paddingRight: '1.9rem',
            paddingTop: '.6rem',
            paddingBottom: '.6rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.8rem',
            paddingLeft: '1.2rem',
            paddingRight: '1.2rem',
            paddingTop: '.5rem',
            paddingBottom: '.5rem',
            marginLeft: 0
        }
    },
    donateHeaderContainer: {
        paddingLeft: '2rem',
        paddingRight: '2rem',
        display: 'inline-flex !important',
        height: '4rem',
        alignItems: 'center',
        width: 'max-content',
        [theme.breakpoints.down('lg')]: {
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(5)
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(4)
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(3),
            paddingLeft: '4vw',
            maxWidth: '200px'
        }
    },
    donateIconContainer: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    donateNumber: {
        display: 'flex',
        flexDirection: 'column',
        '& div': {
            letterSpacing: '6'
        } as JssStyle
    },
    image: {
        width: '100%',
        pointerEvents: 'none'
    }
}))

declare global {
    interface Window {
        dataLayer: Record<string, any>[];
    }
}

const onHotlineClick = () => {
    window.dataLayer.push({
        'event': 'BreastolutionTopMenuBRCAPHotline'
    })
}

const Header: React.FunctionComponent<IHeaderProps> = () => {

    const scrolled = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window,
    });

    const browser = detect();
    const browserName = browser.name;
    const classes = useStyles({ scrolled, browserName });    

    const headerImageOuterClass = classNames("header-branding", classes.headerLogo)
    const headerLogoClass = classNames("img-fluid", "header-logo-img");

    useEffect(() => {
        document.getElementById("burgerMenu").addEventListener("touchmove", function (e) {
            e.preventDefault();
        }, false);
    }, [])

    const scrollToDiv = (divId: string) => {
        let elem = document.getElementById(divId);
        if (elem) {
            elem.scrollIntoView();
        }

        let headerBar = document.getElementById("appBar");
        let headerOffset = headerBar ? headerBar.clientHeight : 0;

        if (headerOffset > 0 && divId !== "brcap-trial") {
            window.scrollBy(0, -headerOffset);
        }
    }

    window.addEventListener("hashchange", function () {
        window.scrollTo(window.scrollX, window.scrollY - 100);
    });

    const headerLogoSrc = (scrolled) ? headerLogoStickyImg : headerLogoImg;

    return (
        <AppBar id="appBar" color="inherit" className={classes.header} elevation={0}>
            <div className={headerImageOuterClass}>
                <img className={headerLogoClass} src={headerLogoSrc} alt="Breast Cancer Trials" />
            </div>
            <div className={classes.headerItems}>
                <div className={classes.navMenu}>
                    <div className={classes.navMenuItem} onClick={() => scrollToDiv("about")}>
                        ABOUT THE TRIAL
                    </div>
                    <div className={classes.navMenuItem} onClick={() => scrollToDiv("who")}>
                        WHO IS IT FOR?
                    </div>
                    <div className={classes.navMenuItem} onClick={() => scrollToDiv("enrolment")}>
                        ENROLMENT
                    </div>
                    <div className={classes.navMenuItem} onClick={() => scrollToDiv("faq")}>
                        FAQS
                    </div>
                    <a className={classes.navMenuItem} href={appSettings.locationsUrl}>
                        LOCATIONS
                    </a>
                    <div className={classes.navMenuItem} onClick={() => scrollToDiv("about-bct")}>
                    ABOUT BCT
                    </div>
                </div>
                <div className={classes.donateHeaderContainer}>
                    <div className={classes.donateIconContainer} onClick={onHotlineClick}>
                        <a href="tel:1800777253" style={{display: 'block'}}>
                            <object id={"svg-phone"} data={PhoneGraphicDesktop} type="image/svg+xml" className={classes.image}>Donate</object>
                        </a>
                    </div>
                </div>
            </div>
            <div id="mobileMenuContainer" className={classes.headerBurger}>
                <div className={classes.donateHeaderContainer}>
                    <div className={classes.donateIconContainer}>
                        <object id={"svg-phone"} data={PhoneGraphicDesktop} type="image/svg+xml" className={classes.image}>Donate</object>
                    </div>
                </div>
                <Menu
                    id={"burgerMenu"}
                    className={classes.menu}
                    right
                    pageWrapId={"page-wrap"}
                    outerContainerId={"mobileMenuContainer"}
                    >
                    <a href="#about" className={classes.mobileNavMenuItem}>
                        ABOUT THE TRIAL
                        </a>
                    <a href="#who" className={classes.mobileNavMenuItem}>
                        WHO IS IT FOR?
                        </a>
                    <a href="#enrolment" className={classes.mobileNavMenuItem}>
                        ENROLMENT
                        </a>
                    <a href="#faq" className={classes.mobileNavMenuItem}>
                        FAQS
                        </a>
                    <a href={appSettings.locationsUrl} className={classes.mobileNavMenuItem}>
                        LOCATIONS
                        </a>
                    <a href="#about-bct" className={classes.mobileNavMenuItem}>
                        ABOUT BCT
                    </a>
                </Menu>
            </div>
        </AppBar>    
    );
};


export default Header;
