/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving the authenticated person.
 * --------------------------------------------------------------------------------
 */



/*
 * Used to get access to the API types and requests
 */
import * as BctDtos from '../../src/api/bct.dtos';

import { Module as ReduxTypedModule } from 'redux-typed-modules';

import { update } from "../api/immutabilityHelper";
import reducerRegistry  from '../api/ReducerRegistry';
import { bctClient } from './bctClient';

import { createLogic } from 'redux-logic';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IRequestState {
    state: RequestState;
    responseStatus?: BctDtos.ResponseStatus;
}

export interface IInitialState {
    data: BctDtos.TrialSubscription;
    registrationState: IRequestState;
    postsState: IRequestState;
}



const typeNamespace = '@@trialSubsctiption'

export const types = {
    SAVE_REGISTRATION: `${typeNamespace}/SAVE_REGISTRATION`,
    SAVE_REGISTRATION_SUCCESS: `${typeNamespace}/SAVE_REGISTRATION_SUCCESS`,
    SAVE_REGISTRATION_FAILURE: `${typeNamespace}/SAVE_REGISTRATION_FAILURE`,
};


export enum RequestState {
    None = 0,
    Pending = 1,
    Success = 2,
    Failure = 3,
    Cancel = 4
}



export interface INewsletterState {
    registration: IInitialState
}


export const initialState: IInitialState = {
    data: new BctDtos.TrialSubscription(),
    registrationState: {
        state: RequestState.None
    },
    postsState: {
        state: RequestState.None
    }
};
/*
 * ---------------------------------------------------------------------------------
 * Redux Module
 * ---------------------------------------------------------------------------------
 */

const reduxTypedModule = new ReduxTypedModule<IInitialState, {}>({
    initialState: initialState
});

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const newsletterActions = {

    register: reduxTypedModule.createAction({
        type: types.SAVE_REGISTRATION,
        action: (
            registration: BctDtos.TrialSubscription
        ) => ({
            registration
        }),
        reducer: (state, action) => update(
            state,
            {
                registrationState: {
                    $set: {
                        state: RequestState.Pending
                    }
                }
            }
        )
    }),
    registerSuccess: reduxTypedModule.createAction({
        type: types.SAVE_REGISTRATION_SUCCESS,
        action: (
            registration: BctDtos.TrialSubscription
        ) => {
            return ({ registration })
        },
        reducer: (state, action) => update(
            state,
            {
                data: {
                    $set: action.registration
                },
                registrationState: {
                    $set: {
                        state: RequestState.Success
                    }
                }
            }
        )
    }),
    registerFailure: reduxTypedModule.createAction({
        type: types.SAVE_REGISTRATION_FAILURE,
        action: (responseStatus: BctDtos.ResponseStatus) => ({
            responseStatus
        }),
        reducer: (state, action) => update(
            state,
            {
                registrationState: {
                    $set: {
                        state: RequestState.Failure,
                        responseStatus: action.responseStatus
                    }
                }
            }
        )
    }),
}



export const BrcapApi = {
   
    register: (
        registration: BctDtos.TrialSubscription,
    ): Promise<BctDtos.PostTrialSubscriptionResponse> => {
        //Create the request.
        let request: BctDtos.PostTrialSubscription =
            new BctDtos.PostTrialSubscription();

        request.trialSubscription = {
            id: null,
            trialId: "BRCAP",
            title: "",
            firstName: registration.firstName,
            surName: registration.surName,
            phoneNumber: "",
            emailAddress: registration.emailAddress,
            comments: null,
            dateEntered: null,
            subscribed: true,
            trialName: ""
        }


        //Send request.
        const response: Promise<BctDtos.PostTrialSubscriptionResponse> = bctClient.post(request);

        //Return request observable (incomplete request, still processing).
        return response;
    },

    
}

/*
 * ---------------------------------------------------------------------------------
 * Logics
 * ---------------------------------------------------------------------------------
 */
const
    register = createLogic({
        type: types.SAVE_REGISTRATION,
        latest: true,
        process: async ({ action }: any, dispatch: (arg0: any) => void, done: () => void) => {
            const typedAction: ReturnType<typeof newsletterActions.register> = action as any;

            try {
                const response = await BrcapApi
                    .register(typedAction.registration);

                dispatch(newsletterActions.registerSuccess(response.trialSubscription));
            }
            catch (error) {
                dispatch(newsletterActions.registerFailure(error ? error.responseStatus : undefined));
            }

            done();
        }
    })
    
    



const logics = [
    register,
];

/*
 * ---------------------------------------------------------------------------------
 * Reducer
 * ---------------------------------------------------------------------------------
 */

const reducer = reduxTypedModule.createReducer();

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

reducerRegistry.register("registration", reducer, logics);

export default reducer;

