import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import appSettings from '../helpers/appSettings';

interface ISignUpButtonProps {

}

interface ISignUpButtonStyleProps {
    darkButton: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    signupButton: {
        display: 'inline-block',
        color: (props: ISignUpButtonStyleProps) => props.darkButton ? theme.palette.secondary.main : 'white',
        backgroundColor: 'transparent',
        borderColor: (props: ISignUpButtonStyleProps) => props.darkButton ? theme.palette.secondary.main : 'white',
        textTransform: 'uppercase',
        borderStyle: 'solid',
        borderRadius: theme.spacing(4),
        marginLeft: 0,
        padding: theme.spacing(2.5),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        margin: theme.spacing(1),
        fontFamily: 'Roboto',
        fontSize: '1.3rem',
        fontStretch: 'condensed',
        lineHeight: '.8rem',
        alignSelf: 'flex-start',
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: (props: ISignUpButtonStyleProps) => props.darkButton ? theme.palette.secondary.main : 'white',
            color: (props: ISignUpButtonStyleProps) => props.darkButton ? "white" : theme.palette.primary.main,
            textDecoration: 'none',
            transitionProperty: 'color, background-color, border-color',
            transitionDuration: '150ms',
            transitionTimingFunction: 'easi-in-out',
            WebkitTransitionProperty: 'width, background-color',
            WebkitTransitionDuration: '150ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        },
        '&:active': {
            backgroundColor: (props: ISignUpButtonStyleProps) => props.darkButton ? theme.palette.secondary.main : '#ffc0e5',
            borderColor: (props: ISignUpButtonStyleProps) => props.darkButton ? theme.palette.secondary.main : '#ffc0e5',
            color: "white",
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '5px',
            fontSize: '5.5vw',
            fontWeight: '500',
            fontStretch: 'condensed',
            padding: '4.5vw',
            paddingLeft: '6vw',
            paddingRight: '6vw',
        }
    }
}));

const SignUpButton: React.FunctionComponent<ISignUpButtonProps> = () => {
    const classes = useStyles({});

    return (<a
        className={classes.signupButton}
        href={appSettings.signupUrl}
    >
        Sign Up
    </a>);
};

export default SignUpButton;