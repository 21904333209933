import React from 'react';
import { Container, makeStyles, Theme } from '@material-ui/core';
import InfoGraphic from './InfoGraphic';
import IconEnums from '../enumerations/icons';
const { detect } = require('detect-browser');

interface IInfoGraphicProps {
    heading?: string;
}

interface IInfoGraphicStyleProps {
    browserName: string
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    infoGraphicContainerBody: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '5rem',
        [theme.breakpoints.down('md')]: {
            display: 'inline-flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            maxWidth: (props: IInfoGraphicStyleProps) => props.browserName === "ie" ? '' : '50%',
            width: (props: IInfoGraphicStyleProps) => props.browserName === "ie" ? '50%' : '',
            marginTop: '5%'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '10%'
        }
    },
    header: {
        color: theme.palette.primary.main,
        fontFamily: 'GTPressura, sans-serif',
        fontSize: '66px',
        paddingTop: '3rem',
        paddingBottom: '3rem',
        textAlign: 'center',
        '@media (max-width:1800px)':
        {
            fontSize: '4rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: (props: IInfoGraphicStyleProps) => props.browserName !== "ie" ? '9vw' : '3rem',
            padding: '0',
            paddingTop: '1.5rem'
        },
    },
    infoGraphicRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#f1f1f1',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '1vw'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '4vw'
        }
    },
    container: {
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '5rem',
        maxWidth: '80%',
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        paddingBottom: '8rem',
        [theme.breakpoints.down('md')]: {
            maxWidth: 'none',
            padding: '0',
        }
    },
    desktop: {
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            display: 'inline-flex',
            flexDirection: 'column',
            alignItems: 'center',
        }
    },
    mobileBody: {
        [theme.breakpoints.down('md')]: {
            display: 'inline-flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%'
        }
    },
    icon: {

    }
}))

const InfoGraphicContainer: React.FunctionComponent<IInfoGraphicProps> = props => {

    const browser = detect();
    const browserName = browser.name;
    const classes = useStyles({browserName});
    return (
        <div className={classes.infoGraphicRoot}>
            <Container className={classes.container}>
                <div className={classes.desktop}>
                    <h2 className={classes.header}>
                        In Australia today it is estimated that...
                    </h2>
                    <div className={classes.infoGraphicContainerBody}>
                        <InfoGraphic
                            heading={browser.name !== "ie" ? "1 in " : "1 in 400"}
                            name={IconEnums.FemaleSymbol}
                            id={1}>
                            1 in 400 women are at the highest risk of breast cancer because they <span>carry a BRCA1 or BRCA2 gene mutation</span>
                        </InfoGraphic>
                        <InfoGraphic
                            name={IconEnums.Gene}
                            bigIcon={true}
                            id={2}>
                            Women who carry a BRCA1 gene mutation have a <span>72% lifetime risk of developing breast cancer</span> and a 44% risk of developing ovarian cancer 
                        </InfoGraphic>
                        <InfoGraphic
                            heading={browser.name !== "ie" ? "1 in " : "1 in 40"}
                            name={IconEnums.Star}
                            primaryHeading={true}
                            id={3}>
                            <span>1 in 40 Ashkenazi Jewish women carry a BRCA1 or BRCA2 gene mutation.</span> This is 20 times more common than the general population
                        </InfoGraphic>
                    </div>
                    <div className={classes.infoGraphicContainerBody}>
                        <InfoGraphic
                            name={IconEnums.Breasts}
                            bigIcon={true}
                            id={4}>
                            BRCA1 and BRCA2 gene mutations account for about <span>5-10% of breast cancer diagnoses</span>
                        </InfoGraphic>
                        <InfoGraphic
                            name={IconEnums.Women}
                            bigIcon={true}
                            id={5}>
                            Breast cancers associated with a BRCA1 or BRCA2 gene mutation tend to develop in younger women where the incidence <span>becomes significant in their 30s and 40s</span>
                        </InfoGraphic>
                        <InfoGraphic
                            name={IconEnums.Nurse}
                            primaryHeading={true}
                            bigIcon={true}
                            id={6}>
                            Current risk management for BRCA1 gene mutation carriers is limited to <span>regular surveillance, preventative surgery</span> or <span>hormonal therapy medicines</span>
                        </InfoGraphic>
                    </div>
                </div>
                <div className={classes.mobile}>
                    <div className={classes.header}>
                        In Australia today it is estimated that...
                    </div>
                    <div className={classes.mobileBody}>
                        <div className={classes.infoGraphicContainerBody}>
                            <InfoGraphic
                                heading="1 in"
                                name={IconEnums.FemaleSymbol}
                                id={7}>
                                women carry a BRCA1 or BRCA2 gene mutation
                            </InfoGraphic>
                            <InfoGraphic
                                heading="1 in"
                                name={IconEnums.Star}
                                primaryHeading={true}
                                id={8}>
                                Ashkenazi Jewish women carry a BRCA1 or BRCA2 gene mutation
                            </InfoGraphic>
                            <InfoGraphic
                                name={IconEnums.Woman}
                                id={9}>
                                Breast cancer associated with a BRCA1 or BRCA2 gene mutation tend to develop in younger women
                            </InfoGraphic>
                        </div>
                        <div className={classes.infoGraphicContainerBody} style={{ marginTop: "17%" }}>
                            <InfoGraphic
                                name={IconEnums.Gene}
                                bigIcon={true}
                                id={10}>
                                Women who carry a BRCA1 gene mutation have a 72% lifetime risk of developing breast cancer
                            </InfoGraphic>
                            <InfoGraphic
                                name={IconEnums.Breasts}
                                bigIcon={true}
                                id={11}>
                                These gene mutations account for about 5-10% of breast cancer diagnoses
                            </InfoGraphic>
                            <InfoGraphic
                                name={IconEnums.Nurse}
                                primaryHeading={true}
                                bigIcon={true}
                                id={12}>
                                Current risk management is limited to regular surveillance, preventative surgery or hormonal therapy medicines
                            </InfoGraphic>
                            </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default InfoGraphicContainer;
