import React, { useEffect } from 'react';
import { makeStyles, Theme, Container, SvgIcon } from '@material-ui/core';
import BannerImg from '../assets/img/v2/desktop/HeaderImage_01.jpg';
import BannerImgMobile from '../assets/img/v2/mobile/HeaderImage_01.jpg';
import 'typeface-roboto';

import { PhoneIconPath, MouseIconPath } from '../fileImports/iconPaths';
import { BRCAP_URL } from '../constants/Urls';

interface IBannerProps {
}

interface IBannerStyleProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    banner: {
        height: '762px',
        //width: '1903px',
        display: 'flex',
        backgroundImage: `url(${BannerImg})`,
        backgroundSize: '1919px 762px',
        backgroundRepeat: 'no-repeat',
        color: 'white',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            paddingTop: '40%',
            backgroundPositionX: 'center'
        },
        [theme.breakpoints.down('md')]: {
            height: '640px',
            //width: '1614px',
            backgroundSize: '1614px 640px',
            backgroundPositionX: '-350px'
        },
        [theme.breakpoints.down('sm')]: {
            height: '640px',
            //width: '1614px',
            backgroundSize: '1614px 640px',
            backgroundPositionX: '-600px'
        },
        [theme.breakpoints.down('xs')]: {
            backgroundSize: '100%, 100%',
            backgroundPosition: '0px',
            width: '100%',
            height: 'auto',
            paddingBottom: '98%',
            backgroundImage: `url(${BannerImgMobile})`,
            justifyContent: 'center',
        },

        '& h1': {
            fontSize: '6rem',
            fontStretch: 'condensed',
            fontFamily: 'GTPressura, sans-serif',
            lineHeight: '0.8',
            [theme.breakpoints.down('md')]:
            {
                fontSize: '4.5rem',
            },
            [theme.breakpoints.down('sm')]:
            {
                fontSize: '4rem',
            },
            [theme.breakpoints.down('xs')]:
            {
                marginBottom: '0.2rem',
                fontSize: '13.8vw',
            }

        },
        '& h2': {
            fontSize: '3rem',
            fontStyle: 'italic',
            lineHeight: '.9',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: '300',
            [theme.breakpoints.down('md')]:
            {
                fontSize: '2.5rem',
            },
            [theme.breakpoints.down('sm')]:
            {
                fontSize: '2rem',
            },
            [theme.breakpoints.down('xs')]:
            {
                marginBottom: '0.2rem',
                fontSize: '7vw',
            }
        }
    },
    bannerImgClass: {
        maxWidth: '100%',
        overflowX: 'hidden',
    },
    bannerContainer: {
        maxHeight: '50rem',
        width: '100%',
        display: 'flex',
        marginRight: theme.spacing(10),
        marginLeft: '0px',
        paddingLeft: '14%',
        paddingTop: theme.spacing(30),
        paddingBottom: '3rem',
        [theme.breakpoints.up('xl')]: {
            paddingTop: '13rem',
            position: 'absolute',
            top: 0,
            width: '100%',
            backgroundSize: '100% 100%',
        },
        [theme.breakpoints.down('lg')]:
        {
            paddingTop: theme.spacing(25),
            paddingLeft: theme.spacing(4),
            position: 'absolute',
            top: 0,
            width: '100%',
            backgroundSize: '100% 100%',
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(20),
        },
        [theme.breakpoints.down('xs')]:
        {
            paddingTop: theme.spacing(11),
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: '0px',
            marginRight: '0px',
            width: 'fit-content',
            justifyContent: 'center'
        },
        
        transitionProperty: 'margin',
        transitionDuration: '150ms',
        transitionTimingFunction: 'ease-in-out',
        
    },
    donateButton: {
        fontFamily: 'GTPressura, sans-serif',
        textAlign: 'center',
        fontWeight: 'lighter',
        fontSize: '1.8rem',
        backgroundColor: 'none',
        border: 'solid 2px',
        borderColor: theme.palette.secondary.main,
        borderRadius: '2.75rem',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textDecoration: 'none',
        transform: 'scale(1, 1.1)',
        color: theme.palette.secondary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            transitionProperty: 'color, background-color, border-color',
            transitionDuration: '150ms',
            transitionTimingFunction: 'easi-in-out',
            WebkitTransitionProperty: 'width, background-color',
            WebkitTransitionDuration: '150ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        }
    },
    falseButton: {
        fontFamily: 'GTPressura, sans-serif',
        textAlign: 'center',
        fontWeight: 'lighter',
        fontSize: '1.8rem',
        backgroundColor: 'none',
        border: 'solid 2px',
        borderColor: theme.palette.secondary.main,
        borderRadius: '2.75rem',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textDecoration: 'none',
        transform: 'scale(1, 1.1)',
        color: theme.palette.secondary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    donateHeaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1.3vw',
        alignItems: 'center',
        marginBottom: theme.spacing(4),
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(3),
            marginBottom: 0
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: theme.spacing(3),
    },
    textContainer: {
        width: 'fit-content',
        maxWidth: 'fit-content',
        marginTop: '1rem',
        paddingTop: '1rem',
        [theme.breakpoints.down('xs')]: {
            marginTop: '5px',
            paddingTop: '0'
        }
    },
    headingContainer: {
        backgroundColor: theme.palette.primary.main,
        padding: '8px',
        width: 'fit-content',
        marginBottom: theme.spacing(1)
    },
    blurb: {
        fontSize: '2rem',
        fontFamily: 'Roboto, sans-serif',
        lineHeight: 1.2,
        fontWeight: 400,
        color: theme.palette.info.light,
        maxWidth: '600px',
        padding: theme.spacing(2),
        [theme.breakpoints.down('lg')]:
        {
            fontSize: '2rem',
        },
        [theme.breakpoints.down('md')]:
        {
            paddingTop: '1.5rem',
            paddingBottom: theme.spacing(3),
            fontSize: '1.5rem',
            backgroundColor: '#d6d9de8c',
            boxShadow: '0 0 20px 19px rgba(214, 217, 222, 0.55)',
            marginTop: theme.spacing(4)
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: '0.8rem'
        },
        [theme.breakpoints.down('xs')]:
        {
            padding: '0.3rem',
            fontSize: '4.9vw',
            background: 'none',
            boxShadow: 'none',
            marginTop: theme.spacing(0)
        }
    },
    icon: {
        fill: theme.palette.secondary.main,
        '&:hover': {
            cursor: 'pointer',
            fill: '#ffffff'
        }
    },
    iconContainer: {
        fontSize: '1.4rem',
        display: 'flex',
        paddingRight: theme.spacing(2),
        '& .MuiSvgIcon-root': {
            width: '1.5em',
            height: '1.4em',
        }
    },
    hover: {
        '&:hover': {
            textDecoration: 'none',
        }
    }
}))

declare global {
    interface Window {
        dataLayer: Record<string, any>[];
    }
}

const onHotlineClick = () => {
    window.dataLayer.push({
        'event': 'BreastolutionTopMenuBRCAPHotline'
    })
}

const Banner: React.FunctionComponent<IBannerProps> = () => {

    useEffect(() => {
        document.body.style.overflowX = 'hidden';
    }, []);

    const classes = useStyles({});

    return (
        <div className={classes.banner}>
            <Container className={classes.bannerContainer}>
                <div className={classes.textContainer}>
                    <div className={classes.headingContainer}>
                        <h2 style={{ marginBottom: 0 }}>Join the</h2>
                    </div>
                    <div className={classes.headingContainer}>
                        <h1 style={{ marginBottom: '0.1rem' }}>BREASTOLUTION</h1>
                    </div>
                    <div className={classes.blurb}>
                        Participate in a world-first clinical trial to prevent breast cancer in women with the BRCA1 gene mutation.
                        <div className={classes.donateHeaderContainer}>
                            Would you like to know more about the study, or interested in joining?
                            <div className={classes.buttonContainer}>
                                <a href="tel:1800777253" className={classes.hover} onClick={onHotlineClick}>
                                    <div className={classes.donateButton}>
                                        <div className={classes.iconContainer}>
                                            <SvgIcon className="icon" fontSize="inherit">
                                                <path d={PhoneIconPath} />
                                            </SvgIcon>
                                        </div>
                                        1800 777 253
                                    </div>
                                </a>
                                <a href={`${BRCAP_URL}eoi`} className={classes.hover}>
                                    <div className={classes.donateButton}>
                                        <div className={classes.iconContainer}>
                                            <SvgIcon className="icon" fontSize="inherit" viewBox="4 7 32 16">
                                                <path d={MouseIconPath} />
                                            </SvgIcon>
                                        </div>
                                        REGISTER ONLINE
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Banner;