/* Options:
Date: 2019-12-20 14:27:31
Version: 5.10
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:40707

//GlobalNamespace: 
//MakePropertiesOptional: True
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse() : T;
}

export interface IReturnVoid
{
    createResponse() : void;
}

export interface IGet
{
}

export interface IPost
{
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1, EmitDefaultValue=false)
    errorCode: string;

    // @DataMember(Order=2, EmitDefaultValue=false)
    fieldName: string;

    // @DataMember(Order=3, EmitDefaultValue=false)
    message: string;

    // @DataMember(Order=4, EmitDefaultValue=false)
    meta: { [index:string]: string; };
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    errorCode: string;

    // @DataMember(Order=2)
    message: string;

    // @DataMember(Order=3)
    stackTrace: string;

    // @DataMember(Order=4)
    errors: ResponseError[];

    // @DataMember(Order=5)
    meta: { [index:string]: string; };
}

export type AuthorisationDefinitionGroupType = "Exclude" | "Include";

export class AuthorisationGroup
{
    id: number;
    code: string;
    name: string;
    description: string;
    authorisationDefinitionGroupType: AuthorisationDefinitionGroupType;
}

export type AuthorisationDefinitionTrialType = "Exclude" | "Include";

export class AuthorisationTrial
{
    id: number;
    name: string;
    authorisationDefinitionTrialType: AuthorisationDefinitionTrialType;
    randomisation: boolean;
}

export class AuthorisationDefinition
{
    id: number;
    code: string;
    name: string;
    description: string;
    unauthorisedOnly: boolean;
    authorisationGroups: AuthorisationGroup[];
    authorisationTrials: AuthorisationTrial[];
}

export class AuthUser
{
    userName: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    groups: string[];
    requireMembershipRenewal: boolean;
}

export interface IHasUserGuid
{
    guid?: string;
}

export class BoardNominee
{
    id: number;
    fileId: number;
    name: string;
    proposer: string;
    seconder: string;
}

export class User
{
    username: string;
    firstName: string;
    surname: string;
    title: string;
    company: string;
    position: string;
    address: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
    email: string;
    phone: string;
    fax: string;
    mobile: string;
    imageUrl: string;
    hideDetails: boolean;
}

export class BoardVote
{
    id: number;
    boardNomineeId: number;
    username: string;
    boardNominee: BoardNominee;
}

export class CapReviewDocSummary
{
    docId: number;
    title: string;
    cutOffDate: string;
    commentCutOffDate: string;
}

export class CapReviewDoc
{
    docId: number;
    title: string;
    cutOffDate: string;
    longTitle: string;
    fileName: string;
    supportDoc1Title: string;
    supportDoc1FileName: string;
    supportDoc2Title: string;
    supportDoc2FileName: string;
    supportDoc3Title: string;
    supportDoc3FileName: string;
    supportDoc4Title: string;
    supportDoc4FileName: string;
    description: string;
    additionalNotes: string;
    commentCutOffDate: string;
    fileNotes: string;
}

export class CapReviewQuestion
{
    docId: number;
    qId: number;
    question: string;
}

export class CapReviewContact
{
    username: string;
    title: string;
    firstName: string;
    surname: string;
    imageUrl: string;
}

export class CapReviewComment
{
    docId: number;
    qId: number;
    commentId: number;
    username: string;
    dateEntered: string;
    comment: string;
    user: CapReviewContact;
}

export class CapReviewQuestionComplete
{
    docId: number;
    qId: number;
    question: string;
    capReviewComments: CapReviewComment[];
}

export class CapReviewDocComplete
{
    docId: number;
    title: string;
    cutOffDate: string;
    longTitle: string;
    fileName: string;
    supportDoc1Title: string;
    supportDoc1FileName: string;
    supportDoc2Title: string;
    supportDoc2FileName: string;
    supportDoc3Title: string;
    supportDoc3FileName: string;
    supportDoc4Title: string;
    supportDoc4FileName: string;
    description: string;
    additionalNotes: string;
    commentCutOffDate: string;
    capReviewQuestions: CapReviewQuestionComplete[];
}

export class DiaryOrder
{
    id: number;
    title: string;
    firstName: string;
    surName: string;
    address: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
    phoneNumber: string;
    email: string;
    numberOfDiaries: number;
    dateEntered: string;
}

export type DonationType = "Personal" | "Organisation";

export type PhoneType = "Home" | "Work" | "Mobile";

export type PaymentType = "CreditCard" | "PayPal";

export type CreditCardType = "Visa" | "MasterCard" | "AmericanExpress";

export type DonationFrequency = "OneTimeGift" | "RegularGift";

export type YesNo = "No" | "Yes";

export type DonationMothersDayCardType = "None" | "Animated" | "Pdf" | "HardCopy";

export type DonationMothersDayCardDesign = "DesignOne" | "DesignTwo";

export type DonationMothersDayCardDestination = "Donor" | "DonorsLovedOne";

export type DonationMothersDayCardDeliveryMethod = "Email" | "RegularMail" | "PriorityMail" | "ExpressMail";

export class DonationMothersDayCard
{
    id: number;
    donationId: number;
    type: DonationMothersDayCardType;
    design: DonationMothersDayCardDesign;
    destination: DonationMothersDayCardDestination;
    deliveryDate: string;
    deliveryMethod: DonationMothersDayCardDeliveryMethod;
    deliveredDate: string;
    guid: string;
    title: string;
    firstName: string;
    surname: string;
    email: string;
    address1: string;
    address2: string;
    suburb: string;
    state: string;
    postcode: string;
    message: string;
    exported: string;
}

export class Donation
{
    id: number;
    refNo: string;
    receiptNo: string;
    prefilled: boolean;
    donatedBefore: boolean;
    donorId: string;
    appealResponse: boolean;
    appealCode: string;
    packageCode: string;
    amountOne: number;
    amountTwo: number;
    amountThree: number;
    regularAmountOne: number;
    regularAmountTwo: number;
    regularAmountThree: number;
    amountDescriptionOne: string;
    amountDescriptionTwo: string;
    amountDescriptionThree: string;
    amountDescriptionFour: string;
    amountImageOne: string;
    amountImageTwo: string;
    amountImageThree: string;
    amountImageFour: string;
    organisation: DonationType;
    title: string;
    gender: number;
    firstName: string;
    surname: string;
    address1: string;
    address2: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
    phone: string;
    phoneType: PhoneType;
    email: string;
    orgName: string;
    orgPosition: string;
    hearBcia: number;
    bciaOther: string;
    dob: string;
    paymentType: PaymentType;
    cardType: CreditCardType;
    cardName: string;
    cardNo: string;
    cardExp: string;
    cardCcv: string;
    amount: string;
    amountOther: boolean;
    diaries: number;
    diaryTitle: string;
    diaryCost: number;
    diaryDonationRequired: number;
    paid: boolean;
    declined: boolean;
    attemptNo: number;
    frequency: DonationFrequency;
    payPalNonce: string;
    payPalClientToken: string;
    preview: boolean;
    memory: YesNo;
    memTitle: string;
    memFirstName: string;
    memSurname: string;
    celebration: YesNo;
    dedicationName: string;
    advise: YesNo;
    adviseTitle: string;
    adviseFirstName: string;
    adviseSurname: string;
    adviseAddress1: string;
    adviseAddress2: string;
    adviseSuburb: string;
    adviseState: string;
    advisePostcode: string;
    exported: string;
    date: string;
    emailMajorDonor: boolean;
    mothersDay: boolean;
    mothersDayCards: DonationMothersDayCard[];
    mothersDayFridayDate: string;
    mothersDaySundayDate: string;
    mothersDayCardAmountRequirement: number;
    mothersDayCardPostageCost: number;
    mothersDayCardPriorityPostageCost: number;
    mothersDayCardExpressPostageCost: number;
    raceForACureShareName: YesNo;
    raceForACureName: string;
}

export class TemporaryFile
{
    id: string;
    name: string;
    contentType: string;
    extension: string;
    fileSize: number;
    image: boolean;
    dateUploaded: string;
}

export type SupporterMessageType = "Normal" | "MothersDay" | "Christmas" | "Video" | "Diary";

export class StoryMessage
{
    firstName: string;
    message: string;
    file: TemporaryFile;
    referenceNumber: string;
    mothersDayHonor: string;
    mothersDayMessage: string;
    caption: string;
    supporterMessageType: SupporterMessageType;
}

export type FileType = "Public" | "Private" | "Trial";

export type PropertyGroup = "None" | "Image";

export class FileProps
{
    fileType: FileType;
    itemId: string;
    categoryId: number;
    subcategoryId: number;
    fileId: number;
    name: string;
    propertyGroup: PropertyGroup;
    previousSelection: FileProps;
    hasFileSelection: boolean;
    selectedFileId: number;
}

export class EmailConfiguration
{
    banner: FileProps;
    footerImage: FileProps;
    attachments: FileProps[];
    openingText: string;
    closingText: string;
    psText: string;
    openingTextConverted: string;
    closingTextConverted: string;
    psTextConverted: string;
    christmasClosure: boolean;
    christmasClosureStartDate: string;
    christmasClosureEndDate: string;
    christmasClosureFinalOrderDate: string;
}

export class Payment
{
    attemptNumber: number;
    approved: boolean;
    expired: boolean;
    insufficientFunds: boolean;
    declined: boolean;
    declinedByBank: boolean;
    unattempted: boolean;
}

export class EofyReceipt
{
    constituentId: string;
    addressee: string;
    salutation: string;
    position: string;
    organisation: string;
    receiptAmount: string;
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
    frequency: string;
    receiptNumber: string;
    date: string;
    receiptName: string;
}

export class ResidentialAddressOption
{
    id: string;
    label: string;
}

export class Address
{
    id: string;
    streetLine: string;
    suburb: string;
    postcode: string;
    state: string;
    country: string;
}

export type EoiTrial = "Diamond" | "BrcaP" | "Neon" | "Capture";

export class Eoi
{
    id: number;
    instCode: number;
    institution: string;
    enteredBy: string;
    modifiedBy: string;
    dateEntered: string;
    dateModified: string;
    completedBy: string;
    principalInvestigator: string;
    data: { [index:string]: Object; };
}

export class InstitutionsOffline
{
    instId: number;
    institution: string;
    institutionUrl: string;
    state: string;
    suburb: string;
}

export class EoiCustomInstitution
{
    id: number;
    institution: string;
}

export class Role
{
    roleId: number;
    name: string;
    roleRank: number;
    users: User[];
}

export class FileNotificationOptionalRoles
{
    id: number;
    roleId: number;
    role: Role;
}

export type FileNotificationPriority = "Normal" | "High";

export class FileNotification
{
    id: number;
    fileId: number;
    priority: FileNotificationPriority;
    comment: string;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
    notification: FileNotification;
}

export class File
{
    id: number;
    fileCategoryId: number;
    fileSubCategoryId: number;
    temporaryFile: TemporaryFile;
    name: string;
    additionalInformation: string;
    contentType: string;
    extension: string;
    fileSize: number;
    enabled: boolean;
    order: number;
    image: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateUploaded: string;
    dateEntered: string;
    dateModified: string;
    notification: FileNotification;
    selectedRoles: number[];
}

export class FileSubCategory
{
    id: number;
    fileCategoryId: number;
    name: string;
    description: string;
    hideFromMenu: boolean;
    enabled: boolean;
    order: number;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
    files: File[];
}

export class FileCategory
{
    id: number;
    fileType: FileType;
    itemId: string;
    name: string;
    description: string;
    hideFromMenu: boolean;
    enabled: boolean;
    order: number;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
    fileSubCategories: FileSubCategory[];
    files: File[];
    authorisationDefinitions: AuthorisationDefinition[];
}

export type DragDirection = "Up" | "Down" | "Left" | "Right";

export class TrialStudyLookup
{
    id: number;
    name: string;
    shortName: string;
}

export type ANZCountryType = "Australia" | "NewZealand";

export class Referee
{
    name: string;
    position: string;
    organisation: string;
    address: string;
    postcode: string;
    country: ANZCountryType;
    telephone: string;
    mobile: string;
}

export class ImpactAdvocateApplication
{
    title: string;
    firstName: string;
    surname: string;
    address: string;
    suburb: string;
    state: string;
    postcode: string;
    country: ANZCountryType;
    homePhone: string;
    businessPhone: string;
    mobilePhone: string;
    email: string;
    occupation: string;
    trialParticipation: boolean;
    isBctTrial: boolean;
    bctTrialName: string;
    diagnosed: boolean;
    diagnosisYear: number;
    educationalLevel: string;
    educationComments: string;
    ageRange: string;
    organisationDescription: string;
    referees: Referee[];
    advocateEssay: TemporaryFile;
}

export class PageViewContentProperty
{
    id: number;
    pageVersionContentId: number;
    name: string;
    value: string;
    propertyGroup: PropertyGroup;
}

export class PageViewContent
{
    id: number;
    pageVersionId: number;
    parentPageVersionContentId: number;
    type: string;
    container: string;
    contents: PageViewContent[];
    properties: PageViewContentProperty[];
}

export class LayoutView
{
    id: number;
    layout: PageViewContent[];
}

export type MembershipCategoryType = "FullMember" | "AffiliateMember";

export type MembershipChangeType = "Remain" | "Change" | "Cancel";

export class Membership
{
    membid: number;
    username: string;
    memcat: MembershipCategoryType;
    offlineid: string;
    changingMembership: MembershipChangeType;
    changeReason: string;
    renewalRequired: boolean;
}

export class MenuItem
{
    id: number;
    parentMenuItemId: number;
    external: boolean;
    name: string;
    path: string;
    menuItems: MenuItem[];
}

export type MenuItemType = "Heading" | "External" | "Internal" | "Document" | "Page" | "PublicTrials" | "PublicTrial" | "Webforms" | "WebformsExternal";

export class MenuItemDetails
{
    id: number;
    parentMenuItemId: number;
    menuItemType: MenuItemType;
    itemId: string;
    enabled: boolean;
    name: string;
    path: string;
    order: number;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
    menuItems: MenuItemDetails[];
    authorisationDefinitions: AuthorisationDefinition[];
}

export class MerchandiseItemPurchase
{
    id: number;
    itemId: number;
    merchandiseId: number;
    quantity: number;
    itemCost: number;
    itemPostage: number;
    raisersEdgeCost: number;
    raisersEdgePostage: number;
    fieldValues: KeyValuePair<MerchandiseItemField,MerchandiseItemFieldValue>[];
}

export class MerchandiseItem
{
    id: number;
    name: string;
    title: string;
    image: string;
    description: string;
    cost: number;
    postage: number;
    live: boolean;
    bulkOrderThreshold: number;
    costDiscounted: number;
    isDiscountedCostCharged: boolean;
    isPostageCharged: boolean;
}

export class StockedMerchandiseItemStock
{
    merchandiseItemId: number;
    fieldValues: KeyValuePair<MerchandiseItemField,MerchandiseItemFieldValue>[];
    stock: number;
    stockId: number;
}

export class StockedMerchandiseItem
{
    merchandiseItem: MerchandiseItem;
    stockedItems: StockedMerchandiseItemStock[];
}

export class Merchandise
{
    id: number;
    refNo: string;
    donatedBefore: boolean;
    donorId: string;
    title: string;
    gender: number;
    firstName: string;
    surname: string;
    address1: string;
    address2: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
    phone: string;
    phoneType: PhoneType;
    email: string;
    dob: string;
    diaryStock: number;
    diaryHeard: string;
    diaryHeardOther: string;
    diaryShareMessage: string;
    discountDiaryCost: number;
    diaryDonationRequired: number;
    cost: number;
    postage: number;
    donateRefNo: string;
    donateReceiptNo: string;
    donationAmount: number;
    paymentType: PaymentType;
    cardType: CreditCardType;
    cardName: string;
    cardNo: string;
    cardExp: string;
    cardCcv: string;
    payPalNonce: string;
    payPalClientToken: string;
    exported: string;
    date: string;
    paid: boolean;
    declined: boolean;
    attemptNo: number;
    organisation: boolean;
    orgName: string;
    orgPosition: string;
    promoCode: string;
    promoFields: { [index:number]: string; };
    merchandiseItemPurchases: MerchandiseItemPurchase[];
    stockedMerchandiseItems: StockedMerchandiseItem[];
    shoppingCart: StockedMerchandiseItem[];
}

export type MerchandisePromoFieldType = "Text" | "Choice";

export class MerchandisePromoFieldValue
{
    id: number;
    merchandisePromoFieldId: number;
    label: string;
    value: string;
    order: number;
    enabled: boolean;
}

export class MerchandisePromoField
{
    id: number;
    merchandisePromoId: number;
    label: string;
    type: MerchandisePromoFieldType;
    order: number;
    defaultValue: string;
    attribute: string;
    required: boolean;
    enabled: boolean;
    values: MerchandisePromoFieldValue[];
}

export class MerchandisePromoItemFieldValue
{
    id: number;
    merchandisePromoItemId: number;
    merchandiseItemFieldId: number;
    merchandiseItemFieldValueId: number;
}

export class MerchandisePromoItem
{
    id: number;
    merchandisePromoId: number;
    merchandiseItemId: number;
    cost: number;
    postage: number;
    raisersEdgeCost: number;
    raisersEdgePostage: number;
    enabled: boolean;
    values: MerchandisePromoItemFieldValue[];
}

export class MerchandisePromo
{
    id: number;
    code: string;
    title: string;
    description: string;
    deliveryMessage: string;
    enabled: boolean;
    startDate: string;
    endDate: string;
    fields: MerchandisePromoField[];
    items: MerchandisePromoItem[];
}

export class MerchandiseEmailConfiguration
{
    christmasClosure: boolean;
    christmasClosureStartDate: string;
    christmasClosureEndDate: string;
    christmasClosureFinalOrderDate: string;
}

export class MerchandisePromoFieldAnswer
{
    id: number;
    merchandiseId: number;
    merchandisePromoFieldId: number;
    value: string;
}

export class MerchandiseItemStockQuantity
{
    id: number;
    stock: number;
}

export type CommitteeType = "Sac" | "SacSts" | "SacLts" | "Cap" | "SacScs" | "Fac";

export class CommitteeMember
{
    title: string;
    firstName: string;
    lastName: string;
    position: string;
}

export class ResearchBlogCategory
{
    id: number;
    name: string;
    researchBlogCount: number;
}

export class ResearchBlogCategorySummary
{
    id: number;
    name: string;
    enabled: boolean;
    researchBlogCount: number;
}

export class ResearchBlogCategoryDetails
{
    id: number;
    name: string;
    enabled: boolean;
    researchBlogCount: number;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
}

export class ResearchBlog
{
    id: number;
    layoutId: number;
    categoryId: number;
    imageId: number;
    seoImageId: number;
    showFacebook: boolean;
    facebookQuote: string;
    facebookHashTag: string;
    showTwitter: boolean;
    twitterText: string;
    twitterHashTags: string;
    showLinkedIn: boolean;
    showEmail: boolean;
    emailSubject: string;
    emailBody: string;
    audioId: number;
    iFrameUrl: string;
    iFrameWidth: string;
    iFrameHeight: string;
    title: string;
    slug: string;
    summary: string;
    date: string;
    enabled: boolean;
    layout: LayoutView;
}

export class ResearchBlogSummary
{
    id: number;
    categoryId: number;
    title: string;
    slug: string;
    date: string;
    enabled: boolean;
}

export class ResearchBlogDetails
{
    id: number;
    layoutId: number;
    categoryId: number;
    imageId: number;
    seoImageId: number;
    showFacebook: boolean;
    facebookQuote: string;
    facebookHashTag: string;
    showTwitter: boolean;
    twitterText: string;
    twitterHashTags: string;
    showLinkedIn: boolean;
    showEmail: boolean;
    emailSubject: string;
    emailBody: string;
    audioId: number;
    iFrameUrl: string;
    iFrameWidth: string;
    iFrameHeight: string;
    title: string;
    slug: string;
    summary: string;
    summaryText: string;
    date: string;
    enabled: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
    layout: PageViewContent[];
}

export type NewsDisplayType = "Everyone" | "ResearchOnly" | "PublicOnly";

export class News
{
    id: number;
    title: string;
    date: string;
    shortDescription: string;
    text: string;
    live: boolean;
    hideFromSearch: boolean;
    type: number;
    research: boolean;
    moreLink: string;
    embargoDate: string;
    description: string;
    displayType: NewsDisplayType;
    urlAddress: string;
    membersOnly: boolean;
    frontPage: boolean;
    rightText: string;
    imageExt: string;
    contentType: string;
    image: boolean;
    youtubeUrl: string;
}

export class NewsSummary
{
    id: number;
    title: string;
    date: string;
    shortDescription: string;
    text: string;
    postedBy: string;
    live: boolean;
    hideFromSearch: boolean;
    type: number;
    research: boolean;
    moreLink: string;
    embargoDate: string;
    description: string;
    displayType: NewsDisplayType;
    urlAddress: string;
    membersOnly: boolean;
    frontPage: boolean;
    rightText: string;
    imageExt: string;
    contentType: string;
    image: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
}

export class NewsDetails
{
    id: number;
    title: string;
    date: string;
    shortDescription: string;
    text: string;
    live: boolean;
    hideFromSearch: boolean;
    type: number;
    research: boolean;
    moreLink: string;
    embargoDate: string;
    description: string;
    displayType: NewsDisplayType;
    urlAddress: string;
    membersOnly: boolean;
    frontPage: boolean;
    rightText: string;
    imageExt: string;
    contentType: string;
    seoFileId: number;
    image: boolean;
    youtubeUrl: string;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
}

export class Notice
{
    recentId: number;
    title: string;
    date: string;
    urlAddress: string;
    live: boolean;
    imageTagStart: string;
    imageTagEnd: string;
    newWindow: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
}

export class NoticeSummary
{
    recentId: number;
    title: string;
    date: string;
    urlAddress: string;
    live: boolean;
    imageTagStart: string;
    imageTagEnd: string;
    newWindow: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
}

export class NoticeDetails
{
    recentId: number;
    title: string;
    date: string;
    urlAddress: string;
    live: boolean;
    imageTagStart: string;
    imageTagEnd: string;
    newWindow: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
}

export class PageViewSlug
{
    id: number;
    pageId: number;
    path: string;
    preferred: boolean;
}

export class PageSlug extends PageViewSlug
{
    enabled: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
}

export class PageVersionContent extends PageViewContent
{
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
}

export class PageVersion
{
    id: number;
    pageId: number;
    versionNumber: number;
    title: string;
    seoTitle: string;
    seoDescription: string;
    seoFileId: number;
    seoUrl: string;
    enabled: boolean;
    editable: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
    contents: PageVersionContent[];
}

export class Page
{
    id: number;
    enabled: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
    slugs: PageSlug[];
    versions: PageVersion[];
}

export class PageSummary
{
    id: number;
    preferredSlug: string;
    liveTitle: string;
    pageSlugCount: number;
    pageVersionCount: number;
    enabled: boolean;
    versionEnabled: boolean;
    dateEntered: string;
    dateModified: string;
}

export class PageSlugSummary
{
    id: number;
    pageId: number;
    path: string;
    preferred: boolean;
    enabled: boolean;
    dateEntered: string;
    dateModified: string;
}

export class PageVersionSummary
{
    id: number;
    pageId: number;
    versionNumber: number;
    editable: boolean;
    title: string;
    enabled: boolean;
    dateEntered: string;
    dateModified: string;
}

export class PageDetails
{
    id: number;
    enabled: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
    authorisationDefinitions: AuthorisationDefinition[];
}

export class PageSlugDetails
{
    id: number;
    pageId: number;
    path: string;
    preferred: boolean;
    enabled: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
}

export class PageVersionDetails
{
    id: number;
    pageId: number;
    versionNumber: number;
    title: string;
    seoTitle: string;
    seoDescription: string;
    seoFileId: number;
    seoUrl: string;
    enabled: boolean;
    showTitle: boolean;
    editable: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
}

export class NewPage
{
    id: number;
    path: string;
    title: string;
    seoTitle: string;
    seoDescription: string;
    seoFileId: number;
    seoUrl: string;
}

export class PageView
{
    id: number;
    versionId: number;
    showTitle: boolean;
    title: string;
    seoTitle: string;
    seoDescription: string;
    seoUrl: string;
    slugs: PageViewSlug[];
    layout: PageViewContent[];
}

export class ProposerUser
{
    username: string;
    firstName: string;
    surname: string;
}

export type SearchLinkType = "Internal" | "External";

export class SearchResult
{
    table: string;
    searchTitle: string;
    searchLink: string;
    searchLinkType: SearchLinkType;
    searchSummary: string;
}

export type StoryBadgeType = "Diary" | "Christmas" | "MothersDay" | "Normal";

export class Story
{
    id: number;
    firstName: string;
    message: string;
    live: boolean;
    dateAdded: string;
    refNo: string;
    forName: string;
    supporterMessageTypeID: SupporterMessageType;
    defaultImage: string;
    image: boolean;
    titleColorIndex: number;
    videoUrl: string;
    storyBadgeTypes: StoryBadgeType[];
    diaryImage: string;
    caption: string;
}

export class MothersDayStoryImport
{
    giftDate: string;
    name: string;
    honorName: string;
    live: boolean;
    completeMessage: string;
}

export class Publication
{
    pubId: number;
    year: number;
    type: number;
    title: string;
    author: string;
    publicationName: string;
    volume: string;
    number: string;
    abstractNo: string;
    pages: string;
    month: number;
    trialStatusUpdate: boolean;
    tcdReport: boolean;
    boardReport: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
}

export class PublicationType
{
    id: number;
    type: string;
}

export type TrialType = "EarlyBreastCancer" | "Metastatic" | "Prevention";

export type TrialStatus = "None" | "Open" | "ActivationPending" | "Closed";

export class PublicationTrialInfo
{
    id: string;
    protocolId: string;
    title: string;
    trialType: TrialType;
    trialStatus: TrialStatus;
}

export class PublicationSummary
{
    pubId: number;
    year: number;
    type: number;
    title: string;
    author: string;
    publicationName: string;
    volume: string;
    number: string;
    abstractNo: string;
    pages: string;
    month: number;
    trialStatusUpdate: boolean;
    tcdReport: boolean;
    boardReport: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
    trialInfos: PublicationTrialInfo[];
}

export class TrialLookup
{
    id: string;
    name: string;
}

export class PublicationDetails
{
    pubId: number;
    year: number;
    type: number;
    title: string;
    author: string;
    publicationName: string;
    volume: string;
    number: string;
    abstractNo: string;
    pages: string;
    month: number;
    trialStatusUpdate: boolean;
    tcdReport: boolean;
    boardReport: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
    trialLookups: TrialLookup[];
}

export class TrainingCourseResponseItem
{
    id: number;
    trainingCourseResponseId: number;
    label: string;
    value: string;
    rank: number;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
}

export class TrainingCourseResponse
{
    id: number;
    trainingCourseQuestionId: number;
    label: string;
    type: number;
    correctResponse: string;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
    trainingCourseResponseItems: TrainingCourseResponseItem[];
}

export class TrainingCourseQuestion
{
    id: number;
    trainingCourseId: number;
    text: string;
    rank: number;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
    trainingCourseResponses: TrainingCourseResponse[];
}

export class TrainingCourse
{
    id: number;
    name: string;
    passMarkPercentage: number;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
    trainingCourseQuestions: TrainingCourseQuestion[];
}

export class TrainingCourseAttemptResponse
{
    id: number;
    trainingCourseAttemptId: number;
    trainingCourseResponseId: number;
    response: string;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
}

export class TrainingCourseAttempt
{
    id: number;
    trainingCourseId: number;
    username: string;
    markPercentage: number;
    passed: boolean;
    enteredByUsername: string;
    modifiedByUsername: string;
    dateEntered: string;
    dateModified: string;
    trainingCourseAttemptResponses: TrainingCourseAttemptResponse[];
}

export interface ITrialSummary
{
    trial?: string;
    protocolId?: string;
    title?: string;
    description?: string;
    trialStatus?: TrialStatus;
    trialType?: string;
    trialTypeColourIndex?: number;
    defaultTrial?: boolean;
    slug?: string;
    fullName?: string;
}

export class TrialPublicDetails
{
    trial: string;
    publicTitle: string;
    publicDescription: string;
    publicScientificTitle: string;
    protocolId: string;
    publicSummary: string;
    publicVideoUrl: string;
    publicImageUrl: string;
    publicImageTitle: string;
    publicImageDescription: string;
    publicSlug: string;
    publicInactiveInstitutions: boolean;
    publicRegisterYourInterest: boolean;
    publicRegisterYourInterestIntro: string;
    publicHowYouCanHelpUrl: string;
    publicHowYouCanHelpText: string;
    openInstitutions: InstitutionsOffline[];
    pendingInstitutions: InstitutionsOffline[];
}

export type TrialInstitutionStatus = "Invited" | "ActivationPending" | "Activated" | "Withdrawn";

export class TrialStudyInstitution
{
    trialId: string;
    offlineId: number;
    instId: number;
    status: TrialInstitutionStatus;
    dateFirstPos: string;
    earlyRecClosure: string;
    confidentialitySigned: boolean;
    trialClosed: boolean;
    institutionsOffline: InstitutionsOffline;
}

export class TrialStudyAccrualANZ
{
    trialId: string;
    offlineId: number;
    instId: number;
    year: string;
    january: number;
    february: number;
    march: number;
    april: number;
    may: number;
    june: number;
    july: number;
    august: number;
    september: number;
    october: number;
    november: number;
    december: number;
    trialStudyInstitution: TrialStudyInstitution;
}

export type TrialMenuItemType = "Substudies" | "Institutions" | "AccrualTables" | "Schema" | "Documents" | "Contacts" | "Ecrf" | "External" | "Content" | "Randomisation" | "Publications";

export type TrialProtectionType = "All" | "ActiveTrialRole";

export class TrialMenuItem
{
    id: number;
    trialStudyId: number;
    trialMenuItemType: TrialMenuItemType;
    trialProtectionType: TrialProtectionType;
    itemId: number;
    path: string;
    name: string;
}

export class TrialStudy
{
    trialId: string;
    offlineId: number;
    websiteName: string;
    dateAnzAccrualComplete: string;
    dateIntlAccrualComplete: string;
    rank: string;
    targetAccrual: string;
    dateAnzActivation: string;
    dateIntlActivation: string;
    subStudy: boolean;
    mainStudy: number;
    trialName: string;
    trialTitle: string;
    restricted: boolean;
    trialStudyAccrualAnz: TrialStudyAccrualANZ[];
    internationalAccrual: number;
    trialMenuItems: TrialMenuItem[];
}

export class TrialPrivateDetails
{
    id: string;
    protocolId: string;
    title: string;
    trialType: TrialType;
    trialStatus: TrialStatus;
    description: string;
    proposedAccrual: string;
    registered: string;
    enquiryEmail: string;
    activated: string;
    mainStudy: TrialStudy;
    subStudies: TrialStudy[];
}

export class TrialContact
{
    username: string;
    title: string;
    firstName: string;
    surname: string;
    email: string;
    company: string;
    position: string;
    phone: string;
    fax: string;
    mobile: string;
    imageUrl: string;
}

export class TrialContactRole
{
    id: number;
    name: string;
    contacts: TrialContact[];
}

export class TrialSubscription
{
    id: number;
    trialId: string;
    title: string;
    firstName: string;
    surName: string;
    phoneNumber: string;
    emailAddress: string;
    comments: string;
    dateEntered: string;
    subscribed: boolean;
    trialName: string;
}

export type UserSearchType = "Username" | "Surname";

export type MemberType = "FullMember" | "AffiliateMember" | "OtherMember";

export class KeyValuePair<TKey, TValue>
{
    key: TKey;
    value: TValue;
}

export class MerchandiseItemFieldValue
{
    id: number;
    fieldId: number;
    name: string;
    value: string;
    rank: number;
}

export class MerchandiseItemField
{
    id: number;
    merchandiseItemId: number;
    name: string;
    type: string;
    rank: number;
    initialValue: number;
    fieldValues: MerchandiseItemFieldValue[];
    enabled: boolean;
}

export class NewsletterRegistration
{
    public firstName: string;
    public lastName: string;
    public email: string;
}

export class ExternalMedia
{
    public id: number;
    public importId: string;
    public url: string;
    public thumbnailUrl: string;
    public postUrl: string;
    public platform: Platform;
    public mediaType: MediaType;
    public hashtag: string;
    public enabled: boolean;
    public modifiedBy: string;
    public creadtedDate: string;
    public enabledDate: string;
    public deletedDate: string;
}

export type Platform = "Instagram";

export type MediaType = "Image" | "Video" | "Carousel";

export class GetAuthorisationDefinitionResponse
{
    responseStatus: ResponseStatus;
    authorisationDefinition: AuthorisationDefinition;
}

export class GetAssignableAuthorisationDefinitionsResponse
{
    responseStatus: ResponseStatus;
    authorisationDefinitions: AuthorisationDefinition[];
}

export class GetUserResponse
{
    responseStatus: ResponseStatus;
    user: AuthUser;
}

export class GetVersionGuidResponse
{
    responseStatus: ResponseStatus;
    guid: string;
}

export class UpdateVersionGuidResponse
{
    responseStatus: ResponseStatus;
}

export class AutoCompleteAddressResponse
{
    responseStatus: ResponseStatus;
    results: Object;
}

export class GetBoardNomineesResponse
{
    responseStatus: ResponseStatus;
    boardNominees: BoardNominee[];
}

export class GetUserHasBoardVotedResponse
{
    responseStatus: ResponseStatus;
    voted: boolean;
}

export class GetBoardVotesResponse
{
    responseStatus: ResponseStatus;
    boardVotes: BoardVote[];
}

export class SubmitBoardVoteResponse
{
    responseStatus: ResponseStatus;
    success: boolean;
}

export class GetCurrentCapReviewsResponse
{
    responseStatus: ResponseStatus;
    capReviewDocSummaries: CapReviewDocSummary[];
}

export class GetCapReviewResponse
{
    responseStatus: ResponseStatus;
    capReviewDoc: CapReviewDoc;
}

export class GetCapReviewQuestionsResponse
{
    responseStatus: ResponseStatus;
    capReviewQuestions: CapReviewQuestion[];
}

export class GetCapReviewCommentsResponse
{
    responseStatus: ResponseStatus;
    capReviewComments: CapReviewComment[];
}

export class SaveCapReviewCommentResponse
{
    responseStatus: ResponseStatus;
    success: boolean;
}

export class GetCapReviewPrintResponse
{
    responseStatus: ResponseStatus;
    capReviewDocComplete: CapReviewDocComplete;
}

export class PostDiaryOrderResponse
{
    responseStatus: ResponseStatus;
    diaryOrder: DiaryOrder;
}

export class ExportDiaryOrderResponse
{
    responseStatus: ResponseStatus;
    export: string;
}

export class GetDonationResponse
{
    responseStatus: ResponseStatus;
    donation: Donation;
}

export class SubmitDonationResponse
{
    responseStatus: ResponseStatus;
    payment: Payment;
    donation: Donation;
    storyMessage: StoryMessage;
}

export class GetDonationConfirmationResponse
{
    responseStatus: ResponseStatus;
    donation: Donation;
}

export class GetDonationMothersDayCardsResponse
{
    responseStatus: ResponseStatus;
    mothersDayCards: DonationMothersDayCard[];
    mothersDayFridayDate: string;
    mothersDaySundayDate: string;
    mothersDayCardAmountRequirement: number;
    mothersDayCardPostageCost: number;
    mothersDayCardPriorityPostageCost: number;
    mothersDayCardExpressPostageCost: number;
}

export class SendMothersDayCardEmailResponse
{
    responseStatus: ResponseStatus;
    success: boolean;
}

export class UpdateDonationMothersDayCardsResponse
{
    responseStatus: ResponseStatus;
    mothersDayCards: DonationMothersDayCard[];
}

export class SendDonationEmailResponse
{
    responseStatus: ResponseStatus;
    success: boolean;
}

export class GetDonatedAmountResponse
{
    responseStatus: ResponseStatus;
    amount: number;
}

export class GetMothersDayCardExportDatesResponse
{
    responseStatus: ResponseStatus;
    exportDates: string[];
}

export class GetDonationsWithMothersDayCardExportDatesResponse
{
    responseStatus: ResponseStatus;
    exportDates: string[];
}

export class PostMothersDayCardExportDateResetResponse
{
    responseStatus: ResponseStatus;
}

export class GetDiaryNameResponse
{
    responseStatus: ResponseStatus;
    diaryName: string;
}

export class GetDonationTotalResponse
{
    responseStatus: ResponseStatus;
    total: number;
}

export class GetResidentialAddressOptionListResponse
{
    responseStatus: ResponseStatus;
    residentialAddressOptionList: ResidentialAddressOption[];
    residentialAddressInfoList: Address[];
}

export class GetAddressInfoOutsideAustraliaResponse
{
    responseStatus: ResponseStatus;
    addressInfo: Address;
}

export class SaveEoiResponse
{
    responseStatus: ResponseStatus;
    eoi: Eoi;
}

export class GetEoiResponse
{
    responseStatus: ResponseStatus;
    eoi: Eoi;
}

export class GetEoiInstitutionsResponse
{
    responseStatus: ResponseStatus;
    institutions: InstitutionsOffline[];
}

export class GetCompleteEoiInstitutionsResponse
{
    responseStatus: ResponseStatus;
    institutions: InstitutionsOffline[];
}

export class GetEoiCustomInstitutionsResponse
{
    responseStatus: ResponseStatus;
    institutions: EoiCustomInstitution[];
}

export class DoFileImportResponse
{
    responseStatus: ResponseStatus;
}

export class UploadTempFileResponse
{
    responseStatus: ResponseStatus;
    guid: string;
}

export class GetTempFileInfoResponse
{
    responseStatus: ResponseStatus;
    temporaryFile: TemporaryFile;
}

export class GetOptionalRolesResponse
{
    responseStatus: ResponseStatus;
    optionalRoles: FileNotificationOptionalRoles[];
}

export class GetFileCategoriesResponse
{
    responseStatus: ResponseStatus;
    fileCategories: FileCategory[];
}

export class GetFileSubCategoriesResponse
{
    responseStatus: ResponseStatus;
    fileSubCategories: FileSubCategory[];
}

export class GetFileInfoResponse
{
    responseStatus: ResponseStatus;
    file: File;
    fileType: FileType;
    itemId: string;
}

export class SaveFileResponse
{
    responseStatus: ResponseStatus;
    fileCategories: FileCategory[];
}

export class DeleteFileResponse
{
    responseStatus: ResponseStatus;
    fileCategories: FileCategory[];
}

export class GetFileUrlResponse
{
    responseStatus: ResponseStatus;
    fileUrl: string;
}

export class SaveCategoryResponse
{
    responseStatus: ResponseStatus;
    fileCategories: FileCategory[];
}

export class DeleteCategoryResponse
{
    responseStatus: ResponseStatus;
    fileCategories: FileCategory[];
}

export class SaveSubCategoryResponse
{
    responseStatus: ResponseStatus;
    fileCategories: FileCategory[];
}

export class DeleteSubCategoryResponse
{
    responseStatus: ResponseStatus;
    fileCategories: FileCategory[];
}

export class SortCategoryResponse
{
    responseStatus: ResponseStatus;
    fileCategories: FileCategory[];
}

export class SortSubCategoryResponse
{
    responseStatus: ResponseStatus;
    fileCategories: FileCategory[];
}

export class SortFileResponse
{
    responseStatus: ResponseStatus;
    fileCategories: FileCategory[];
}

export class GetFileNotificationsResponse
{
    responseStatus: ResponseStatus;
    fileCategories: FileCategory[];
    trialStudies: TrialStudyLookup[];
}

export class UpdateFileNotificationsResponse
{
    responseStatus: ResponseStatus;
    fileCategories: FileCategory[];
    trialStudies: TrialStudyLookup[];
}

export class DeleteFileNotificationsResponse
{
    responseStatus: ResponseStatus;
    fileCategories: FileCategory[];
    trialStudies: TrialStudyLookup[];
}

export class SubmitImpactAdvocateApplicationResponse
{
    responseStatus: ResponseStatus;
}

export class GetLayoutsContentResponse
{
    responseStatus: ResponseStatus;
    layouts: LayoutView[];
}

export class GetLayoutContentResponse
{
    responseStatus: ResponseStatus;
    layout: PageViewContent[];
    editable: boolean;
}

export class SaveLayoutContentResponse
{
    responseStatus: ResponseStatus;
    layout: PageViewContent[];
}

export class GetMembershipResponse
{
    responseStatus: ResponseStatus;
    membership: Membership;
}

export class SubmitMembershipRenewalResponse
{
    responseStatus: ResponseStatus;
    userProfile: User;
    membership: Membership;
}

export class GetMenuResponse
{
    responseStatus: ResponseStatus;
    menuItems: MenuItem[];
}

export class GetSiblingMenuItemsResponse
{
    responseStatus: ResponseStatus;
    menuItem: MenuItem;
}

export class GetMenuItemDetailsResponse
{
    responseStatus: ResponseStatus;
    menuItemDetails: MenuItemDetails[];
}

export class SaveMenuItemDetailsResponse
{
    responseStatus: ResponseStatus;
    menuItemDetails: MenuItemDetails[];
}

export class DeleteMenuItemDetailsResponse
{
    responseStatus: ResponseStatus;
    menuItemDetails: MenuItemDetails[];
}

export class SortMenuItemResponse
{
    responseStatus: ResponseStatus;
    menuItemDetails: MenuItemDetails[];
}

export class GetMerchandiseResponse
{
    responseStatus: ResponseStatus;
    merchandise: Merchandise;
    merchandisePromo: MerchandisePromo;
}

export class SubmitMerchandiseResponse
{
    responseStatus: ResponseStatus;
    payment: Payment;
    merchandise: Merchandise;
}

export class GetCompletedMerchandiseResponse
{
    responseStatus: ResponseStatus;
    merchandise: Merchandise;
    merchandiseItems: MerchandiseItem[];
    promoFields: MerchandisePromoField[];
    promoFieldAnswers: MerchandisePromoFieldAnswer[];
}

export class SendMerchandiseEmailResponse
{
    responseStatus: ResponseStatus;
    success: boolean;
}

export class SubmitMerchandiseItemStockQuantityUpdateResponse
{
    responseStatus: ResponseStatus;
    success: boolean;
}

export class GetCommitteeListResponse
{
    responseStatus: ResponseStatus;
    members: CommitteeMember[];
}

export class GetResearchBlogCategoryResponse
{
    responseStatus: ResponseStatus;
    researchBlogCategory: ResearchBlogCategory;
}

export class GetResearchBlogCategoriesResponse
{
    responseStatus: ResponseStatus;
    researchBlogCategories: ResearchBlogCategory[];
}

export class GetResearchBlogCategorySummariesResponse
{
    responseStatus: ResponseStatus;
    researchBlogCategorySummaries: ResearchBlogCategorySummary[];
}

export class GetResearchBlogCategorySummaryResponse
{
    responseStatus: ResponseStatus;
    researchBlogCategorySummary: ResearchBlogCategorySummary;
}

export class GetResearchBlogCategoryDetailsResponse
{
    responseStatus: ResponseStatus;
    researchBlogCategoryDetails: ResearchBlogCategoryDetails;
}

export class SaveResearchBlogCategoryDetailsResponse
{
    responseStatus: ResponseStatus;
    researchBlogCategoryDetails: ResearchBlogCategoryDetails;
}

export class DeleteResearchBlogCategoryResponse
{
    responseStatus: ResponseStatus;
}

export class GetResearchBlogResponse
{
    responseStatus: ResponseStatus;
    researchBlog: ResearchBlog;
}

export class GetResearchBlogsResponse
{
    responseStatus: ResponseStatus;
    researchBlogs: ResearchBlog[];
    hasMore: boolean;
}

export class GetResearchBlogSummariesResponse
{
    responseStatus: ResponseStatus;
    researchBlogSummaries: ResearchBlogSummary[];
}

export class GetResearchBlogSummaryResponse
{
    responseStatus: ResponseStatus;
    researchBlogSummary: ResearchBlogSummary;
}

export class GetResearchBlogDetailsResponse
{
    responseStatus: ResponseStatus;
    researchBlogDetails: ResearchBlogDetails;
}

export class SaveResearchBlogDetailsResponse
{
    responseStatus: ResponseStatus;
    researchBlogDetails: ResearchBlogDetails;
}

export class DeleteResearchBlogResponse
{
    responseStatus: ResponseStatus;
}

export class GetNewsItemResponse
{
    responseStatus: ResponseStatus;
    newsItem: News;
}

export class GetNewsItemsResponse
{
    responseStatus: ResponseStatus;
    newsItems: News[];
    hasMore: boolean;
}

export class GetNewsSummariesResponse
{
    responseStatus: ResponseStatus;
    newsSummaries: NewsSummary[];
}

export class GetNewsSummaryResponse
{
    responseStatus: ResponseStatus;
    newsSummary: NewsSummary;
}

export class GetNewsDetailsResponse
{
    responseStatus: ResponseStatus;
    newsDetails: NewsDetails;
}

export class SaveNewsDetailsResponse
{
    responseStatus: ResponseStatus;
    newsDetails: NewsDetails;
}

export class DeleteNewsResponse
{
    responseStatus: ResponseStatus;
}

export class ValidateMerchandisePromoResponse
{
    responseStatus: ResponseStatus;
    valid: boolean;
    merchandisePromo: MerchandisePromo;
}

export class GetNoticeResponse
{
    responseStatus: ResponseStatus;
    notice: Notice;
}

export class GetNoticesResponse
{
    responseStatus: ResponseStatus;
    notices: Notice[];
    hasMore: boolean;
}

export class GetNoticeSummariesResponse
{
    responseStatus: ResponseStatus;
    noticeSummaries: NoticeSummary[];
}

export class GetNoticeSummaryResponse
{
    responseStatus: ResponseStatus;
    noticeSummary: NoticeSummary;
}

export class GetNoticeDetailsResponse
{
    responseStatus: ResponseStatus;
    noticeDetails: NoticeDetails;
}

export class SaveNoticeDetailsResponse
{
    responseStatus: ResponseStatus;
    noticeDetails: NoticeDetails;
}

export class DeleteNoticeResponse
{
    responseStatus: ResponseStatus;
}

export class GetPageResponse
{
    responseStatus: ResponseStatus;
    page: Page;
}

export class GetPageSummariesResponse
{
    responseStatus: ResponseStatus;
    pageSummaries: PageSummary[];
}

export class GetPageSummaryResponse
{
    responseStatus: ResponseStatus;
    pageSummary: PageSummary;
}

export class GetPageSlugSummariesResponse
{
    responseStatus: ResponseStatus;
    pageSlugSummaries: PageSlugSummary[];
}

export class GetPageVersionSummariesResponse
{
    responseStatus: ResponseStatus;
    pageVersionSummaries: PageVersionSummary[];
}

export class GetPageDetailsResponse
{
    responseStatus: ResponseStatus;
    pageDetails: PageDetails;
}

export class GetPageSlugDetailsResponse
{
    responseStatus: ResponseStatus;
    pageSlugDetails: PageSlugDetails;
}

export class GetPageVersionDetailsResponse
{
    responseStatus: ResponseStatus;
    pageVersionDetails: PageVersionDetails;
}

export class SavePageDetailsResponse
{
    responseStatus: ResponseStatus;
    pageDetails: PageDetails;
}

export class SavePageSlugDetailsResponse
{
    responseStatus: ResponseStatus;
    pageSlugDetails: PageSlugDetails;
}

export class SavePageVersionDetailsResponse
{
    responseStatus: ResponseStatus;
    pageVersionDetails: PageVersionDetails;
}

export class DeletePageResponse
{
    responseStatus: ResponseStatus;
}

export class DeletePageSlugResponse
{
    responseStatus: ResponseStatus;
}

export class DeletePageVersionResponse
{
    responseStatus: ResponseStatus;
}

export class CreateNewPageResponse
{
    responseStatus: ResponseStatus;
    pageDetails: PageDetails;
}

export class GetPageViewResponse
{
    responseStatus: ResponseStatus;
    pageView: PageView;
}

export class GetPageVersionLayoutResponse
{
    responseStatus: ResponseStatus;
    layout: PageViewContent[];
    editable: boolean;
}

export class SavePageVersionLayoutResponse
{
    responseStatus: ResponseStatus;
    layout: PageViewContent[];
}

export class GetPageSlugPreferredResponse
{
    responseStatus: ResponseStatus;
    pageSlug: PageSlug;
}

export class CopyPageVersionResponse
{
    responseStatus: ResponseStatus;
    pageVersionId: number;
}

export class CreatePageFromVersionResponse
{
    responseStatus: ResponseStatus;
    pageId: number;
}

export class GetBoardNominationProposersResponse
{
    responseStatus: ResponseStatus;
    proposers: ProposerUser[];
}

export class SubmitBoardNominationResponse
{
    responseStatus: ResponseStatus;
    success: boolean;
}

export class GetSearchResultsResponse
{
    responseStatus: ResponseStatus;
    searchResults: SearchResult[];
    totalResultCount: number;
    hasMore: boolean;
}

export class GetStoryResponse
{
    responseStatus: ResponseStatus;
    story: Story;
}

export class GetStoriesResponse
{
    responseStatus: ResponseStatus;
    stories: Story[];
    hasMore: boolean;
}

export class SaveStoryMessageResponse
{
    responseStatus: ResponseStatus;
    success: boolean;
}

export class GetStoryDefaultImagesResponse
{
    responseStatus: ResponseStatus;
    imagePaths: string[];
}

export class ReadMothersDayStoriesResponse
{
    responseStatus: ResponseStatus;
    stories: MothersDayStoryImport[];
}

export class ImportMothersDayStoriesResponse
{
    responseStatus: ResponseStatus;
}

export class GetPublicationsResponse
{
    responseStatus: ResponseStatus;
    publications: Publication[];
}

export class GetPublicationTypesResponse
{
    responseStatus: ResponseStatus;
    publicationTypes: PublicationType[];
}

export class GetPublicationSummariesResponse
{
    responseStatus: ResponseStatus;
    publicationSummaries: PublicationSummary[];
}

export class GetPublicationSummaryResponse
{
    responseStatus: ResponseStatus;
    publicationSummary: PublicationSummary;
}

export class GetPublicationDetailsResponse
{
    responseStatus: ResponseStatus;
    publicationDetails: PublicationDetails;
}

export class SavePublicationDetailsResponse
{
    responseStatus: ResponseStatus;
    publicationDetails: PublicationDetails;
}

export class DeletePublicationResponse
{
    responseStatus: ResponseStatus;
}

export class GetTrainingCourseResponse
{
    responseStatus: ResponseStatus;
    trainingCourse: TrainingCourse;
}

export class SubmitTrainingCourseAttemptResponse
{
    responseStatus: ResponseStatus;
    success: boolean;
    trainingCourseAttempt: TrainingCourseAttempt;
}

export class GetUserHasPassedTrainingCourseResponse
{
    responseStatus: ResponseStatus;
    passed: boolean;
}

export class GetTrialListResponse
{
    responseStatus: ResponseStatus;
    trialSummaries: ITrialSummary[];
}

export class GetPublicTrialResponse
{
    responseStatus: ResponseStatus;
    trialPublicDetails: TrialPublicDetails;
}

export class GetTrialLookupResponse
{
    responseStatus: ResponseStatus;
    trialLookups: TrialLookup[];
}

export class GetTrialStudyLookupResponse
{
    responseStatus: ResponseStatus;
    trialStudyLookups: TrialStudyLookup[];
}

export class GetTrialFilesResponse
{
    responseStatus: ResponseStatus;
    fileCategories: FileCategory[];
}

export class GetPrivateTrialResponse
{
    responseStatus: ResponseStatus;
    trialPrivateDetails: TrialPrivateDetails;
}

export class GetPrivateTrialInstitutionsResponse
{
    responseStatus: ResponseStatus;
    institutions: InstitutionsOffline[];
}

export class GetPrivateTrialContactsResponse
{
    responseStatus: ResponseStatus;
    contactRoles: TrialContactRole[];
}

export class GetPrivateTrialContentResponse
{
    responseStatus: ResponseStatus;
    trialContent: PageView;
}

export class GetTrialContentVersionLayoutResponse
{
    responseStatus: ResponseStatus;
    layout: PageViewContent[];
}

export class SaveTrialContentVersionLayoutResponse
{
    responseStatus: ResponseStatus;
    layout: PageViewContent[];
}

export class GetTrialPublicationsResponse
{
    responseStatus: ResponseStatus;
    trialPublications: Publication[];
}

export class GetTrialsActiveForUserResponse
{
    responseStatus: ResponseStatus;
    trialSummaries: ITrialSummary[];
}

export class GetTrialSubscriptionsResponse
{
    responseStatus: ResponseStatus;
    trialSubscriptions: TrialSubscription[];
}

export class GetTrialSubscriptionsByIdResponse
{
    responseStatus: ResponseStatus;
    trialSubscriptions: TrialSubscription[];
}

export class GetTrialSubscriptionsBySearchResponse
{
    responseStatus: ResponseStatus;
    trialSubscriptions: TrialSubscription[];
}

export class GetTrialUnsubscribedResponse
{
    responseStatus: ResponseStatus;
    trialSubscriptions: TrialSubscription[];
}

export class GetTrialUnsubscribedByIdResponse
{
    responseStatus: ResponseStatus;
    trialSubscriptions: TrialSubscription[];
}

export class PostTrialSubscriptionResponse
{
    responseStatus: ResponseStatus;
    trialSubscription: TrialSubscription;
}

export class ToggleSubscriptionResponse
{
    responseStatus: ResponseStatus;
    id: number;
    subscribed: boolean;
}

export class EditSubscriptionResponse
{
    responseStatus: ResponseStatus;
}

export class GetUserProfileResponse
{
    responseStatus: ResponseStatus;
    userProfile: User;
}

export class SubmitUserProfileResponse
{
    responseStatus: ResponseStatus;
    userProfile: User;
}

export class GetUserCompaniesResponse
{
    responseStatus: ResponseStatus;
    companies: string[];
}

export class GetUserSearchResultsResponse
{
    responseStatus: ResponseStatus;
    userSearchResults: User[];
}

export class GetUserHasAuthResponse
{
    responseStatus: ResponseStatus;
    hasAuth: boolean;
    memberType: MemberType;
    username: string;
    firstName: string;
    title: string;
    lastName: string;
}

export class GetInstagramPostsResponse {
    responseStatus: ResponseStatus;
    posts: ExternalMedia[];
}

// @DataContract
export class AuthenticateResponse
{
    // @DataMember(Order=1)
    userId: string;

    // @DataMember(Order=2)
    sessionId: string;

    // @DataMember(Order=3)
    userName: string;

    // @DataMember(Order=4)
    displayName: string;

    // @DataMember(Order=5)
    referrerUrl: string;

    // @DataMember(Order=6)
    bearerToken: string;

    // @DataMember(Order=7)
    refreshToken: string;

    // @DataMember(Order=8)
    responseStatus: ResponseStatus;

    // @DataMember(Order=9)
    meta: { [index:string]: string; };
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    allRoles: string[];

    // @DataMember(Order=2)
    allPermissions: string[];

    // @DataMember(Order=3)
    responseStatus: ResponseStatus;
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    allRoles: string[];

    // @DataMember(Order=2)
    allPermissions: string[];

    // @DataMember(Order=3)
    responseStatus: ResponseStatus;
}

export class GetAuthorisationDefinition implements IReturn<GetAuthorisationDefinitionResponse>
{
    createResponse() { return new GetAuthorisationDefinitionResponse(); }
    getTypeName() { return "GetAuthorisationDefinition"; }
}

export class GetAssignableAuthorisationDefinitions implements IReturn<GetAssignableAuthorisationDefinitionsResponse>
{
    createResponse() { return new GetAssignableAuthorisationDefinitionsResponse(); }
    getTypeName() { return "GetAssignableAuthorisationDefinitions"; }
}

// @Route("/api/user", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetUser implements IReturn<GetUserResponse>
{
    createResponse() { return new GetUserResponse(); }
    getTypeName() { return "GetUser"; }
}

// @Route("/login", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetLoginRedirect
{
    /**
    * The URL that the login redirect was requested from.
    */
    // @ApiMember(DataType="string", Description="The URL that the login redirect was requested from.", Name="ReferrerUrl", ParameterType="query")
    referrerUrl: string;
}

// @Route("/logout", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetLogoutRedirect
{
}

// @Route("/api/version-guid", "GET")
export class GetVersionGuid implements IReturn<GetVersionGuidResponse>
{
    createResponse() { return new GetVersionGuidResponse(); }
    getTypeName() { return "GetVersionGuid"; }
}

// @Route("/api/version-guid/update", "GET")
export class UpdateVersionGuid implements IReturn<UpdateVersionGuidResponse>
{
    createResponse() { return new UpdateVersionGuidResponse(); }
    getTypeName() { return "UpdateVersionGuid"; }
}

// @Route("/api/address/{SearchTerm}", "GET")
export class AutoCompleteAddress implements IReturn<AutoCompleteAddressResponse>
{
    searchTerm: string;
    createResponse() { return new AutoCompleteAddressResponse(); }
    getTypeName() { return "AutoCompleteAddress"; }
}

export class GetBoardNominees implements IReturn<GetBoardNomineesResponse>, IHasUserGuid
{
    guid: string;
    createResponse() { return new GetBoardNomineesResponse(); }
    getTypeName() { return "GetBoardNominees"; }
}

export class GetUserHasBoardVoted implements IReturn<GetUserHasBoardVotedResponse>, IHasUserGuid
{
    guid: string;
    username: User;
    createResponse() { return new GetUserHasBoardVotedResponse(); }
    getTypeName() { return "GetUserHasBoardVoted"; }
}

export class GetBoardVotes implements IReturn<GetBoardVotesResponse>
{
    createResponse() { return new GetBoardVotesResponse(); }
    getTypeName() { return "GetBoardVotes"; }
}

export class SubmitBoardVote implements IReturn<SubmitBoardVoteResponse>, IHasUserGuid
{
    guid: string;
    boardNominees: BoardNominee[];
    createResponse() { return new SubmitBoardVoteResponse(); }
    getTypeName() { return "SubmitBoardVote"; }
}

// @Route("/api/cap-review/current", "GET")
export class GetCurrentCapReviews implements IReturn<GetCurrentCapReviewsResponse>
{
    createResponse() { return new GetCurrentCapReviewsResponse(); }
    getTypeName() { return "GetCurrentCapReviews"; }
}

// @Route("/api/cap-review/{DocId}", "GET")
export class GetCapReview implements IReturn<GetCapReviewResponse>
{
    docId: number;
    createResponse() { return new GetCapReviewResponse(); }
    getTypeName() { return "GetCapReview"; }
}

// @Route("/api/cap-review/{DocId}/questions", "GET")
export class GetCapReviewQuestions implements IReturn<GetCapReviewQuestionsResponse>
{
    docId: number;
    createResponse() { return new GetCapReviewQuestionsResponse(); }
    getTypeName() { return "GetCapReviewQuestions"; }
}

// @Route("/api/cap-review/{DocId}/question/{QId}/comments", "GET")
export class GetCapReviewComments implements IReturn<GetCapReviewCommentsResponse>
{
    docId: number;
    qId: number;
    createResponse() { return new GetCapReviewCommentsResponse(); }
    getTypeName() { return "GetCapReviewComments"; }
}

// @Route("/api/cap-review/comment/save", "POST")
export class SaveCapReviewComment implements IReturn<SaveCapReviewCommentResponse>
{
    capReviewComment: CapReviewComment;
    createResponse() { return new SaveCapReviewCommentResponse(); }
    getTypeName() { return "SaveCapReviewComment"; }
}

// @Route("/api/cap-review/{DocId}/print", "GET")
export class GetCapReviewPrint implements IReturn<GetCapReviewPrintResponse>
{
    docId: number;
    createResponse() { return new GetCapReviewPrintResponse(); }
    getTypeName() { return "GetCapReviewPrint"; }
}

// @Route("/api/diary/postOrder", "POST")
export class PostDiaryOrder implements IReturn<PostDiaryOrderResponse>
{
    diaryOrder: DiaryOrder;
    createResponse() { return new PostDiaryOrderResponse(); }
    getTypeName() { return "PostDiaryOrder"; }
}

// @Route("/api/diary/exportOrders", "GET")
export class ExportDiaryOrder implements IReturn<ExportDiaryOrderResponse>
{
    fromDate: string;
    toDate: string;
    createResponse() { return new ExportDiaryOrderResponse(); }
    getTypeName() { return "ExportDiaryOrder"; }
}

// @Route("/api/donation", "GET")
// @Route("/api/donation/{EncryptedData}", "GET")
export class GetDonation implements IReturn<GetDonationResponse>
{
    encryptedData: string;
    preview: boolean;
    createResponse() { return new GetDonationResponse(); }
    getTypeName() { return "GetDonation"; }
}

// @Route("/api/donation", "GET")
// @Route("/api/donation/{EncryptedData}", "GET")
export class SubmitDonation implements IReturn<SubmitDonationResponse>
{
    donation: Donation;
    storyMessage: StoryMessage;
    emailConfiguration: EmailConfiguration;
    createResponse() { return new SubmitDonationResponse(); }
    getTypeName() { return "SubmitDonation"; }
}

export class GetDonationConfirmation implements IReturn<GetDonationConfirmationResponse>
{
    referenceNumber: string;
    receiptNumber: string;
    createResponse() { return new GetDonationConfirmationResponse(); }
    getTypeName() { return "GetDonationConfirmation"; }
}

export class GetDonationMothersDayCards implements IReturn<GetDonationMothersDayCardsResponse>
{
    referenceNumber: string;
    id: number;
    createResponse() { return new GetDonationMothersDayCardsResponse(); }
    getTypeName() { return "GetDonationMothersDayCards"; }
}

export class SendMothersDayCardEmail implements IReturn<SendMothersDayCardEmailResponse>
{
    donorEmail: string;
    donorTitle: string;
    donorFirstName: string;
    donorLastName: string;
    customPDF: boolean;
    mothersDayCard: DonationMothersDayCard;
    createResponse() { return new SendMothersDayCardEmailResponse(); }
    getTypeName() { return "SendMothersDayCardEmail"; }
}

export class UpdateDonationMothersDayCards implements IReturn<UpdateDonationMothersDayCardsResponse>
{
    mothersDayCards: DonationMothersDayCard[];
    id: number;
    createResponse() { return new UpdateDonationMothersDayCardsResponse(); }
    getTypeName() { return "UpdateDonationMothersDayCards"; }
}

export class SendDonationEmail implements IReturn<SendDonationEmailResponse>
{
    referenceNumber: string;
    email: string;
    createResponse() { return new SendDonationEmailResponse(); }
    getTypeName() { return "SendDonationEmail"; }
}

export class GetDonatedAmount implements IReturn<GetDonatedAmountResponse>
{
    startDate: string;
    endDate: string;
    createResponse() { return new GetDonatedAmountResponse(); }
    getTypeName() { return "GetDonatedAmount"; }
}

// @Route("/mothers-day-card/{Year}", "GET")
// @Route("/mothers-day-card/{Year}/{Guid}", "GET")
// @Route("/mothers-day-card/{Year}/{Guid}/{Preview}", "GET")
export class GetAnimatedMothersDayCard
{
    preview: string;
    guid: string;
    year: string;
}

// @Route("/mothers-day-card-report", "GET")
// @Route("/mothers-day-card-report/{DonationExportDate}", "GET")
export class GetMothersDayCardReport
{
    donationExportDate: string;
}

// @Route("/mothers-day-card-export", "GET")
// @Route("/mothers-day-card-export/{DonationExportDate}", "GET")
export class GetMothersDayCardExport
{
    donationExportDate: string;
}

export class GetMothersDayCardExportDates implements IReturn<GetMothersDayCardExportDatesResponse>
{
    createResponse() { return new GetMothersDayCardExportDatesResponse(); }
    getTypeName() { return "GetMothersDayCardExportDates"; }
}

export class GetDonationsWithMothersDayCardExportDates implements IReturn<GetDonationsWithMothersDayCardExportDatesResponse>
{
    createResponse() { return new GetDonationsWithMothersDayCardExportDatesResponse(); }
    getTypeName() { return "GetDonationsWithMothersDayCardExportDates"; }
}

export class PostMothersDayCardExportDateReset implements IReturn<PostMothersDayCardExportDateResetResponse>
{
    exportDate: string;
    createResponse() { return new PostMothersDayCardExportDateResetResponse(); }
    getTypeName() { return "PostMothersDayCardExportDateReset"; }
}

export class GenerateEndOfFinancialYearReceipt
{
    eofyReceipt: EofyReceipt;
}

export class GetDiaryName implements IReturn<GetDiaryNameResponse>
{
    createResponse() { return new GetDiaryNameResponse(); }
    getTypeName() { return "GetDiaryName"; }
}

export class GetDonationTotal implements IReturn<GetDonationTotalResponse>
{
    appeal: string;
    startDate: string;
    endDate: string;
    createResponse() { return new GetDonationTotalResponse(); }
    getTypeName() { return "GetDonationTotal"; }
}

export class GetResidentialAddressOptionList implements IReturn<GetResidentialAddressOptionListResponse>
{
    residentialAddressSearchTerm: string;
    createResponse() { return new GetResidentialAddressOptionListResponse(); }
    getTypeName() { return "GetResidentialAddressOptionList"; }
}

export class GetAddressInfoOutsideAustralia implements IReturn<GetAddressInfoOutsideAustraliaResponse>
{
    address: string;
    createResponse() { return new GetAddressInfoOutsideAustraliaResponse(); }
    getTypeName() { return "GetAddressInfoOutsideAustralia"; }
}

// @Route("/api/eoi/{Trial}", "POST")
export class SaveEoi implements IReturn<SaveEoiResponse>
{
    trial: EoiTrial;
    eoi: Eoi;
    createResponse() { return new SaveEoiResponse(); }
    getTypeName() { return "SaveEoi"; }
}

// @Route("/api/eoi/{Trial}/{InstCode}", "GET")
export class GetEoi implements IReturn<GetEoiResponse>
{
    trial: EoiTrial;
    id: number;
    instCode: number;
    createResponse() { return new GetEoiResponse(); }
    getTypeName() { return "GetEoi"; }
}

export class GetEoiInstitutions implements IReturn<GetEoiInstitutionsResponse>
{
    createResponse() { return new GetEoiInstitutionsResponse(); }
    getTypeName() { return "GetEoiInstitutions"; }
}

// @Route("/expression-of-interest/complete-institutions/{Trial}", "GET")
export class GetCompleteEoiInstitutions implements IReturn<GetCompleteEoiInstitutionsResponse>
{
    trial: EoiTrial;
    createResponse() { return new GetCompleteEoiInstitutionsResponse(); }
    getTypeName() { return "GetCompleteEoiInstitutions"; }
}

// @Route("/expression-of-interest/custom-institutions/{Trial}", "GET")
export class GetEoiCustomInstitutions implements IReturn<GetEoiCustomInstitutionsResponse>
{
    trial: EoiTrial;
    createResponse() { return new GetEoiCustomInstitutionsResponse(); }
    getTypeName() { return "GetEoiCustomInstitutions"; }
}

// @Route("/expression-of-interest/export/{Trial}", "GET")
export class GetEoiExport
{
    trial: EoiTrial;
}

// @Route("/api/file-import", "GET")
export class DoFileImport implements IReturn<DoFileImportResponse>
{
    createResponse() { return new DoFileImportResponse(); }
    getTypeName() { return "DoFileImport"; }
}

// @Route("/api/file/temp/upload", "POST")
export class UploadTempFile implements IReturn<UploadTempFileResponse>
{
    createResponse() { return new UploadTempFileResponse(); }
    getTypeName() { return "UploadTempFile"; }
}

// @Route("/api/file/temp/info/{Id}", "GET")
export class GetTempFileInfo implements IReturn<GetTempFileInfoResponse>
{
    id: string;
    createResponse() { return new GetTempFileInfoResponse(); }
    getTypeName() { return "GetTempFileInfo"; }
}

// @Route("/api/file/optional-roles/{ItemId}", "GET")
export class GetOptionalRoles implements IReturn<GetOptionalRolesResponse>
{
    itemId: string;
    createResponse() { return new GetOptionalRolesResponse(); }
    getTypeName() { return "GetOptionalRoles"; }
}

// @Route("/api/file/temp/get/{Id}", "GET")
export class GetTempFile
{
    id: string;
}

// @Route("/api/file/categories/{FileType}", "GET")
// @Route("/api/file/categories/{FileType}/{ItemId}", "GET")
export class GetFileCategories implements IReturn<GetFileCategoriesResponse>
{
    fileType: FileType;
    itemId: string;
    createResponse() { return new GetFileCategoriesResponse(); }
    getTypeName() { return "GetFileCategories"; }
}

// @Route("/api/file/sub-categories/{FileCategoryId}", "GET")
export class GetFileSubCategories implements IReturn<GetFileSubCategoriesResponse>
{
    fileCategoryId: number;
    createResponse() { return new GetFileSubCategoriesResponse(); }
    getTypeName() { return "GetFileSubCategories"; }
}

export class GetFileInfo implements IReturn<GetFileInfoResponse>
{
    fileId: number;
    createResponse() { return new GetFileInfoResponse(); }
    getTypeName() { return "GetFileInfo"; }
}

// @Route("/file/{Id}", "GET")
// @Route("/file/{Id}/{Slug*}", "GET")
export class GetFile
{
    id: number;
    slug: string;
}

// @Route("/file-download/{Id}", "GET")
// @Route("/file-download/{Id}/{Slug*}", "GET")
export class DownloadFile
{
    id: number;
    slug: string;
}

// @Route("/api/file/save", "POST")
export class SaveFile implements IReturn<SaveFileResponse>
{
    fileType: FileType;
    itemId: string;
    file: File;
    optionalRoles: number[];
    createResponse() { return new SaveFileResponse(); }
    getTypeName() { return "SaveFile"; }
}

// @Route("/api/file/delete", "POST")
export class DeleteFile implements IReturn<DeleteFileResponse>
{
    fileType: FileType;
    itemId: string;
    fileId: number;
    createResponse() { return new DeleteFileResponse(); }
    getTypeName() { return "DeleteFile"; }
}

export class GetFileUrl implements IReturn<GetFileUrlResponse>
{
    fileId: number;
    createResponse() { return new GetFileUrlResponse(); }
    getTypeName() { return "GetFileUrl"; }
}

// @Route("/api/category/save", "POST")
export class SaveCategory implements IReturn<SaveCategoryResponse>
{
    fileType: FileType;
    itemId: string;
    category: FileCategory;
    createResponse() { return new SaveCategoryResponse(); }
    getTypeName() { return "SaveCategory"; }
}

// @Route("/api/category/delete", "POST")
export class DeleteCategory implements IReturn<DeleteCategoryResponse>
{
    fileType: FileType;
    itemId: string;
    categoryId: number;
    createResponse() { return new DeleteCategoryResponse(); }
    getTypeName() { return "DeleteCategory"; }
}

// @Route("/api/subcategory/save", "POST")
export class SaveSubCategory implements IReturn<SaveSubCategoryResponse>
{
    fileType: FileType;
    itemId: string;
    subCategory: FileSubCategory;
    createResponse() { return new SaveSubCategoryResponse(); }
    getTypeName() { return "SaveSubCategory"; }
}

// @Route("/api/subcategory/delete", "POST")
export class DeleteSubCategory implements IReturn<DeleteSubCategoryResponse>
{
    fileType: FileType;
    itemId: string;
    subCategoryId: number;
    createResponse() { return new DeleteSubCategoryResponse(); }
    getTypeName() { return "DeleteSubCategory"; }
}

// @Route("/api/category/sort", "POST")
export class SortCategory implements IReturn<SortCategoryResponse>
{
    fileType: FileType;
    itemId: string;
    dragId: number;
    dropId: number;
    dragDirection: DragDirection;
    createResponse() { return new SortCategoryResponse(); }
    getTypeName() { return "SortCategory"; }
}

// @Route("/api/subcategory/sort", "POST")
export class SortSubCategory implements IReturn<SortSubCategoryResponse>
{
    fileType: FileType;
    itemId: string;
    categoryId: number;
    dragId: number;
    dropId: number;
    dragDirection: DragDirection;
    createResponse() { return new SortSubCategoryResponse(); }
    getTypeName() { return "SortSubCategory"; }
}

// @Route("/api/file/sort", "POST")
export class SortFile implements IReturn<SortFileResponse>
{
    fileType: FileType;
    itemId: string;
    categoryId: number;
    subcategoryId: number;
    dragId: number;
    dropId: number;
    dragDirection: DragDirection;
    createResponse() { return new SortFileResponse(); }
    getTypeName() { return "SortFile"; }
}

// @Route("/trial-files", "GET")
export class GetTrialFilesArchive
{
    ids: number[];
    fileName: string;
}

// @Route("/api/file-notifications", "GET")
export class GetFileNotifications implements IReturn<GetFileNotificationsResponse>
{
    createResponse() { return new GetFileNotificationsResponse(); }
    getTypeName() { return "GetFileNotifications"; }
}

// @Route("/api/file-notifications/update", "POST")
export class UpdateFileNotifications implements IReturn<UpdateFileNotificationsResponse>
{
    fileNotificationId: number;
    priority: FileNotificationPriority;
    comment: string;
    createResponse() { return new UpdateFileNotificationsResponse(); }
    getTypeName() { return "UpdateFileNotifications"; }
}

// @Route("/api/file-notifications/delete", "POST")
export class DeleteFileNotifications implements IReturn<DeleteFileNotificationsResponse>
{
    fileNotificationId: number;
    createResponse() { return new DeleteFileNotificationsResponse(); }
    getTypeName() { return "DeleteFileNotifications"; }
}

// @Route("/api/impact-advocate-application", "POST")
export class SubmitImpactAdvocateApplication implements IReturn<SubmitImpactAdvocateApplicationResponse>
{
    impactAdvocateApplication: ImpactAdvocateApplication;
    createResponse() { return new SubmitImpactAdvocateApplicationResponse(); }
    getTypeName() { return "SubmitImpactAdvocateApplication"; }
}

export class GetLayoutsContent implements IReturn<GetLayoutsContentResponse>
{
    ids: number[];
    createResponse() { return new GetLayoutsContentResponse(); }
    getTypeName() { return "GetLayoutsContent"; }
}

export class GetLayoutContent implements IReturn<GetLayoutContentResponse>
{
    id: number;
    createResponse() { return new GetLayoutContentResponse(); }
    getTypeName() { return "GetLayoutContent"; }
}

export class SaveLayoutContent implements IReturn<SaveLayoutContentResponse>
{
    id: number;
    layout: PageViewContent[];
    createResponse() { return new SaveLayoutContentResponse(); }
    getTypeName() { return "SaveLayoutContent"; }
}

// @Route("/api/membership/", "GET")
// @Route("/api/membership/{Guid}", "GET")
export class GetMembership implements IReturn<GetMembershipResponse>
{
    guid: string;
    createResponse() { return new GetMembershipResponse(); }
    getTypeName() { return "GetMembership"; }
}

// @Route("/api/membership/renewal", "GET")
// @Route("/api/membership/renewal/{Guid}", "GET")
export class SubmitMembershipRenewal implements IReturn<SubmitMembershipRenewalResponse>
{
    userProfile: User;
    membership: Membership;
    guid: string;
    createResponse() { return new SubmitMembershipRenewalResponse(); }
    getTypeName() { return "SubmitMembershipRenewal"; }
}

// @Route("/api/menu", "GET")
export class GetMenu implements IReturn<GetMenuResponse>
{
    createResponse() { return new GetMenuResponse(); }
    getTypeName() { return "GetMenu"; }
}

// @Route("/api/menu", "GET")
// @Route("/api/menu/{Path}", "GET")
export class GetSiblingMenuItems implements IReturn<GetSiblingMenuItemsResponse>
{
    path: string;
    createResponse() { return new GetSiblingMenuItemsResponse(); }
    getTypeName() { return "GetSiblingMenuItems"; }
}

// @Route("/api/menu-item/get", "GET")
export class GetMenuItemDetails implements IReturn<GetMenuItemDetailsResponse>
{
    createResponse() { return new GetMenuItemDetailsResponse(); }
    getTypeName() { return "GetMenuItemDetails"; }
}

// @Route("/api/menu-item/save", "POST")
export class SaveMenuItemDetails implements IReturn<SaveMenuItemDetailsResponse>
{
    menuItemDetails: MenuItemDetails;
    createResponse() { return new SaveMenuItemDetailsResponse(); }
    getTypeName() { return "SaveMenuItemDetails"; }
}

// @Route("/api/menu-item/delete", "POST")
export class DeleteMenuItemDetails implements IReturn<DeleteMenuItemDetailsResponse>
{
    menuItemId: number;
    createResponse() { return new DeleteMenuItemDetailsResponse(); }
    getTypeName() { return "DeleteMenuItemDetails"; }
}

// @Route("/api/category/sort", "POST")
export class SortMenuItem implements IReturn<SortMenuItemResponse>
{
    dragId: number;
    dropId: number;
    dragDirection: DragDirection;
    createResponse() { return new SortMenuItemResponse(); }
    getTypeName() { return "SortMenuItem"; }
}

// @Route("/api/merchandise/{IsDiaryDiscounted}", "GET")
export class GetMerchandise implements IReturn<GetMerchandiseResponse>
{
    isDiaryDiscounted: boolean;
    promoCode: string;
    createResponse() { return new GetMerchandiseResponse(); }
    getTypeName() { return "GetMerchandise"; }
}

// @Route("/api/merchandise", "POST")
export class SubmitMerchandise implements IReturn<SubmitMerchandiseResponse>
{
    merchandise: Merchandise;
    emailConfiguration: MerchandiseEmailConfiguration;
    createResponse() { return new SubmitMerchandiseResponse(); }
    getTypeName() { return "SubmitMerchandise"; }
}

// @Route("/api/merchandise/{MerchId}/{Surname}", "GET")
export class GetCompletedMerchandise implements IReturn<GetCompletedMerchandiseResponse>
{
    merchId: string;
    surname: string;
    createResponse() { return new GetCompletedMerchandiseResponse(); }
    getTypeName() { return "GetCompletedMerchandise"; }
}

export class SendMerchandiseEmail implements IReturn<SendMerchandiseEmailResponse>
{
    referenceNumber: string;
    email: string;
    emailConfiguration: MerchandiseEmailConfiguration;
    createResponse() { return new SendMerchandiseEmailResponse(); }
    getTypeName() { return "SendMerchandiseEmail"; }
}

export class SubmitMerchandiseItemStockQuantityUpdate implements IReturn<SubmitMerchandiseItemStockQuantityUpdateResponse>
{
    updatedStockQuantityList: MerchandiseItemStockQuantity[];
    createResponse() { return new SubmitMerchandiseItemStockQuantityUpdateResponse(); }
    getTypeName() { return "SubmitMerchandiseItemStockQuantityUpdate"; }
}

// @Route("/committee-list/{Committee}", "GET")
export class GetCommitteeList implements IReturn<GetCommitteeListResponse>
{
    committee: CommitteeType;
    createResponse() { return new GetCommitteeListResponse(); }
    getTypeName() { return "GetCommitteeList"; }
}

// @Route("/api/research-blog-category", "GET")
// @Route("/api/research-blog-category/{Id}", "GET")
export class GetResearchBlogCategory implements IReturn<GetResearchBlogCategoryResponse>
{
    id: number;
    createResponse() { return new GetResearchBlogCategoryResponse(); }
    getTypeName() { return "GetResearchBlogCategory"; }
}

// @Route("/api/research-blog-categories", "GET")
export class GetResearchBlogCategories implements IReturn<GetResearchBlogCategoriesResponse>
{
    createResponse() { return new GetResearchBlogCategoriesResponse(); }
    getTypeName() { return "GetResearchBlogCategories"; }
}

export class GetResearchBlogCategorySummaries implements IReturn<GetResearchBlogCategorySummariesResponse>
{
    createResponse() { return new GetResearchBlogCategorySummariesResponse(); }
    getTypeName() { return "GetResearchBlogCategorySummaries"; }
}

export class GetResearchBlogCategorySummary implements IReturn<GetResearchBlogCategorySummaryResponse>
{
    id: number;
    createResponse() { return new GetResearchBlogCategorySummaryResponse(); }
    getTypeName() { return "GetResearchBlogCategorySummary"; }
}

export class GetResearchBlogCategoryDetails implements IReturn<GetResearchBlogCategoryDetailsResponse>
{
    id: number;
    createResponse() { return new GetResearchBlogCategoryDetailsResponse(); }
    getTypeName() { return "GetResearchBlogCategoryDetails"; }
}

export class SaveResearchBlogCategoryDetails implements IReturn<SaveResearchBlogCategoryDetailsResponse>
{
    researchBlogCategoryDetails: ResearchBlogCategoryDetails;
    createResponse() { return new SaveResearchBlogCategoryDetailsResponse(); }
    getTypeName() { return "SaveResearchBlogCategoryDetails"; }
}

export class DeleteResearchBlogCategory implements IReturn<DeleteResearchBlogCategoryResponse>
{
    id: number;
    createResponse() { return new DeleteResearchBlogCategoryResponse(); }
    getTypeName() { return "DeleteResearchBlogCategory"; }
}

// @Route("/api/research-blog", "GET")
// @Route("/api/research-blog/{Id}", "GET")
export class GetResearchBlog implements IReturn<GetResearchBlogResponse>
{
    id: number;
    name: string;
    createResponse() { return new GetResearchBlogResponse(); }
    getTypeName() { return "GetResearchBlog"; }
}

// @Route("/api/research-blogs", "GET")
// @Route("/api/research-blogs/{ResearchBlogCount}", "GET")
// @Route("/api/research-blogs/{ResearchBlogCount}/{ResearchBlogSet}", "GET")
// @Route("/api/research-blogs/{ResearchBlogCount}/{ResearchBlogSet}/{LatestResearchBlog}", "GET")
// @Route("/api/research-blogs/{ResearchBlogCount}/{ResearchBlogSet}/{LatestResearchBlog}/{ResearchBlogDisplayType}", "GET")
// @Route("/api/research-blogs/{ResearchBlogCount}/{ResearchBlogSet}/{LatestResearchBlog}/{ResearchBlogDisplayType}/{Year}", "GET")
export class GetResearchBlogs implements IReturn<GetResearchBlogsResponse>
{
    researchBlogCount: number;
    researchBlogSet: number;
    latestResearchBlog: boolean;
    researchBlogCategoryId: number;
    year: number;
    createResponse() { return new GetResearchBlogsResponse(); }
    getTypeName() { return "GetResearchBlogs"; }
}

export class GetResearchBlogSummaries implements IReturn<GetResearchBlogSummariesResponse>
{
    createResponse() { return new GetResearchBlogSummariesResponse(); }
    getTypeName() { return "GetResearchBlogSummaries"; }
}

export class GetResearchBlogSummary implements IReturn<GetResearchBlogSummaryResponse>
{
    id: number;
    createResponse() { return new GetResearchBlogSummaryResponse(); }
    getTypeName() { return "GetResearchBlogSummary"; }
}

export class GetResearchBlogDetails implements IReturn<GetResearchBlogDetailsResponse>
{
    id: number;
    createResponse() { return new GetResearchBlogDetailsResponse(); }
    getTypeName() { return "GetResearchBlogDetails"; }
}

export class SaveResearchBlogDetails implements IReturn<SaveResearchBlogDetailsResponse>
{
    researchBlogDetails: ResearchBlogDetails;
    createResponse() { return new SaveResearchBlogDetailsResponse(); }
    getTypeName() { return "SaveResearchBlogDetails"; }
}

export class DeleteResearchBlog implements IReturn<DeleteResearchBlogResponse>
{
    id: number;
    createResponse() { return new DeleteResearchBlogResponse(); }
    getTypeName() { return "DeleteResearchBlog"; }
}

// @Route("/api/news-item", "GET")
// @Route("/api/news-item/{Id}", "GET")
export class GetNewsItem implements IReturn<GetNewsItemResponse>
{
    id: number;
    createResponse() { return new GetNewsItemResponse(); }
    getTypeName() { return "GetNewsItem"; }
}

// @Route("/api/news-items", "GET")
// @Route("/api/news-items/{NewsCount}", "GET")
// @Route("/api/news-items/{NewsCount}/{NewsSet}", "GET")
// @Route("/api/news-items/{NewsCount}/{NewsSet}/{LatestNews}", "GET")
// @Route("/api/news-items/{NewsCount}/{NewsSet}/{LatestNews}/{NewsDisplayType}", "GET")
// @Route("/api/news-items/{NewsCount}/{NewsSet}/{LatestNews}/{NewsDisplayType}/{Year}", "GET")
export class GetNewsItems implements IReturn<GetNewsItemsResponse>
{
    newsCount: number;
    newsSet: number;
    latestNews: boolean;
    newsDisplayType: number;
    year: number;
    createResponse() { return new GetNewsItemsResponse(); }
    getTypeName() { return "GetNewsItems"; }
}

// @Route("/api/news-item/image/get/{Id}", "GET")
export class GetNewsImage
{
    id: number;
}

export class GetNewsSummaries implements IReturn<GetNewsSummariesResponse>
{
    createResponse() { return new GetNewsSummariesResponse(); }
    getTypeName() { return "GetNewsSummaries"; }
}

export class GetNewsSummary implements IReturn<GetNewsSummaryResponse>
{
    id: number;
    createResponse() { return new GetNewsSummaryResponse(); }
    getTypeName() { return "GetNewsSummary"; }
}

export class GetNewsDetails implements IReturn<GetNewsDetailsResponse>
{
    id: number;
    createResponse() { return new GetNewsDetailsResponse(); }
    getTypeName() { return "GetNewsDetails"; }
}

export class SaveNewsDetails implements IReturn<SaveNewsDetailsResponse>
{
    newsDetails: NewsDetails;
    createResponse() { return new SaveNewsDetailsResponse(); }
    getTypeName() { return "SaveNewsDetails"; }
}

export class DeleteNews implements IReturn<DeleteNewsResponse>
{
    id: number;
    createResponse() { return new DeleteNewsResponse(); }
    getTypeName() { return "DeleteNews"; }
}

// @Route("/api/merchandise-promo/{PromoCode}", "GET")
export class ValidateMerchandisePromo implements IReturn<ValidateMerchandisePromoResponse>
{
    promoCode: string;
    createResponse() { return new ValidateMerchandisePromoResponse(); }
    getTypeName() { return "ValidateMerchandisePromo"; }
}

// @Route("/api/notice", "GET")
// @Route("/api/notice/{Id}", "GET")
export class GetNotice implements IReturn<GetNoticeResponse>
{
    id: number;
    createResponse() { return new GetNoticeResponse(); }
    getTypeName() { return "GetNotice"; }
}

// @Route("/api/notices", "GET")
// @Route("/api/notices/{NoticeCount}", "GET")
// @Route("/api/notices/{NoticeCount}/{NoticeSet}", "GET")
// @Route("/api/notices/{NoticeCount}/{NoticeSet}/{LatestNotices}", "GET")
export class GetNotices implements IReturn<GetNoticesResponse>
{
    noticeCount: number;
    noticeSet: number;
    latestNotices: boolean;
    compact: boolean;
    createResponse() { return new GetNoticesResponse(); }
    getTypeName() { return "GetNotices"; }
}

export class GetNoticeSummaries implements IReturn<GetNoticeSummariesResponse>
{
    createResponse() { return new GetNoticeSummariesResponse(); }
    getTypeName() { return "GetNoticeSummaries"; }
}

export class GetNoticeSummary implements IReturn<GetNoticeSummaryResponse>
{
    id: number;
    createResponse() { return new GetNoticeSummaryResponse(); }
    getTypeName() { return "GetNoticeSummary"; }
}

export class GetNoticeDetails implements IReturn<GetNoticeDetailsResponse>
{
    id: number;
    createResponse() { return new GetNoticeDetailsResponse(); }
    getTypeName() { return "GetNoticeDetails"; }
}

export class SaveNoticeDetails implements IReturn<SaveNoticeDetailsResponse>
{
    noticeDetails: NoticeDetails;
    createResponse() { return new SaveNoticeDetailsResponse(); }
    getTypeName() { return "SaveNoticeDetails"; }
}

export class DeleteNotice implements IReturn<DeleteNoticeResponse>
{
    id: number;
    createResponse() { return new DeleteNoticeResponse(); }
    getTypeName() { return "DeleteNotice"; }
}

// @Route("/page", "GET")
// @Route("/page/{Slug}", "GET")
// @Route("/page/id/{Id}", "GET")
export class GetPage implements IReturn<GetPageResponse>
{
    id: number;
    slug: string;
    createResponse() { return new GetPageResponse(); }
    getTypeName() { return "GetPage"; }
}

export class GetPageSummaries implements IReturn<GetPageSummariesResponse>
{
    createResponse() { return new GetPageSummariesResponse(); }
    getTypeName() { return "GetPageSummaries"; }
}

export class GetPageSummary implements IReturn<GetPageSummaryResponse>
{
    id: number;
    createResponse() { return new GetPageSummaryResponse(); }
    getTypeName() { return "GetPageSummary"; }
}

export class GetPageSlugSummaries implements IReturn<GetPageSlugSummariesResponse>
{
    id: number;
    createResponse() { return new GetPageSlugSummariesResponse(); }
    getTypeName() { return "GetPageSlugSummaries"; }
}

export class GetPageVersionSummaries implements IReturn<GetPageVersionSummariesResponse>
{
    id: number;
    createResponse() { return new GetPageVersionSummariesResponse(); }
    getTypeName() { return "GetPageVersionSummaries"; }
}

export class GetPageDetails implements IReturn<GetPageDetailsResponse>
{
    id: number;
    createResponse() { return new GetPageDetailsResponse(); }
    getTypeName() { return "GetPageDetails"; }
}

export class GetPageSlugDetails implements IReturn<GetPageSlugDetailsResponse>
{
    id: number;
    pageId: number;
    createResponse() { return new GetPageSlugDetailsResponse(); }
    getTypeName() { return "GetPageSlugDetails"; }
}

export class GetPageVersionDetails implements IReturn<GetPageVersionDetailsResponse>
{
    id: number;
    pageId: number;
    createResponse() { return new GetPageVersionDetailsResponse(); }
    getTypeName() { return "GetPageVersionDetails"; }
}

export class SavePageDetails implements IReturn<SavePageDetailsResponse>
{
    pageDetails: PageDetails;
    createResponse() { return new SavePageDetailsResponse(); }
    getTypeName() { return "SavePageDetails"; }
}

export class SavePageSlugDetails implements IReturn<SavePageSlugDetailsResponse>
{
    pageSlugDetails: PageSlugDetails;
    createResponse() { return new SavePageSlugDetailsResponse(); }
    getTypeName() { return "SavePageSlugDetails"; }
}

export class SavePageVersionDetails implements IReturn<SavePageVersionDetailsResponse>
{
    pageVersionDetails: PageVersionDetails;
    createResponse() { return new SavePageVersionDetailsResponse(); }
    getTypeName() { return "SavePageVersionDetails"; }
}

export class DeletePage implements IReturn<DeletePageResponse>
{
    id: number;
    createResponse() { return new DeletePageResponse(); }
    getTypeName() { return "DeletePage"; }
}

export class DeletePageSlug implements IReturn<DeletePageSlugResponse>
{
    id: number;
    pageId: number;
    createResponse() { return new DeletePageSlugResponse(); }
    getTypeName() { return "DeletePageSlug"; }
}

export class DeletePageVersion implements IReturn<DeletePageVersionResponse>
{
    id: number;
    pageId: number;
    createResponse() { return new DeletePageVersionResponse(); }
    getTypeName() { return "DeletePageVersion"; }
}

export class CreateNewPage implements IReturn<CreateNewPageResponse>
{
    newPage: NewPage;
    createResponse() { return new CreateNewPageResponse(); }
    getTypeName() { return "CreateNewPage"; }
}

export class GetPageView implements IReturn<GetPageViewResponse>
{
    slug: string;
    versionId: number;
    createResponse() { return new GetPageViewResponse(); }
    getTypeName() { return "GetPageView"; }
}

export class GetPageVersionLayout implements IReturn<GetPageVersionLayoutResponse>
{
    id: number;
    pageId: number;
    createResponse() { return new GetPageVersionLayoutResponse(); }
    getTypeName() { return "GetPageVersionLayout"; }
}

export class SavePageVersionLayout implements IReturn<SavePageVersionLayoutResponse>
{
    id: number;
    pageId: number;
    layout: PageViewContent[];
    createResponse() { return new SavePageVersionLayoutResponse(); }
    getTypeName() { return "SavePageVersionLayout"; }
}

export class GetPageSlugPreferred implements IReturn<GetPageSlugPreferredResponse>
{
    pageId: number;
    createResponse() { return new GetPageSlugPreferredResponse(); }
    getTypeName() { return "GetPageSlugPreferred"; }
}

export class CopyPageVersion implements IReturn<CopyPageVersionResponse>
{
    pageId: number;
    pageVersionId: number;
    createResponse() { return new CopyPageVersionResponse(); }
    getTypeName() { return "CopyPageVersion"; }
}

export class CreatePageFromVersion implements IReturn<CreatePageFromVersionResponse>
{
    pageId: number;
    pageVersionId: number;
    createResponse() { return new CreatePageFromVersionResponse(); }
    getTypeName() { return "CreatePageFromVersion"; }
}

export class GetBoardNominationProposers implements IReturn<GetBoardNominationProposersResponse>, IHasUserGuid
{
    guid: string;
    createResponse() { return new GetBoardNominationProposersResponse(); }
    getTypeName() { return "GetBoardNominationProposers"; }
}

export class SubmitBoardNomination implements IReturn<SubmitBoardNominationResponse>, IHasUserGuid
{
    guid: string;
    currentAppointments: string;
    involvement: string;
    experience: string;
    commitment: string;
    proposerUsername: string;
    seconderUsername: string;
    createResponse() { return new SubmitBoardNominationResponse(); }
    getTypeName() { return "SubmitBoardNomination"; }
}

// @Route("/api/search/{QueryString}", "GET")
// @Route("/api/search/{QueryString}/{SearchCount}", "GET")
// @Route("/api/stories/{QueryString}/{SearchCount}/{SearchSet}", "GET")
export class GetSearchResults implements IReturn<GetSearchResultsResponse>
{
    queryString: string;
    searchCount: number;
    searchSet: number;
    createResponse() { return new GetSearchResultsResponse(); }
    getTypeName() { return "GetSearchResults"; }
}

// @Route("/api/story", "GET")
// @Route("/api/story/{Id}", "GET")
export class GetStory implements IReturn<GetStoryResponse>
{
    id: number;
    createResponse() { return new GetStoryResponse(); }
    getTypeName() { return "GetStory"; }
}

// @Route("/api/stories", "GET")
// @Route("/api/stories/{StoryCount}", "GET")
// @Route("/api/stories/{StoryCount}/{StorySet}", "GET")
// @Route("/api/stories/{StoryCount}/{StorySet}/{LatestStories}", "GET")
export class GetStories implements IReturn<GetStoriesResponse>
{
    storyCount: number;
    storySet: number;
    latestStories: boolean;
    titleColorsLength: number;
    compact: boolean;
    defaultImageList: number[];
    includedTypes: number[];
    excludedTypes: number[];
    createResponse() { return new GetStoriesResponse(); }
    getTypeName() { return "GetStories"; }
}

// @Route("/story/image/{Id}", "GET")
export class GetStoryImage
{
    id: number;
}

export class SaveStoryMessage implements IReturn<SaveStoryMessageResponse>
{
    storyMessage: StoryMessage;
    createResponse() { return new SaveStoryMessageResponse(); }
    getTypeName() { return "SaveStoryMessage"; }
}

// @Route("/stories/default-images", "GET")
export class GetStoryDefaultImages implements IReturn<GetStoryDefaultImagesResponse>
{
    createResponse() { return new GetStoryDefaultImagesResponse(); }
    getTypeName() { return "GetStoryDefaultImages"; }
}

// @Route("/stories/read-csv/mothers-day", "POST")
export class ReadMothersDayStories implements IReturn<ReadMothersDayStoriesResponse>
{
    stories: MothersDayStoryImport[];
    createResponse() { return new ReadMothersDayStoriesResponse(); }
    getTypeName() { return "ReadMothersDayStories"; }
}

// @Route("/stories/import/mothers-day", "POST")
export class ImportMothersDayStories implements IReturn<ImportMothersDayStoriesResponse>
{
    stories: MothersDayStoryImport[];
    createResponse() { return new ImportMothersDayStoriesResponse(); }
    getTypeName() { return "ImportMothersDayStories"; }
}

// @Route("/api/publications/", "GET")
export class GetPublications implements IReturn<GetPublicationsResponse>
{
    year: number;
    trialId: string;
    title: string;
    author: string;
    type: number;
    createResponse() { return new GetPublicationsResponse(); }
    getTypeName() { return "GetPublications"; }
}

export class GetPublicationTypes implements IReturn<GetPublicationTypesResponse>
{
    createResponse() { return new GetPublicationTypesResponse(); }
    getTypeName() { return "GetPublicationTypes"; }
}

export class GetPublicationSummaries implements IReturn<GetPublicationSummariesResponse>
{
    createResponse() { return new GetPublicationSummariesResponse(); }
    getTypeName() { return "GetPublicationSummaries"; }
}

export class GetPublicationSummary implements IReturn<GetPublicationSummaryResponse>
{
    id: number;
    createResponse() { return new GetPublicationSummaryResponse(); }
    getTypeName() { return "GetPublicationSummary"; }
}

export class GetPublicationDetails implements IReturn<GetPublicationDetailsResponse>
{
    id: number;
    createResponse() { return new GetPublicationDetailsResponse(); }
    getTypeName() { return "GetPublicationDetails"; }
}

export class SavePublicationDetails implements IReturn<SavePublicationDetailsResponse>
{
    publicationDetails: PublicationDetails;
    createResponse() { return new SavePublicationDetailsResponse(); }
    getTypeName() { return "SavePublicationDetails"; }
}

export class DeletePublication implements IReturn<DeletePublicationResponse>
{
    id: number;
    createResponse() { return new DeletePublicationResponse(); }
    getTypeName() { return "DeletePublication"; }
}

// @Route("/publications/export/{FromMonth}/{FromYear}/{ToMonth}/{ToYear}/{PublicationTypeId}/{TrialId}", "GET")
// @Route("/publications/export/{FromMonth}/{FromYear}/{ToMonth}/{ToYear}/{PublicationTypeId}/{TrialId}/{Text}", "GET")
export class ExportPublications
{
    text: string;
    publicationTypeId: number;
    trialId: string;
    fromMonth: number;
    fromYear: number;
    toMonth: number;
    toYear: number;
}

// @Route("/api/training-course/{Id}", "GET")
export class GetTrainingCourse implements IReturn<GetTrainingCourseResponse>
{
    id: number;
    createResponse() { return new GetTrainingCourseResponse(); }
    getTypeName() { return "GetTrainingCourse"; }
}

// @Route("/api/training-course/save", "POST")
export class SubmitTrainingCourseAttempt implements IReturn<SubmitTrainingCourseAttemptResponse>
{
    trainingCourseAttempt: TrainingCourseAttempt;
    createResponse() { return new SubmitTrainingCourseAttemptResponse(); }
    getTypeName() { return "SubmitTrainingCourseAttempt"; }
}

// @Route("/api/training-course/has-passed/{Id}", "GET")
export class GetUserHasPassedTrainingCourse implements IReturn<GetUserHasPassedTrainingCourseResponse>
{
    id: number;
    createResponse() { return new GetUserHasPassedTrainingCourseResponse(); }
    getTypeName() { return "GetUserHasPassedTrainingCourse"; }
}

// @Route("/api/trial/list/{IsPublic}/{TrialStatus}", "GET")
export class GetTrialList implements IReturn<GetTrialListResponse>
{
    trialStatuses: string[];
    isPublic: boolean;
    createResponse() { return new GetTrialListResponse(); }
    getTypeName() { return "GetTrialList"; }
}

// @Route("/api/trial/public/{Trial}", "GET")
export class GetPublicTrial implements IReturn<GetPublicTrialResponse>
{
    trial: string;
    createResponse() { return new GetPublicTrialResponse(); }
    getTypeName() { return "GetPublicTrial"; }
}

// @Route("/api/trial/lookup", "GET")
export class GetTrialLookup implements IReturn<GetTrialLookupResponse>
{
    createResponse() { return new GetTrialLookupResponse(); }
    getTypeName() { return "GetTrialLookup"; }
}

// @Route("/api/trial/study/lookup", "GET")
export class GetTrialStudyLookup implements IReturn<GetTrialStudyLookupResponse>
{
    createResponse() { return new GetTrialStudyLookupResponse(); }
    getTypeName() { return "GetTrialStudyLookup"; }
}

export class GetTrialFiles implements IReturn<GetTrialFilesResponse>
{
    trialStudyId: number;
    createResponse() { return new GetTrialFilesResponse(); }
    getTypeName() { return "GetTrialFiles"; }
}

export class GetPrivateTrial implements IReturn<GetPrivateTrialResponse>
{
    trial: string;
    createResponse() { return new GetPrivateTrialResponse(); }
    getTypeName() { return "GetPrivateTrial"; }
}

export class GetPrivateTrialInstitutions implements IReturn<GetPrivateTrialInstitutionsResponse>
{
    trialId: string;
    trialStudyId: number;
    createResponse() { return new GetPrivateTrialInstitutionsResponse(); }
    getTypeName() { return "GetPrivateTrialInstitutions"; }
}

export class GetPrivateTrialContacts implements IReturn<GetPrivateTrialContactsResponse>
{
    trialId: string;
    createResponse() { return new GetPrivateTrialContactsResponse(); }
    getTypeName() { return "GetPrivateTrialContacts"; }
}

export class GetPrivateTrialContent implements IReturn<GetPrivateTrialContentResponse>
{
    trialMenuItemId: number;
    createResponse() { return new GetPrivateTrialContentResponse(); }
    getTypeName() { return "GetPrivateTrialContent"; }
}

export class GetTrialContentVersionLayout implements IReturn<GetTrialContentVersionLayoutResponse>
{
    trialMenuItemId: number;
    trialContentVersionId: number;
    createResponse() { return new GetTrialContentVersionLayoutResponse(); }
    getTypeName() { return "GetTrialContentVersionLayout"; }
}

export class SaveTrialContentVersionLayout implements IReturn<SaveTrialContentVersionLayoutResponse>
{
    trialMenuItemId: number;
    trialContentVersionId: number;
    layout: PageViewContent[];
    createResponse() { return new SaveTrialContentVersionLayoutResponse(); }
    getTypeName() { return "SaveTrialContentVersionLayout"; }
}

// @Route("/api/trial-publications/", "GET")
export class GetTrialPublications implements IReturn<GetTrialPublicationsResponse>
{
    trialId: string;
    createResponse() { return new GetTrialPublicationsResponse(); }
    getTypeName() { return "GetTrialPublications"; }
}

export class GetTrialsActiveForUser implements IReturn<GetTrialsActiveForUserResponse>
{
    createResponse() { return new GetTrialsActiveForUserResponse(); }
    getTypeName() { return "GetTrialsActiveForUser"; }
}

// @Route("/api/trial/getsubscriptions", "GET")
export class GetTrialSubscriptions implements IReturn<GetTrialSubscriptionsResponse>
{
    createResponse() { return new GetTrialSubscriptionsResponse(); }
    getTypeName() { return "GetTrialSubscriptions"; }
}

// @Route("/api/trial/getsubscription/{trialId}", "GET")
export class GetTrialSubscriptionsById implements IReturn<GetTrialSubscriptionsByIdResponse>
{
    trialId: string;
    createResponse() { return new GetTrialSubscriptionsByIdResponse(); }
    getTypeName() { return "GetTrialSubscriptionsById"; }
}

// @Route("/api/trial/getsubscriptions/{search}", "GET")
export class GetTrialSubscriptionsBySearch implements IReturn<GetTrialSubscriptionsBySearchResponse>
{
    search: string;
    createResponse() { return new GetTrialSubscriptionsBySearchResponse(); }
    getTypeName() { return "GetTrialSubscriptionsBySearch"; }
}

// @Route("/api/trial/getunsubscribed", "GET")
export class GetTrialUnsubscribed implements IReturn<GetTrialUnsubscribedResponse>
{
    createResponse() { return new GetTrialUnsubscribedResponse(); }
    getTypeName() { return "GetTrialUnsubscribed"; }
}

// @Route("/api/trial/getunsubscribed/{trialId}", "GET")
export class GetTrialUnsubscribedById implements IReturn<GetTrialUnsubscribedByIdResponse>
{
    trialId: string;
    createResponse() { return new GetTrialUnsubscribedByIdResponse(); }
    getTypeName() { return "GetTrialUnsubscribedById"; }
}

// @Route("/api/trial/postsubscription", "POST")
export class PostTrialSubscription implements IReturn<PostTrialSubscriptionResponse>
{
    trialSubscription: TrialSubscription;
    createResponse() { return new PostTrialSubscriptionResponse(); }
    getTypeName() { return "PostTrialSubscription"; }
}

// @Route("/api/trial/togglesubscription", "POST")
export class ToggleSubscription implements IReturn<ToggleSubscriptionResponse>
{
    id: number;
    subscribed: boolean;
    createResponse() { return new ToggleSubscriptionResponse(); }
    getTypeName() { return "ToggleSubscription"; }
}

// @Route("/api/trial/editsubscription", "PUT")
export class EditSubscription implements IReturn<EditSubscriptionResponse>
{
    trialSubscription: TrialSubscription;
    createResponse() { return new EditSubscriptionResponse(); }
    getTypeName() { return "EditSubscription"; }
}

// @Route("/trialsubscriptions/export/{include}", "GET")
// @Route("/trialsubscriptions/export/{include}/{search}", "GET")
export class ExportTrialSubscriptions
{
    include: boolean;
    search: string;
}

// @Route("/api/user-profile/", "GET")
// @Route("/api/user-profile/{Guid}", "GET")
export class GetUserProfile implements IReturn<GetUserProfileResponse>
{
    guid: string;
    createResponse() { return new GetUserProfileResponse(); }
    getTypeName() { return "GetUserProfile"; }
}

// @Route("/api/user-profile", "GET")
// @Route("/api/user-profile/{Guid}", "GET")
export class SubmitUserProfile implements IReturn<SubmitUserProfileResponse>
{
    userProfile: User;
    guid: string;
    createResponse() { return new SubmitUserProfileResponse(); }
    getTypeName() { return "SubmitUserProfile"; }
}

export class GetUserCompanies implements IReturn<GetUserCompaniesResponse>
{
    createResponse() { return new GetUserCompaniesResponse(); }
    getTypeName() { return "GetUserCompanies"; }
}

export class GetUserSearchResults implements IReturn<GetUserSearchResultsResponse>
{
    searchType: UserSearchType;
    searchTerm: string;
    company: string;
    createResponse() { return new GetUserSearchResultsResponse(); }
    getTypeName() { return "GetUserSearchResults"; }
}

// @Route("/api/user/auth", "GET")
// @Route("/api/user/auth/{Guid}", "GET")
export class GetUserHasAuth implements IReturn<GetUserHasAuthResponse>
{
    guid: string;
    createResponse() { return new GetUserHasAuthResponse(); }
    getTypeName() { return "GetUserHasAuth"; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @Route("/authenticate")
// @Route("/authenticate/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    provider: string;

    // @DataMember(Order=2)
    state: string;

    // @DataMember(Order=3)
    oauth_token: string;

    // @DataMember(Order=4)
    oauth_verifier: string;

    // @DataMember(Order=5)
    userName: string;

    // @DataMember(Order=6)
    password: string;

    // @DataMember(Order=7)
    rememberMe: boolean;

    // @DataMember(Order=8)
    continue: string;

    // @DataMember(Order=9)
    nonce: string;

    // @DataMember(Order=10)
    uri: string;

    // @DataMember(Order=11)
    response: string;

    // @DataMember(Order=12)
    qop: string;

    // @DataMember(Order=13)
    nc: string;

    // @DataMember(Order=14)
    cnonce: string;

    // @DataMember(Order=15)
    useTokenCookie: boolean;

    // @DataMember(Order=16)
    accessToken: string;

    // @DataMember(Order=17)
    accessTokenSecret: string;

    // @DataMember(Order=18)
    meta: { [index:string]: string; };
    createResponse() { return new AuthenticateResponse(); }
    getTypeName() { return "Authenticate"; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    userName: string;

    // @DataMember(Order=2)
    permissions: string[];

    // @DataMember(Order=3)
    roles: string[];
    createResponse() { return new AssignRolesResponse(); }
    getTypeName() { return "AssignRoles"; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    userName: string;

    // @DataMember(Order=2)
    permissions: string[];

    // @DataMember(Order=3)
    roles: string[];
    createResponse() { return new UnAssignRolesResponse(); }
    getTypeName() { return "UnAssignRoles"; }
}

// @Route("/api/instagramPosts")
export class GetInstagramPosts implements IReturn<GetInstagramPostsResponse>, IGet
{
    // @DataMember(Order=1)
    hashtag: string;
    // @DataMember(Order=2)
    limit: number;
    // @DataMember(Order=3)
    randomise: boolean;
    // @DataMember(Order=4)
    deleted: boolean;
    // @DataMember(Order=5)
    enabled: boolean;

    createResponse() { return new GetInstagramPostsResponse(); }
    getTypeName() { return "GetInstagramPosts"; }
}
