import React from 'react';
import { Container, Theme, makeStyles, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails } from '@material-ui/core';

const Arrow = require('../assets/icons/Icon_13_Arrow.svg');

interface IFaqProps {

}

const useStyles = makeStyles((theme: Theme) => ({
    faq: {
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(5)
    },
    faqContatiner: {
        display: 'flex',
        justifyContent: 'center'
    },
    faqInner: {
        width: '80%',
        '& .MuiPaper-root': {
            backgroundColor: 'transparent'
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        },
        '& .MuiExpansionPanel-root': {
            borderTop: `solid 1px ${theme.palette.primary.main}`
        },
        '& .MuiExpansionPanelSummary-content': {
            margin: '1.7rem 0'
        },
        '& .MuiExpansionPanelSummary-expandIcon.Mui-expanded': {
            transform: 'rotate(90deg)'
        },
        '& .MuiExpansionPanelSummary-root': {
            padding: '0 24px 0 0'
        },
        [theme.breakpoints.down('xs')]: {
            width: '98%'
        }
    },
    heading: {
        fontSize: '1.5rem',
        color: theme.palette.secondary.main,
        fontFamily: 'GTPressura, sans-serif',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem'
        }
    },
    header: {
        marginBottom: '4vw',
        '& h2': {
            color: theme.palette.primary.main,
            fontFamily: 'GTPressura, sans-serif',
            fontSize: '66px',
            textAlign: 'center',
            '@media (max-width:1900px)': {
                fontSize: '4.5em',
            },
            [theme.breakpoints.down('lg')]:
            {
                fontSize: '3.3vw',
            },
            [theme.breakpoints.down('md')]:
            {
                fontSize: '6.5vw'
            },
            [theme.breakpoints.down('xs')]:
            {
                fontSize: '9vw',
                padding: '1rem 0 1rem 0'
            }
        },
    },
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            style: 'underline',
            color: theme.palette.primary.main,
        }
    },
    spacer: {
        pointerEvents: 'none'
    },
}))

const Faq: React.FunctionComponent<IFaqProps> = () => {

    const classes = useStyles({});

    const [expanded, setExpanded] = React.useState('');

    const handleChange = React.useCallback((panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }, [])


    return (
        <div className={classes.faq}>
            <Container className={classes.faqContatiner}>
                <div className={classes.faqInner}>
                    <div className={classes.header}>
                        <h2>Frequently Asked Questions</h2>
                    </div>
                    <ExpansionPanel square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<img src={Arrow} alt="arrow" />}>
                            <Typography className={classes.heading}>
                                What is a BRCA1 or BRCA2 gene mutation?
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                Most inherited cases of breast cancer are associated with mutations in one of two genes: BRCA1 (BReast CAncer gene 1) and BRCA2 (BReast CAncer gene 2).
                                <br /><br />
                                Everyone has BRCA1 and BRCA2 genes. The function of the BRCA genes is to repair DNA damage in a cell and keep breast, ovarian, and other cells growing normally. 
                                An inherited mutation in a BRCA1 or BRCA2 gene means that one of the copies of the gene is not working but that the other copy can maintain the normal function. 
                                It is when the second copy of the BRCA1 or BRCA2 gene acquires a mutation that both copies are now non-functional, and the wrong protein is made. 
                                <br /><br />
                                With two non-functional copies making the wrong protein, the ability of the cell to repair DNA damage is affected. If DNA damage cannot be repaired,
                                but the cell remains alive, the cell may become cancerous by growing uncontrollably and forming a tumour mass.
                                <br /><br />
                                BRCA1 and BRCA2 gene mutations account for about 5-10% of breast cancer diagnoses in Australia today.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<img src={Arrow} alt="arrow" />}>
                            <Typography className={classes.heading}>
                                Who can this affect?
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography component="div">
                                Breast cancers associated with a BRCA1 or BRCA2 gene mutation tend to develop in younger women where the incidence becomes significant in their 30s and 40s.
                                <br /><br />
                                <span style={{ fontWeight: 'bold', fontStyle: 'underline' }}>You are more likely to have a genetic mutation linked to breast cancer if:</span>
                                <ul>
                                    <li>
                                        You have blood relatives (grandmothers, mother, sisters, aunts) on either your mother's or father's side of the family who had breast cancer diagnosed before age 40.
                                    </li>
                                    <li>
                                        There is both breast and ovarian cancer on the same side of the family or in a single individual.
                                    </li>
                                    <li>
                                        You have a relative(s) with triple-negative breast cancer diagnosed before age 50.
                                    </li>
                                    <li>
                                        There are other cancers in your family in addition to breast or ovarian cancer, such as prostate, melanoma or pancreatic cancer.
                                    </li>
                                    <li>
                                        Women in your family have had cancer in both breasts.
                                    </li>
                                    <li>
                                        You are of Ashkenazi Jewish (Eastern European) heritage.
                                    </li>
                                    <li>
                                        A man in your family has had breast cancer.
                                    </li>
                                    <li>
                                        There is a known abnormal breast cancer gene in your family.
                                    </li>
                                </ul>
                                If you think you are at high risk for a genetic mutation linked to breast cancer, see your doctor. 
                                Usually, the first person to be tested for a mutation is the person who has been affect by cancer, and if a mutation is found in that person, other family members can be tested. 
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <ExpansionPanelSummary aria-controls="panel4d-content" id="panel4d-header" expandIcon={<img src={Arrow} alt="arrow" />}>
                            <Typography className={classes.heading}>
                                What is the risk of breast cancer for women with a BRCA1 or BRCA2 gene mutation?
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                It is estimated that in Australia today, about 1 in 400 women are at the highest risk of breast cancer because they carry a BRCA1 or BRCA2 gene mutation. 
                                For these people, carrying a gene mutation is associated with an approximate 72% risk of developing breast cancer over the course of their lifetime. 
                                And they can have a risk of up to 44% of developing ovarian cancer. 
                                <br /><br />
                                Many of those at this highest risk of breast cancer will not be aware they carry a breast cancer gene mutation until they are diagnosed with breast cancer themselves or a close family member is diagnosed.
                                Each of them will have the potential to pass this inherited gene mutation to their children. 
                                <br /><br />
                                Jewish women of eastern European ancestry (Ashkenazi) are more likely to carry a BRCA1 or BRCA2 gene mutation, estimated to be present in about 1 in 40 women.
                                This is 20 times more common than in the general population.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel square expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <ExpansionPanelSummary aria-controls="panel5d-content" id="panel5d-header" expandIcon={<img src={Arrow} alt="arrow" />}>
                            <Typography className={classes.heading}>
                                How will the trial be conducted?
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                The BRCA-P trial will be led internationally by our colleagues at the Austrian Breast Cancer Study Group and conducted in Australia by Breast Cancer Trials (BCT). 
                                Professor Geoffrey Lindeman is the BCT Study Chair of the trial and the global co-Principal Investigator.
                                <br /><br />
                                Throughout Australia, 15 sites will be open to patient recruitment together with six other countries including Austria, Germany, Israel, Spain, the United Kingdom and the United States. 
                                <br /><br />
                                The international recruitment target is 2,918 participants and Australia will recruit 300 participants over a two-year period.
                                The trial will include five years of active treatment comprising six monthly injections of the drug together with annual follow ups and required tests.
                                Participants will be followed up every 12 months for a further five years of post-treatment assessment. 
                                <br /><br />
                                The trial will be a randomised, double-blind, placebo-controlled study meaning that participants will be randomly allocated to receive either the trial drug or a placebo and neither 
                                the participant nor their doctor will know who is on the study drug and who is receiving the placebo. 
                                This will ensure the trial results and reporting will be unaffected by any potential bias.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel square expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                        <ExpansionPanelSummary aria-controls="panel6d-content" id="panel6d-header" expandIcon={<img src={Arrow} alt="arrow" />}>
                            <Typography className={classes.heading}>
                                Who can participate in this clinical trial?
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography component="div">
                                The BRCA-P clinical trial is a prevention clinical trial, therefore potential participants are those who have not had breast cancer but who carry the BRCA1 gene mutation and therefore are at higher risk. 
                                <br /><br />
                                <span style={{ fontWeight: 'bold', fontStyle: 'underline' }}>Eligibility must be assessed carefully and includes the following criteria: </span>
                                <ul>
                                    <li>
                                        Women who carry a BRCA1 gene mutation and are aged 25-55 years and unaffected by breast or ovarian cancer.
                                    </li>
                                    <li>
                                        May be either pre or post-menopausal.
                                    </li>
                                    <li>
                                        Not pregnant at study entry (treatment can be paused for planned pregnancy after starting on trial).
                                    </li>
                                    <li>
                                        Have not had preventative breast surgery.
                                    </li>
                                    <li>
                                        Not taking any breast cancer preventative agents such as Tamoxifen or an Aromatase Inhibitor. 
                                    </li>
                                </ul>
                                Many people who participate in breast cancer prevention clinical trials do so in the hope that the trial results will influence and change the 
                                future for their children and following generations.
                                They may also benefit from access to the treatment being trialled, and all participants benefit from the close follow up the trial provides in monitoring their breast cancer risk. 
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel square expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                        <ExpansionPanelSummary aria-controls="panel7d-content" id="panel7d-header" expandIcon={<img src={Arrow} alt="arrow" />}>
                            <Typography className={classes.heading}>
                                Why is it only for people with the BRCA1 gene mutation?
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                The pre-clinical evidence supports potential activity for denosumab in women with a BRCA1 gene mutation. 
                                There is not sufficient data to as yet to support a clinical trial in other high-risk women, such as those with a BRCA2 gene mutation.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel square expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                        <ExpansionPanelSummary aria-controls="panel8d-content" id="panel8d-header" expandIcon={<img src={Arrow} alt="arrow" />}>
                            <Typography className={classes.heading}>
                                Why is there an age limit on this clinical trial? 
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                The incidence of breast cancer in women with a BRCA1 gene mutation is highest in their 30's-50's. 
                                Therefore the study is focusing on this age group in order to see if the incidence of breast cancer can be reduced. 
                                There is also strong pre-clinical evidence to support the evaluation of denosumab in pre-menopausal women.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel square expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                        <ExpansionPanelSummary aria-controls="panel10d-content" id="panel10d-header" expandIcon={<img src={Arrow} alt="arrow" />}>
                            <Typography className={classes.heading}>
                                Why can only women participate in this clinical trial?
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                Although the relative risk of developing male breast cancer is high for BRCA1 gene mutation carriers compared to men without a faulty gene,
                                the absolute risk of breast cancer in men is low (~1.2% to age 70; JNCI 2007). 
                                Put another way, male breast cancer in BRCA1 gene mutation carriers is rare, by comparison the average lifetime risk of breast cancer for
                                a healthy woman is ~12.5%. We would have to increase the size of the study to treat thousands of men,
                                with very little (if any) benefit seen in the vast majority. Inclusion of male subjects is not therefore feasible. 
                                However, if we do see a benefit for women, this could also be of benefit to men.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel square expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                        <ExpansionPanelSummary aria-controls="panel9d-content" id="panel9d-header" expandIcon={<img src={Arrow} alt="arrow" />}>
                            <Typography className={classes.heading}>
                                Where can I participate?
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                The BRCA-P clinical trial will be open by Breast Cancer Trials in 15 medical institutions throughout Australia.
                                You can sign up to receive updates on the trial, including when each site opens, <a className={classes.link} href="https://www.breastcancertrials.org.au/trials/brca-p/">here</a>. 
                                <br /><br />
                                The trial will open at: The Olivia Newton John Cancer Wellness and Research Centre (Austin Hospital), Ballarat Oncology and Haematology Services, 
                                Concord Repatriation General Hospital, Lake Macquarie Private Hospital, Monash Medical Centre (Clayton), 
                                Prince of Wales Hospital, Royal Adelaide Hospital, Royal Brisbane and Women's Hospital, Royal Hobart Hospital, The Royal Melbourne Hospital, 
                                Royal North Shore Hospital, St John of God Hospital Subiaco, St Vincent's Hospital Sydney, Westmead Hospital and Wollongong Hospital. 
                                <br /><br />
                                See the contact details for each site <a className={classes.link} target="_blank" href="https://www.breastcancertrials.org.au/brca-p-site-contact-details/" rel="noopener noreferrer">here</a>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel square expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                        <ExpansionPanelSummary aria-controls="panel12d-content" id="panel12d-header" expandIcon={<img src={Arrow} alt="arrow" />}>
                            <Typography className={classes.heading}>
                                I have a strong family history of breast cancer. How do I find out if I have a BRCA gene mutation?
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                Genetic testing is often offered to women who are diagnosed with breast cancer at an early age (35 and under), who have a strong family history or who are 
                                diagnosed with certain types of breast cancer such as 'triple negative breast cancer'. 
                                If a gene mutation such as in BRCA1 or BRCA2 (the most common types) is identified, this can be associated with a family history of breast, ovarian, prostate 
                                and some other types of cancer. It is important to note that family history can come from your mother or father's side of the family. 
                                <br /><br />
                                Genetic testing is offered only through a Familial Cancer Clinic. If a woman is referred to a Familial Cancer Clinic, the availability, limitations, potential benefits,
                                and possible consequences of genetic testing will be discussed with her. If you are concerned about your strong family history of breast cancer, 
                                or you have a close family member with a BRCA gene mutation, speak with your doctor about a referral to a Familial Cancer Clinic.
                                <br /><br />
                                Remember, having a BRCA1 or BRCA2 gene mutation doesn't mean you will be diagnosed with breast cancer and if one family member has a genetic mutation linked to breast cancer,
                                it does not mean that all family members will have it.
                                <br /><br />
                                You can learn more about genetic testing <a className={classes.link} target="_blank" href="https://www.breastcancertrials.org.au/genetic-testing-for-breast-cancer/" rel="noopener noreferrer">here.</a>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel square expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                        <ExpansionPanelSummary aria-controls="panel13d-content" id="panel13d-header" expandIcon={<img src={Arrow} alt="arrow" />}>
                            <Typography className={classes.heading}>
                                Will this research help to prevent other cancers as well?
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                The BRCA-P clinical trial may also lead to significant information related to the treatment of ovarian cancer, other types of cancer and bone related issues such as osteoporosis, 
                                as well as lead to further research into the BRCA2 gene mutation.
                                In this way, the results of one clinical trial may affect many thousands of people across different areas of medical research and community concern.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel square expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                        <ExpansionPanelSummary aria-controls="panel14d-content" id="panel14d-header" expandIcon={<img src={Arrow} alt="arrow" />}>
                            <Typography className={classes.heading}>
                                How can I help? 
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                Breast Cancer Trials understands that what is learned from this prevention trial will be very important to many people, 
                                in particular individuals and families who carry the BRCA1 gene mutation. 
                                <br /><br />
                                If you think you meet the eligibility criteria for the BRCA-P clinical trial, please speak with your Familial Cancer Centre or
                                GP about referral to a site that is participating in the BRCA-P clinical trial. 
                                <br /><br />
                                The BRCA-P clinical trial will cost about $7.5 million to conduct in Australia. The National Health and Medical Research Council
                                has supported the trial with a peer-reviewed grant of $2.8 million, indicating the value of this research to the long-term health of Australians.
                                <br /><br />
                                However, more funding is needed to ensure this important research is conducted. 
                                 <br /><br />
                                If you can help, please make a donation to the trial <a className={classes.link} href="https://www.breastcancertrials.org.au/donate-to-breast-cancer-research/">here</a>. 
                                By joining our Regular Giving Program and committing to a monthly gift, you'll be investing in a brighter future for our families and loved ones.  
                                 <br /><br />
                                If you'd like to be kept informed about the BRCA-P clinical trial and its progress, please sign up to receive our BRCA-P newsletter
                                <a className={classes.link} href="https://www.breastcancertrials.org.au/trials/brca-p/"> here</a>.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel className={classes.spacer}>
                        <ExpansionPanelSummary aria-controls="panel15d-content" id="panel15d-header">
                        </ExpansionPanelSummary>
                    </ExpansionPanel>
                </div>
            </Container>
        </div>
    );
};

export default Faq;
