enum IconEnums {
    FemaleSymbol = 0,
    Gene = 1,
    Star = 2,
    Breasts = 3,
    Women = 4,
    Nurse = 5,
    Microscope = 6,
    Donate = 7,
    Ribbon = 8,
    Mail = 9,
    Location = 10,
    Hospitals = 11,
    Arrow = 12,
    Comma = 13,
    VideoPlayButton = 14,
    Woman = 15,
    Share = 16,
    StaticWomen =17,
    StaticWoman = 18,
    StaticNurse = 19,
    StaticGene = 20,
    StaticBreasts = 21,
    StaticHospitals = 22,
    ShirtIcon = 23,
    Phone = 24
}

export default IconEnums;