import React from 'react';
import { makeStyles, Theme, Container } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'font-awesome/css/font-awesome.min.css';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import Form from './Form'
import SocialButtons from './SocialButtons';
import SignUpButton from './SignUpButton';
import appSettings from '../helpers/appSettings';
const { detect } = require('detect-browser');

interface IFooterProps {

}

interface IFooterStyleProps {
    browserName: string
    errorFlag: boolean;
    darkButton: boolean;
}



const useStyles = makeStyles((theme: Theme) => ({
    footer: {
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'row',
    },
    footerContainer: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(8),
        marginLeft: '10%',
        [theme.breakpoints.down('lg')]: {
            marginLeft: '5%',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.spacing(180)
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '10vw',
            paddingTop: '8vw'
        }
    },
    footerLeft: {
        display: 'flex',
        flexDirection: 'column',
        width: "55%",
        justifyContent: 'space-between'
    },
    footerLeftTop: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.spacing(6)
    },
    footerLeftBottom: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: '5rem',
        [theme.breakpoints.down('sm')]: {
            padding: `0 ${theme.spacing(2)}px`,
            flexDirection: 'column',
        },
        [theme.breakpoints.down('xs')]:{
            padding: '0 0'
        }
    },
    footerText: {
        width: (props: IFooterStyleProps) => props.browserName === "ie" ? '40rem' : '',
        marginLeft: (props: IFooterStyleProps) => props.browserName === "ie" ? '3rem' : '',
        color: 'white',
        justifyContent: 'center',
        textAlign: 'center',
    },
    footerLinks: {
        textAlign: 'left',
        '& a': {
            color: 'white !important' as 'white',
            '&:hover': {
                color: '#eeeeee !important' as '#eeeeee',
            },
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 'small'
        }
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }

    },
    footerRight: {
        display: 'inline-flex',
        flexDirection: 'column',
        marginRight: "4rem",
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    footerImageContainer: {
        height: 'auto',
        width: '16rem',
        '& img': {
            width: '100%'
        },
        marginRight: theme.spacing(5)

    },
    footerImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    contactInfoContainer: {
        display: 'inline-flex',
        flexDirection: 'column',

    },
    contactInfo: {
        color: 'white',
        fontSize: 'large',
        display: "flex",
    },
    iconClass: {
        fontSize: 'x-large',

        [theme.breakpoints.down('sm')]: {
            fontSize: '5vw'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '9vw'
        }
    },
    iconContainer: {
        display: "flex",
        paddingRight: theme.spacing(1.5),
        color: "white",
        alignItems: 'center',
        marginBottom: '5px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 6vw'
        },
        '& a': {
            color: "white",
        }
    },
    contactLine: {
        display: 'flex',
        flexDirection: 'row',
        "& div": {
            color: "white"
        },
        padding: theme.spacing(.5)
    },
    bottom: {
        color: "white"
    },
    gridContainer: {
        display: (props: IFooterStyleProps) => props.browserName !== "ie" ? 'grid' : '-ms-grid',
        gridTemplateColumns: 'auto auto',
        msGridColumns: 'auto auto',
        [theme.breakpoints.up('lg')]: {
            marginTop: '20px'
        },

        '& div:nth-of-type(1)': {
            msGridRow: 1,
            msGridColumn: 1,
        },
        '& div:nth-of-type(2)': {
            msGridRow: 1,
            msGridColumn: 2,
        },
        '& div:nth-of-type(3)': {
            msGridRow: 2,
            msGridColumn: 1,
        },
        '& div:nth-of-type(4)': {
            msGridRow: 2,
            msGridColumn: 2,
        },
        '& div:nth-of-type(5)': {
            msGridRow: 3,
            msGridColumn: 1,
        },
        '& div:nth-of-type(6)': {
            msGridRow: 3,
            msGridColumn: 2,
        },
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    mobileIcons: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(3)
    }
}));

const headerLogoStickyImg = require('../assets/img/white-logo-transparent.png');


const Footer: React.FunctionComponent<IFooterProps> = () => {

    const browser = detect();
    const browserName = browser.name;
    const classes = useStyles({browserName});

    const getCurrentYear = () => {
        return (new Date()).getFullYear();
    }

    const showSignupForm = appSettings.showSignupForm;

    return (
        <div className={classes.footer}>
            <Container className={classes.footerContainer}>
                <div className={classes.flexRow}>
                    <div className={classes.footerLeft}>
                        <div className={classes.footerLeftTop}>
                            <div className={classes.footerImageContainer}>
                                <img src={headerLogoStickyImg} alt="Breast Cancer Trials" />
                            </div>
                            <div className={classes.gridContainer}>
                                <div className={classes.iconContainer}>
                                    <FontAwesomeIcon className={classes.iconClass} icon={faPhone} />
                                </div>
                                <div className={classes.contactInfo}>
                                    1800 423 444
                                </div>
                                <div className={classes.iconContainer}>
                                    <FontAwesomeIcon className={classes.iconClass} icon={faMapMarkerAlt} />
                                </div>
                                <div className={classes.contactInfo}>
                                    Level 4/175 Scott St Newcastle NSW 2300
                                </div>
                                <div className={classes.iconContainer}>
                                    <FontAwesomeIcon className={classes.iconClass} icon={faEnvelope} />
                                </div>
                                <div className={classes.contactInfo}>
                                    media@bctrials.org.au
                                </div>
                            </div>
                        </div>
                        <div className={classes.footerLeftBottom}>
                            <div className={classes.footerText}>
                                <div className={classes.footerLinks}>
                                    <a href="/file/527/bct-disclaimer" target="_blank" rel="noopener noreferrer">Disclaimer</a>{" "}
                                    <a href="/file/1723/bct-privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>{" "}
                                </div>
                                <div className={classes.footerLinks}>
                                    Copyright &copy; {getCurrentYear()} ANZ Breast Cancer Trials Group Ltd | <a href="https://abr.business.gov.au/ABN/View?id=64051369496" target="_blank" rel="noopener noreferrer"><span style={{ whiteSpace: "nowrap" }}>ABN 64 051 369 496</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.footerRight}>
                        <p style={{ color: "white", fontSize: "2rem" }}>BRCA-P Newsletter</p>
                        {
                            showSignupForm ?
                                <Form /> :
                                <SignUpButton />
                        }
                        <SocialButtons />
                    </div>
                </div>
                <div className={classes.mobile}>
                    {/*<div className={classes.mobileIcons}>*/}
                    {/*    <div className={classes.iconContainer}>*/}
                    {/*        <a href="tel:1800423444"><FontAwesomeIcon className={classes.iconClass} icon={faPhone} /></a>*/}
                    {/*    </div>*/}
                    {/*    <div className={classes.iconContainer} onClick={() => mapsSelector()}>*/}
                    {/*        <FontAwesomeIcon className={classes.iconClass} icon={faMapMarkerAlt} />*/}
                    {/*    </div>*/}
                    {/*    <div className={classes.iconContainer}>*/}
                    {/*        <a href="mailto:media@bctrials.org.au"><FontAwesomeIcon className={classes.iconClass} icon={faEnvelope} /></a>*/}
                    {/*    </div>*/}
                    {/*    <div className={classes.iconContainer} onClick={() => share()}>*/}
                    {/*        <FontAwesomeIcon className={classes.iconClass} icon={faShareAlt} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={classes.footerLeftBottom}>*/}
                    {/*    <div className={classes.footerText}>*/}
                    {/*        <div className={classes.footerLinks}>*/}
                    {/*            <a href="/file/527/bct-disclaimer" target="_blank" rel="noopener noreferrer">Disclaimer</a>{" "}*/}
                    {/*            | <a href="/file/1723/bct-privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>{" "}*/}
                    {/*            | <a href="/file/4104/grievance-whistleblowing-disclosure" target="_blank" rel="noopener noreferrer">Whistleblowing Policy</a>*/}
                    {/*        </div>*/}
                    {/*        <div className={classes.footerLinks}>*/}
                    {/*            Copyright &copy; {getCurrentYear()} ANZ Breast Cancer Trials Group Ltd | <a href="https://abr.business.gov.au/ABN/View?id=64051369496" target="_blank" rel="noopener noreferrer">ABN 64 051 369 496</a>*/}
                    {/*        </div>*/}
                    {/*        <div style={{ display: "inline-flex", width: "95%", textAlign: "justify", fontSize: "small", paddingTop: "20px" }}>*/}
                    {/*            ANZ Breast Cancer Trials Group Limited (trading as Breast Cancer Trials) is registered as a charity*/}
                    {/*            with the Australian Charities and Not-For-Profits Commission (ACNC) and a registered tax-exempt charity*/}
                    {/*            with DGR (Deductible Gift Recipient) status issued by the Australian Taxation Office, and a*/}
                    {/*            member of the Australian Ethical Health Alliance. Donations of $2 or more are tax deductible in Australia.*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className={classes.footerImage}>*/}
                    {/*        <a href="http://www.acnc.gov.au/" target="_blank" ><img src={require('../assets/img/acnc-logo.png')} width="120" /></a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </Container>
        </div>
    );
};

export default Footer;
