import React from 'react';
import { Container, Theme, makeStyles } from '@material-ui/core';
import Background1 from '../assets/img/v2/desktop/SliderImage_01.jpg';
import BackgroundMobile1 from '../assets/img/v2/mobile/SliderImage_01.jpg';
import Background2 from '../assets/img/v2/desktop/SliderImage_04.jpg';
import { LazyLoadComponent, trackWindowScroll } from 'react-lazy-load-image-component';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css'
import classnames from 'classnames';

const { detect } = require('detect-browser');

interface ISegmentProps {
    segmentNo: number;
    scrollPosition: any;
}

interface ISegmentStyleProps {
    segmentNo: number;
    browserName: string;
}

const useStyles = makeStyles<Theme, ISegmentStyleProps>((theme: Theme) => ({
    segment: {
        backgroundImage: props => props.segmentNo === 1 ? `url(${Background1})` : props.segmentNo === 2 ? `url(${Background2})` : 'none',
        backgroundPosition: 'center bottom',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        backgroundSize: props => props.segmentNo === 3 ? '100%, 100%' : 'cover',
        [theme.breakpoints.down('xs')]: {
            backgroundImage: props => props.segmentNo === 1 ? `url(${BackgroundMobile1})` : props.segmentNo === 2 ? `url(${BackgroundMobile1})` : 'none',
            paddingBottom: props => props.segmentNo === 3 ? '0rem' : '1rem',
            paddingTop: '0',
            //backgroundSize: '100%, 100%',
            marginTop: props => props.segmentNo === 1 ? theme.spacing(14) : 'inherit'
        },

        '& p': {

            fontSize: '1.17rem',
            lineHeight: '1.6',
            [theme.breakpoints.down('md')]: {
                fontSize: '1rem',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.2rem',
            },
            [theme.breakpoints.down('xs')]: {
                fontWeight: '300',
                fontSize: '5vw',
            },
        }
    },
    segmentContainer: {
        maxWidth: props => props.segmentNo === 1 ? '95%' : '90%',
        [theme.breakpoints.down('md')]: {
            maxWidth: props => props.segmentNo === 3 ? '100%' : props.segmentNo === 1 ? '95%' : '90%',
        },
        [theme.breakpoints.down('sm')]: {
            padding: props => props.segmentNo === 3 ? '1.5vw' : props.segmentNo === 1 ? '0 !important' : '16px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: props => 0,
            margin: props => props.segmentNo === 3 ? '0' : 'inherit',
            marginTop: props => props.segmentNo === 3 || props.segmentNo === 1 ? theme.spacing(-10) : 'inherit',
            maxWidth: props => props.segmentNo === 3 || props.segmentNo === 1 ? '100%' : 'inherit',
        }

    },
    segmentContainerInner: {
        display: 'flex',
        //[theme.breakpoints.down('md')]: {
        justifyContent: 'center'
        //}
    },
    textbox: {
        marginTop: '4.2rem',
        width: '55%',
        marginBottom: '5rem',
        paddingRight: '3.1rem',
        paddingLeft: '10%',
        color: 'white',
        fontFamily: 'GTPressura, sans-serif',
        '@media (max-width:1900px)': {
            paddingLeft: '7%',
        },
        '@media (max-width:1750px)': {
            paddingLeft: '4%',
        },
        '@media (max-width:1650px)': {
            paddingLeft: theme.spacing(0),
        },
        [theme.breakpoints.down('md')]: {
            width: '80%',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '.8rem',
            marginRight: '.8rem',
            width: 'fit-content',
            padding: theme.spacing(2),
            marginTop: 0,
            marginBottom: theme.spacing(20)
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(6)
        },
        '& p': {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '18px',
            lineHeight: '30px',
            '@media (max-width:1900px)': {
                fontSize: '1.1rem',
                lineHeight: '1.6',
            },
            [theme.breakpoints.down('lg')]: {
                fontWeight: '300'
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.2rem',
                fontWeight: '400'
            }
        }
    },
    mobileHidden: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    desktopOnly: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    infoGraphics: {
        display: 'flex',
        justifyContent: 'center',
        boxSizing: props => props.browserName === 'ie' ? "content-box" : 'inherit',
    },
    primaryHeading: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        marginBottom: '4vw',
        fontFamily: 'GTPressura, sans-serif',
        lineHeight: '1.3',
        fontSize: '4rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '2.2rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.1rem',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '0px',
            fontSize: '8.5vw',
        },
    },
    whiteHeading: {
        color: 'white',
        lineHeight: '68px',
        fontSize: '54px',
        '@media (max-width:1900px)': {
            fontSize: '2.6vw',
            lineHeight: '1.3',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '2.2rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.1rem',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '0px',
            marginTop: theme.spacing(4),
            fontSize: '9vw',
        },
    },
    segmentBody: {
        marginLeft: theme.spacing(9),
        marginRight: theme.spacing(9),
        marginTop: theme.spacing(12),
        marginBottom: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '& h3': {
            color: 'white',
            marginBottom: '1rem',
            paddingLeft: theme.spacing(2),
            textAlign: 'left',
            fontSize: '54px',
            '@media (max-width:1900px)': {
                paddingLeft: theme.spacing(0),
                fontSize: '3.5em',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '2.5em'
            }
        },
        '& h4': {
            fontStyle: 'italic',
            marginBottom: '0',
            fontWeight: '400',
            fontSize: '33px',
            '@media (max-width:1900px)': {
                fontSize: '1.9rem',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.8rem'
            }
        },
        color: 'white',
        [theme.breakpoints.down('lg')]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(3),
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(6)
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3)
        }
    },
    segmentTwoBodyInner: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(10)
        }
    },
    segmentTwoText: {
        width: '627px',
        '@media (max-width:1900px)': {
            width: '600px',
        },
        [theme.breakpoints.down('lg')]: {
            width: '900px'
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    segmentTwoTextBody: {
        color: '#F1F1F1',
        padding: theme.spacing(2),
        paddingTop: theme.spacing(3),
        fontFamily: 'Roboto, sans-serif',
        '& p': {
            fontSize: '18px',
            lineHeight: '30px',
            fontWeight: 300,
            '@media (max-width:1900px)': {
                fontSize: '1rem',
                lineHeight: '1.8',
            },
            [theme.breakpoints.down('xs')]: {
                fontWeight: '500',
                fontSize: '1.2rem',
            }
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        }
    },
    subHeading: {
        background: theme.palette.primary.main,
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: 'fit-content'
    },
    InfoGraphic: {
        display: 'inline-flex',
        justifyContent: 'center',
        width: '37%',
        '& div': {
            width: 'auto'
        }
    },
    colourBlock: {
        width: '45%',
        height: '75%',
        position: 'absolute',
        bottom: '0',
        right: '0',
        backgroundColor: theme.palette.primary.main,
        zIndex: 1,
        [theme.breakpoints.down('xs')]: {
            marginBottom: '-2%',
            height: '82%',
        }
    },
    textBlock: {
        width: '430px',
        zIndex: 2,
        margin: '4vw 0',
        marginLeft: '3.5vw',
        padding: '1.5vw 2vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& p': {
            fontSize: '18px',
            lineHeight: '30px',
            '@media (max-width:1900px)': {
                fontSize: '1.2em',
                lineHeight: '1.7',
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '1.1em'
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '2vw',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '4.5vw',
                fontWeight: 500
            },
        },
        '& h3': {
            fontSize: '54px',
            lineHeight: '64px',
            '@media (max-width:1900px)': {
                fontSize: '3rem',
                lineHeight: 'normal'
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: '2.7em'
            }
        },
        '& h4': {
            fontWeight: '400',
            fontSize: '28px',
            lineHeight: '36px',
            '@media (max-width:1900px)': {
                fontSize: '1.6rem',
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '1.5rem'
            },
            marginBottom: theme.spacing(3),
            color: theme.palette.secondary.main
        },
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0),
            width: '400px',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0',
        },
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            marginTop: theme.spacing(3),
            padding: '5vw',
        }

    },
    segment3Heading: {
        color: theme.palette.primary.main,
        fontFamily: 'GTPressura, sans-serif',
        marginBottom: theme.spacing(5),
        fontSize: '4rem',
        lineHeight: 1,
        [theme.breakpoints.down('md')]: {
            fontSize: '2.2rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(4),
            fontSize: '8.5vw',
        },
    },
    segment3HeadingMobile: {
        display: 'none',
        color: theme.palette.primary.main,
        fontFamily: 'GTPressura, sans-serif',
        marginBottom: '2.5vw',
        fontSize: '4rem',
        lineHeight: 1,
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            fontSize: '4vw',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '8vw'
        }
    },
    hollowButton: {
        fontFamily: 'Roboto, sans-serif',
        textAlign: 'center',
        fontWeight: 500,
        fontSize: '1.2rem',
        backgroundColor: 'transparent',
        borderColor: theme.palette.secondary.main,
        border: 'solid 2px',
        borderRadius: '2.75rem',
        paddingLeft: '2.2rem',
        paddingRight: '2.2rem',
        paddingTop: '0.7rem',
        paddingBottom: '0.7rem',
        textDecoration: 'none',
        transform: 'scale(1, 1.1)',
        color: theme.palette.secondary.main,
        '&:hover': {
            textDecoration: 'none',
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            transitionProperty: 'color, background-color, border-color',
            transitionDuration: '150ms',
            transitionTimingFunction: 'easi-in-out',
            WebkitTransitionProperty: 'width, background-color',
            WebkitTransitionDuration: '150ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '8vw',
            paddingRight: '8vw',
            paddingTop: '2.2vw',
            paddingBottom: '2.2vw',
            border: 'solid 1.5px',
        }
    },
    hollowButtonContainer: {
        display: 'inline-flex',
        marginTop: '0.5vw',
        height: '4rem',
        alignItems: 'center',
        width: 'fit-content',
        marginBottom: '1vw',
    },
    Segment3Container: {
        width: '960px',
        height: '550px',
        position: 'relative',
        marginTop: '12%',
        marginBottom: '12%',
        '@media (max-width:1900px)': {
            width: '56rem',
            height: 'fit-content',
        },
        [theme.breakpoints.down('lg')]: {
            width: '51rem',
        },
        [theme.breakpoints.down('md')]: {
            width: '40rem',
            height: '23rem',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 'auto',
            margin: '0'
        }
    },
    firstSlideMargin: {
        [theme.breakpoints.down('md')]: {
            marginTop: '5%',
            marginBottom: '5%'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 0
        }
    },
    secondSlideMargin: {
        [theme.breakpoints.down('md')]: {
            marginTop: '5%',
            marginBottom: '5%'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 0
        }
    },
    imgContainer: {
        width: '788px',
        height: '452px',
        position: 'relative',
        zIndex: 1,
        marginTop: '5%',
        marginBottom: '4%',
        flexShrink: 0,
        '& iframe': {
            width: '97%',
            height: '97%',
            position: 'absolute',
            right: 0,
            top: 0,
            float: 'right',
            display: 'flex',
            border: 'none',
            backgroundColor: '#cccccc',
            [theme.breakpoints.down('md')]: {
                width: '97%',
                height: '95%'
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: '96%',
                height: '100%'
            }
        },
        '@media (max-width:1900px)': {
            width: '50rem',
            height: '28.8rem',
        },
        [theme.breakpoints.down('lg')]: {
            width: '40rem',
            height: '23rem',
            marginTop: '7%',
        },
        [theme.breakpoints.down('md')]: {
            width: '85%',
            height: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'auto'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '1%'
        }
    },
    block: {
        width: '45%',
        height: '75%',
        position: 'absolute',
        bottom: 0,
        left: 0,
        backgroundColor: 'white',
        zIndex: -5,
        [theme.breakpoints.down('md')]: {
            bottom: '0',
            left: '0',
        },
        [theme.breakpoints.down('sm')]: {
            left: 0,
            bottom: '-6%',
            height: '84%',
            minWidth: '45%',
            maxWidth: '45%'
        }
    },
    imgContainerDesktop: {
        width: '67rem',
        maxWidth: '67rem',
        height: '38.5rem',
        position: 'relative',
        zIndex: 1,
        '& iframe': {
            float: 'right',
            maxWidth: '65rem',
            display: 'flex',
            border: 'none',
            backgroundColor: '#cccccc',
            [theme.breakpoints.up('lg')]: {
                width: '97%',
                height: '95%'
            },
            [theme.breakpoints.down('md')]: {
                maxWidth: '93%',
                maxHeight: '91%'
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: '97%',
                height: '50vw'
            }
        },
        [theme.breakpoints.up('lg')]: {
            width: '57%',
            height: '32.5vw',
            maxHeight: '38.5rem'
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
            width: '100%',
            height: 'auto'
        },
    },
    firstSegment: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
            alignItems: 'center'
        },
        '& h1': {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(5)
            },
            [theme.breakpoints.down('xs')]: {
                marginTop: theme.spacing(3)
            },
        }
    },
    mobileOnly: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    nonMobile: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    videoContainer: {
        position: 'relative',
        paddingBottom: '56.25%'
    },
    video: {
    },
    segment3Root: {
        [theme.breakpoints.down('xs')]: {
            maxHeight: '1000px'
        }
    },
    segment3RootInner: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center'
        },
    },
    segment3ImageContainer: {
        zIndex: 2,
        position: 'relative',
        width: '100%',
        height: 'fit-content',
        paddingBottom: '56.25%',
        top: 0,
        left: 0,
        '& iframe': {
            position: 'absolute',
            width: '97%',
            border: 'none',
            height: '95%',
            [theme.breakpoints.down('md')]: {
                height: '96%'
            }
        }
    },
    swiperContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        '& .swiper-slide': {
            width: '100% !important',
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column'
            }
        },
        '& .swiper-pagination-bullets': {
            bottom: 0,
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
                marginBottom: theme.spacing(0),
            },
            [theme.breakpoints.down('xs')]: {
                marginBottom: theme.spacing(1),
            },
        },
        '& .swiper-pagination-bullet': {
            width: '13px',
            height: '13px',
            [theme.breakpoints.down('xs')]: {
                width: theme.spacing(2),
                height: theme.spacing(2)
            },
            backgroundColor: 'transparent',
            border: '1.5px #4A4A4A solid',
            opacity: 1,
            '&:focus': {
                outline: 'none'
            }
        },
        '& .swiper-pagination-bullet-active': {
            backgroundColor: '#4A4A4A'
        },
        '& .swiper-button-next': {
            opacity: 1,
            color: theme.palette.secondary.main,
            top: '90%',
            left: 'auto',
            right: theme.spacing(1),
            '&:after': {
                fontSize: '60px',
            },
            [theme.breakpoints.down('xs')]: {
                top: '97.5%',
                '&:after': {
                    fontSize: '24px',
                },
            }
        },
        '& .swiper-button-prev': {
            opacity: 1,
            color: theme.palette.secondary.main,
            top: '90%',
            right: 'auto',
            left: theme.spacing(1),
            '&:after': {
                fontSize: '60px',
            },
            [theme.breakpoints.down('xs')]: {
                top: '97.5%',
                '&:after': {
                    fontSize: '24px',
                },
            }
        },
        '& .swiper-button-disabled': {
            color: '#cccccc',
            opacity: .1,
        }
    }
}))

const Segment: React.FunctionComponent<ISegmentProps> = (props, { scrollPosition }) => {

    const embedLink = `https://www.youtube.com/embed/GU9LD9ZU1IU/?vq=hd720`;
    const samEmbedLink = `https://www.youtube.com/embed/2Hmcg_MKX5E/?vq=hd720`;
    const raeleeEmbedLink = "https://www.youtube.com/embed/WgibdRwSlFo"

    const segmentNo = props.segmentNo;
    const browser = detect();
    const browserName = browser.name;
    const classes = useStyles({ segmentNo, browserName });

    const params = {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        observer: true,
        spaceBetween: 30
    }

    return (
        <>
            
        <div className={classes.segment}>
            <Container className={classes.segmentContainer}>
                <div className={classes.segmentContainerInner}>
                        {
                            props.segmentNo === 1 ?
                                <div className={classes.firstSegment} >
                                    <div className={classes.textbox}>
                                        <h3 className={classes.whiteHeading}>Game-Changing Research to Prevent Breast Cancer in Women with a BRCA1 Gene Mutation</h3>
                                        <p>
                                            <br />The BRCA-P clinical trial has the
                                                potential to become a game-changer in
                                                the way we manage breast cancer risk,
                                                by preventing the disease from ever
                                                developing in women with the BRCA1
                                                gene mutation. <br />
                                        </p>

                                        <p>
                                            Currently, many women with the
                                            BRCA1 gene mutation undertake life
                                            changing preventative measures for
                                            breast and ovarian cancer, including
                                            the surgical removal of their breasts
                                            and ovaries. The BRCA-P clinical trial
                                            hopes to offer these women a better
                                            prevention option.<br />
                                        </p>
                                    </div>
                                    <div className={classes.imgContainer}>
                                        <div className={classes.videoContainer}>
                                            {
                                                <LazyLoadComponent
                                                    threshold={1200}
                                                    scrollPosition={scrollPosition}
                                                >
                                                    <iframe width="100%" title="video1" height="100%" src={embedLink} data-frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" data-allowfullscreen></iframe>
                                                </LazyLoadComponent>
                                            }
                                        </div>
                                        <div className={classes.block}>
                                        </div>
                                    </div>
                                </div>
                                : props.segmentNo === 2 ?
                                    <div className={classes.segmentBody}>
                                        <h3 className={classes.primaryHeading}>What is Denusomab?</h3>
                                        <div className={classes.segmentTwoBodyInner}>
                                            <div className={classes.segmentTwoText}>
                                                <div className={classes.subHeading}>
                                                    <h4>Is it safe?</h4>
                                                </div>
                                                <div className={classes.segmentTwoTextBody}>
                                                    <p>
                                                        The BRCA-P clinical trial is testing the effectiveness of using a drug called Denosumab to
                                                        decrease or prevent the risk of developing breast cancer in women who carry a BRCA1 gene mutation.
                                                    </p>
                                                    <p>
                                                        Denosumab is already approved in Australia for the treatment of osteoporosis in postmenopausal people
                                                        and for the prevention of bone-related problems in adults with bone metastases due to cancer.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={classes.segmentTwoText}>
                                                <div className={classes.subHeading}>
                                                    <h4>How does it work?</h4>
                                                </div>
                                                <div className={classes.segmentTwoTextBody}>
                                                    <p>
                                                        Denosumab is an antibody that neutralises a molecule called RANK ligand.
                                                        Switching off RANK ligand with Denosumab has been shown in other
                                                        research to strengthen bone function for people with weak bones
                                                        (osteoporosis) and to improve outcomes for women whose breast cancer has spread to the bone.
                                                    </p>
                                                    <p>
                                                        Recent laboratory studies suggest that switching off RANK ligand with
                                                        Denosumab could also target the culprit cell that gives rise to breast cancer
                                                        in women with the BRCA1 gene mutation. The BRCA1gene mutation has
                                                        been shown in the laboratory to result in a hypersensitive signalling pathway
                                                        involving RANK ligand. Denosumab may be able to switch this pathway off.
                                                    </p>
                                                    <p>
                                                        The BRCA-P prevention clinical trial has been fast-tracked from the
                                                        laboratory because of the very positive pre-clinical data seen and because a
                                                        Phase III clinical trial can provide the large numbers of participants needed
                                                        to find out if it is effective in the shortest timeframe.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : props.segmentNo === 3 ?
                                        <div className={classes.swiperContainer}>
                                            <Swiper
                                                {...params}
                                            >
                                                <div className={classes.segment3Root}>
                                                    <div className={ classes.segment3RootInner}>
                                                        <div className={classnames(classes.Segment3Container, classes.firstSlideMargin)}>
                                                            <div className={classes.segment3ImageContainer}>
                                                                {/*<img src={Segment3Video} style={{ width: '100%' }} />*/}
                                                                {
                                                                    <LazyLoadComponent
                                                                        threshold={1200}
                                                                        scrollPosition={scrollPosition}
                                                                    >
                                                                        <iframe title="video2" width="100%" height="100%" src={samEmbedLink} data-frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" data-allowfullscreen></iframe>
                                                                    </LazyLoadComponent>
                                                                }
                                                            </div>
                                                            <div className={classes.colourBlock}>
                                                            </div>
                                                        </div>

                                                        <div className={classes.textBlock}>
                                                            <h3 className={classes.segment3Heading}>Hear from someone on the trial</h3>
                                                            <h4>Samantha is a clinical trial participant. Watch her story.</h4>
                                                            <p>
                                                                Samantha was 22 when she found out she had the
                                                                BRCA1 gene mutation and has a family history of
                                                                breast cancer. She decided to participate in the <span style={{ whiteSpace: 'nowrap' }}>BRCA-P</span> trial, to help researchers find more options to prevent breast cancer.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={classes.segment3Root}>
                                                    <div className={classes.segment3RootInner}>
                                                        <div className={classnames(classes.Segment3Container, classes.secondSlideMargin)}>
                                                            <div className={classes.segment3ImageContainer}>
                                                                {
                                                                    <LazyLoadComponent
                                                                        threshold={1200}
                                                                        scrollPosition={scrollPosition}
                                                                    >
                                                                        <iframe title="video3" width="100%" height="100%" src={raeleeEmbedLink} data-frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" data-allowfullscreen></iframe>
                                                                    </LazyLoadComponent>
                                                                }
                                                            </div>
                                                            <div className={classes.colourBlock}>
                                                            </div>
                                                        </div>
                                                        <div className={classes.textBlock}>
                                                            <h3 className={classes.segment3Heading}>Hear from someone on the trial</h3>
                                                            <h4>Raelee was the first person enrolled in the trial in Australia. Watch her story.</h4>
                                                            <p>
                                                                After Raelee's mother was diagnosed with breast cancer and tested positive for the BRCA1 gene mutation,
                                                                Raelee decided to have a genetic test which confirmed her BRCA1 status.
                                                                She hopes that the results of the trial will help future generations of families like hers, who have a high risk of developing breast cancer.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Swiper>
                                        </div>
                            : null
                        }
                    </div>
                </Container>
            </div>
        </>
    );
};

export default trackWindowScroll(Segment);
