import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import InfoGraphic from './InfoGraphic';
import IconEnums from '../enumerations/icons';

interface IParticipateProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#f1f1f1',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'white'
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '1100px',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        color: theme.palette.info.light,
        fontFamily: 'Roboto, sans-serif',
        '& h2': {
            color: theme.palette.primary.main,
            fontSize: '66px',
            fontFamily: 'GTPressura, sans-serif',
            [theme.breakpoints.down('lg')]: {
                fontSize: '4em',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '9vw',
            }
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
        }
    },
    donateButton: {
        fontFamily: 'GTPressura, sans-serif',
        textAlign: 'center',
        fontWeight: 'lighter',
        fontSize: '1.7rem',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '2.75rem',
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textDecoration: 'none',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            transitionProperty: 'color, background-color',
            transitionDuration: '150ms',
            transitionTimingFunction: 'easi-in-out',
            WebkitTransitionProperty: 'width, background-color',
            WebkitTransitionDuration: '150ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            width: 'fit-content'
        }
    },
    iconContainer: {
        fontSize: '1.3rem',
        display: 'flex',
        paddingRight: theme.spacing(1),
        '& .MuiSvgIcon-root': {
            width: '1.5em',
            height: '1.4em',
        }
    },
    graphicContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '95%',
        justifyContent: 'space-around',
        marginTop: theme.spacing(8),
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    graphic: {
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
        '& a': {
            [theme.breakpoints.down('xs')]: {
                width: 'fit-content'
            },
            "&:hover": {
                textDecoration: 'none'
            }
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(4),
            width: '100%'
        }
    }
}));


const Participate: React.FunctionComponent<IParticipateProps> = () => {

    const classes = useStyles({});

    return (
        <div id="about" className={ classes.root}>
            <div className={classes.container}>
                <h2>About the BRCA-P Trial</h2>
                <div className={classes.graphicContainer}>
                    <div className={classes.graphic}>
                        <InfoGraphic
                            name={IconEnums.Location}
                            primaryHeading={true}
                            id={13}
                        >
                            The BRCA-P clinical trial aims to recruit <span>2918 women from 7 countries</span>, including 300 from Australia
                        </InfoGraphic>
                    </div>
                    <div className={classes.graphic}>
                        <InfoGraphic
                            name={IconEnums.Hospitals}
                            id={14}
                        >
                            <span>The trial is open at 15 hospitals</span> across Australia
                        </InfoGraphic>
                    </div>
                </div>
            </div>
        </div>
    )
};


export default Participate;