import React, {  useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { makeStyles, Theme } from '@material-ui/core';
import Icons from '../fileImports/icons';
import IconEnums from '../enumerations/icons';
import { useCountUp } from 'react-countup';
import "fake-smile/smil.check.js";
import "fake-smile/smil.user.js";

const { detect } = require('detect-browser');

interface IInfoGraphicProps {
    heading?: string;
    name?: IconEnums;
    primaryHeading?: boolean;
    bigIcon?: boolean;
    id: number;
}

interface IInfoGraphicStyleProps {
    primaryHeading: boolean;
    bigIcon: boolean;
    iconName: IconEnums;
    iconWidths: string[];
    iconX: string[];
    iconY: string[];
    iconWidthsMd: string[];
    iconXMd: string[];
    iconYMd: string[];
    iconWidthsXs: string[];
    iconXXs: string[];
    iconYXs: string[];
    hasHeading: boolean;
    iconHeightsXs: string[];
    browserName: string;
}

const useStyles = makeStyles<Theme, IInfoGraphicStyleProps>((theme: Theme) => ({
    infoGraphicBody: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        msGridColumns: 'auto',
        msGridRows: '300px 150px',
        gridTemplateRows: '300px 150px',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        '& p': {
            fontFamily: 'Roboto, sans-serif',
            fontWeight: '300',
            textAlign: 'center',
            fontSize: '21px !important',
            marginTop: '0',
            marginLeft: props => props.iconName === IconEnums.Location || props.iconName === IconEnums.Hospitals ? 0 : props.iconName === IconEnums.Women ? '8px' : '12px',
            marginRight: props => props.iconName === IconEnums.Location || props.iconName === IconEnums.Hospitals ? 0 : props.iconName === IconEnums.Women ? '8px' : '12px',
            '& span': {
                fontWeight: 'bold',
                color: theme.palette.primary.main
            },
            '@media (max-width:1800px)':
            {
                fontSize: '1.3em !important',
            },
            [theme.breakpoints.down('md')]:
            {
                fontSize: '2vw !important',
            },
            [theme.breakpoints.down('sm')]:
            {
                fontSize: '2.5vw !important',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '4.2vw !important',
                marginTop: '0rem',
            },
            width: props => props.browserName === 'ie' ? '20vw' : '',
        },
        '@media (max-width:1900px)':
        {
            msGridRows: '14vw 8vw',
            gridTemplateRows: '14vw 8vw',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            margin: '0 0 10% 0',
            padding: '0',
            display: 'flex',
            alignItems: 'center',
        },
        '& div:nth-of-type(1)': {
            msGridRow: 1,
            msGridColumn: 1,
        },
        '& div:nth-of-type(2)': {
            msGridRow: 3,
            msGridColumn: 1,
        },
    },
    header: {
        color: props => props.primaryHeading ? theme.palette.primary.main : theme.palette.secondary.main,
        fontFamily: 'GTPressura, sans-serif',
        fontSize: '5vw',
        textAlign: 'center',
        marginBottom: '0',
        [theme.breakpoints.down('md')]:
        {

            fontSize: '6.5vw'
        },
        [theme.breakpoints.down('xs')]:
        {
            marginTop: '0',
            fontSize: '13vw'
        }
    },
    top: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            height: 'inherit',
            maxWidth: '100%'
        }
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
        width: props => props.bigIcon ? '10rem' : 'inherit',
        [theme.breakpoints.down('xs')]: {
            marginBottom: props => props.bigIcon ? '0.5rem' : '0',
            width: props => props.bigIcon ? '6rem' : 'inherit',
            maxHeight: props => props.bigIcon ? 'none' : '4rem'
        },
        '& img': {
            width: 'inherit',
        }
    },
    svgIcon: {
        width: '100%',
        height: 'inherit',
        [theme.breakpoints.down('md')]: {
            height: props => props.hasHeading ? '18vw' : '25vw',
        },
        [theme.breakpoints.down('sm')]: {
            height: props => props.hasHeading ? '25vw' : '32vw'
        },
        [theme.breakpoints.down('xs')]: {
            height: props => props.hasHeading ? '25vw' : '35vw'
        },


    },
    image: {
        width: props => props.iconName === IconEnums.Location ? '100px' : props.iconName === IconEnums.Hospitals ? '321px'  : props.iconWidths[props.iconName],
        x: props => props.iconX[props.iconName],
        y: props => props.iconY[props.iconName],
        maxWidth: '440px',
        maxHeight: '242px',
        '@media (max-width:1900px)': {
            maxWidth: 'none',
            maxHeight: 'none',
        },
        [theme.breakpoints.down('lg')]: {
            width: props => props.iconWidths[props.iconName]
        },
        [theme.breakpoints.down('md')]: {
            width: props => 'auto',
            x: props => props.iconXMd[props.iconName],
            y: props => props.iconYMd[props.iconName],
        },
        [theme.breakpoints.down('xs')]: {
            width: props => props.iconWidthsXs[props.iconName],
            height: props => props.iconHeightsXs[props.iconName],
            x: props => props.iconXXs[props.iconName],
            y: props => props.iconYXs[props.iconName],
        }
    },
    text: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start',
        margin: props => props.iconName === IconEnums.Location || props.iconName === IconEnums.Hospitals ? 0 : '0 1.8em',
        marginTop: props => props.iconName === IconEnums.Location || props.iconName === IconEnums.Hospitals ? theme.spacing(-3) : 0,
        height: '100%',
        '@media (max-width:1900px)': {
            margin: props => props.iconName === IconEnums.Location || props.iconName === IconEnums.Hospitals ? theme.spacing(2) : 0
        },
        [theme.breakpoints.down('lg')]: {
            margin: props => props.iconName === IconEnums.Location || props.iconName === IconEnums.Hospitals ? theme.spacing(3) : 0,
        },
        [theme.breakpoints.down('xs')]: {
            margin: props => props.iconName === IconEnums.Location || props.iconName === IconEnums.Hospitals ? theme.spacing(4) : 0,
        }
    },
    countUp: {
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        color: props => props.primaryHeading ? theme.palette.primary.main : theme.palette.secondary.main,
        fontFamily: 'GTPressura, sans-serif',
        fontSize: '98px',
        textAlign: 'center',
        marginBottom: '0',
        '@media (max-width:1900px)': {
            fontSize: '6em',
        },
        [theme.breakpoints.down('md')]:
        {
            fontSize: '6.5vw'
        },
        [theme.breakpoints.down('xs')]:
        {
            marginTop: '0',
            fontSize: props => props.iconName === IconEnums.Location ? '23vw' : '3em'
        }
    },
    coutnerContainer: {
       // minWidth: '35%',
    },
    locationHeading: {
        display: props => props.iconName === IconEnums.Location ? 'none' : '',
        [theme.breakpoints.down('md')]: {
            display: props => props.iconName !== IconEnums.Location ? 'block' : 'none' 
        }
    },
    topHalf: {

    }
}))

const InfoGraphic: React.FunctionComponent<IInfoGraphicProps> = props => {

    const primaryHeading = props.primaryHeading;
    const bigIcon = props.bigIcon;
    const iconName = props.name;
    const hasHeading = props.heading ? true : false; 
    const browser = detect();
    const browserName = browser.name;

    const iconWidths: string[] = ['3.6vw', '12.5vw', '4.7vw', '12.5vw', '23vw', '15vw', '0', '0', '0', '0', '80px', '270px', '0', '0', '0', '', '0'];
    const iconX: string[] = ['42%', '24%', '40%', '24%', '0', '20%', '0', '0', '0', '0', '38%', '14.5%', '0', '0', '0', '', '0'];
    const iconY: string[] = ['25%', '10%', '30%', '10%', '15%', '17%', '0', '0', '0', '0', '35%', '22%', '0', '0', '0', '', '0'];
    const iconWidthsMd: string[] = ['11.5vw', '34vw', '4.7vw', '12.5vw', '23.6vw', '15vw', '0', '0', '0', '0', '', '', '0', '0', '0', '', '0'];
    const iconXMd: string[] = ['37%', '12%', '35%', '12%', '0', '3%', '0', '0', '0', '0', '', '', '0', '0', '0', '38%', '0'];
    const iconYMd: string[] = ['25%', '0', '30%', '0%', '15%', '17%', '0', '0', '0', '0', '', '', '0', '0', '0', '20%', '0'];
    const iconWidthsXs: string[] = ['11.5vw', '34vw', '16vw', '34vw', '23.6vw', '35vw', '0', '0', '0', '0', '', '100%', '0', '0', '0', '11vw', '0'];
    const iconHeightsXs: string[] = ['20vw', '40vw', '19vw', '40vw', '23.6vw', '30vw', '0', '0', '0', '0', '', '', '0', '0', '0', '25vw', '0'];
    const iconXXs: string[] = ['37%', '12%', '35%', '12%', '0', '15%', '0', '0', '0', '0', '', '', '0', '0', '0', '35%', '0'];
    const iconYXs: string[] = ['25%', '0%', '25%', '0%', '15%', '30%', '0', '0', '0', '0', '', '', '0', '0', '0', '15%', '0'];

    const classes = useStyles({ primaryHeading, bigIcon, iconName, iconWidths, iconX, iconY, iconWidthsMd, iconXMd, iconYMd, iconWidthsXs, iconXXs, iconYXs, hasHeading, iconHeightsXs, browserName });

    const iconId = getPropId(props.name);

    const icon = Icons[iconId]
    const { countUp, start } = useCountUp({
        start: 0,
        end: props.name === IconEnums.FemaleSymbol ? 400 : props.name === IconEnums.Star ? 40 : 2918,
        duration: props.name === IconEnums.FemaleSymbol ? 5 : props.name === IconEnums.Star ? 2 : 6,
        startOnMount: false,
    });
    
    const [animated, setAnimated] = useState(false);

    

    return (
        <div className={classes.infoGraphicBody}>
            <VisibilitySensor onChange={(isVisible) => animate(props.name, isVisible)} partialVisibility={true} >
                <div className={ classes.topHalf }>
                    <div className={classes.top}>
                        {
                            browser.name === "ie" || browser.name === "edge" ?
                                <img src={icon} alt={iconId.toString()} className={classes.image} />
                                : <object id={"svg" + props.id.toString()} data={icon} type="image/svg+xml" className={classes.image} >{iconId}</object>
                        }
                        
                    </div>
                    {
                        (props.name === IconEnums.FemaleSymbol || props.name === IconEnums.Star || props.name === IconEnums.Location) && browserName !== "ie" ?
                            <div className={classes.countUp}>
                                {props.heading}
                                <div className={classes.locationHeading}>
                                    &nbsp;
                                </div>
                                <div className={classes.coutnerContainer}>
                                    {countUp}
                                </div>
                            </div> :
                            <div className={classes.header}>{props.heading}</div>
                    }
                </div>
            </VisibilitySensor>
            <div className={classes.text}>
                <p>{props.children}</p>
            </div>
        </div>
    );

    function getPropId(iconId: IconEnums): IconEnums {
        if (browser) {
            if (browser.name === "ie" || browser.name === "edge") {
                switch (iconId) {
                    case IconEnums.Breasts:
                        return IconEnums.StaticBreasts;
                    case IconEnums.Gene:
                        return IconEnums.StaticGene;
                    case IconEnums.Hospitals:
                        return IconEnums.StaticHospitals;
                    case IconEnums.Nurse:
                        return IconEnums.StaticNurse;
                    case IconEnums.Woman:
                        return IconEnums.StaticWoman;
                    case IconEnums.Women:
                        return IconEnums.StaticWomen;
                    default:
                        return iconId;
                }
            }
            else
                return iconId;
        }
        return iconId;
    }

    function animate(iconId: number, isVisible: boolean, count: number = 2) {
        if (count === 1) {
        }
        else {
        }
        if (!animated && isVisible) {
            var a: any;
            
            if (iconId === IconEnums.FemaleSymbol || iconId === IconEnums.Star || iconId === IconEnums.Location) {
                start();
                setAnimated(true);
            }
            setTimeout(function () {
                a = document.getElementById("svg" + props.id);
                if (a) {
                    var svgElem: any;
                    setTimeout(function () {
                        svgElem = a.contentDocument;
                        if (svgElem) {
                            if (iconId === IconEnums.Breasts || iconId === IconEnums.Gene) {
                                var animation = svgElem.getElementsByTagName("animate")[0];
                                if (animation) {
                                    animation.beginElement();
                                    setAnimated(true);
                                }
                            }
                            else if (iconId === IconEnums.Women) {
                                let animation1 = svgElem.getElementById("animate-women-1");
                                let animation2 = svgElem.getElementById("animate-women-2");
                                let animation3 = svgElem.getElementById("animate-women-3");
                                let animation4 = svgElem.getElementById("animate-women-4");
                                let animation5 = svgElem.getElementById("animate-women-5");
                                let animation6 = svgElem.getElementById("animate-women-6");
                                let animation7 = svgElem.getElementById("animate-women-7");
                                if (animation1) {
                                    animation1.addEventListener('endEvent', () => {
                                        animation2.beginElement();
                                    });
                                }
                                if (animation2) {
                                    animation2.addEventListener('endEvent', () => {
                                        animation3.beginElement();
                                    });
                                }
                                if (animation3) {
                                    animation3.addEventListener('endEvent', () => {
                                        animation4.beginElement();
                                    });
                                }
                                if (animation4) {
                                    animation4.addEventListener('endEvent', () => {
                                        animation5.beginElement();
                                    });
                                }
                                if (animation5) {
                                    animation5.addEventListener('endEvent', () => {
                                        animation6.beginElement();
                                    });
                                }
                                if (animation6) {
                                    animation6.addEventListener('endEvent', () => {
                                        animation7.beginElement();
                                    });
                                }
                                if (animation1) {
                                    animation1.beginElement();
                                    setAnimated(true);
                                }
                            }
                            else if (iconId === IconEnums.Woman) {
                                let animation = svgElem.getElementById("animate-woman");;
                                if (animation) {
                                    animation.beginElement();
                                    setAnimated(true);
                                }
                            }
                            else if (iconId === IconEnums.Nurse) {
                                let animation1 = svgElem.getElementById("Group-8");
                                let animation2 = svgElem.getElementById("Group-23");
                                let animation3 = svgElem.getElementById("Group-3");

                                if (animation1 && animation2 && animation3) {
                                    let animations1 = animation1.getElementsByTagName("animate");
                                    let animations2 = animation2.getElementsByTagName("animate");
                                    let animations3 = animation3.getElementsByTagName("animate");

                                    if (animations1 && animations2 && animations3) {
                                        let length1 = animations1.length;
                                        let length2 = animations2.length;
                                        let length3 = animations3.length;

                                        animations1[length1 - 1].addEventListener('endEvent', () => {
                                            for (var i = 0; i < length2; i++) {
                                                animations2[i].beginElement();
                                            }
                                        });
                                        animations2[length2 - 1].addEventListener('endEvent', () => {
                                            for (var i = 0; i < length3; i++) {
                                                animations3[i].beginElement();
                                            }
                                        });
                                        for (var i = 0; i < length1; i++) {
                                            animations1[i].beginElement();
                                        }
                                        setAnimated(true);
                                    }
                                }
                            }
                            if (iconId === IconEnums.Hospitals) {
                                animateHospitals(svgElem);
                            }
                        }
                    },200)
                        
                    }
            }, 200);
        }
    }

    function animateHospitals(svgElem: any) {
        setTimeout(function () {
            let animations: any[] = [];

            for (var i = 1; i <= 15; i++) {
                for (var j = 1; j <= 5; j++) {
                    let className = "hospital-" + i + "-" + j;
                    animations.push(svgElem.getElementsByClassName(className));
                }
            }

            var randomArray: number[] = [];

            do {
                let randomNumber = Math.floor((Math.random() * 15));
                if (!randomArray.includes(randomNumber)) {
                    randomArray.push(randomNumber);
                }
            } while (randomArray.length < 15);
            var randomCoutner = 0;

            if (animations[71].length > 0) {
                //First Icon
                animations[0][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[1].length; i++) {
                        animations[1][i].beginElement();
                    }
                    if (randomCoutner < 15) {
                        for (var j = 0; j < animations[randomArray[randomCoutner] * 5].length; j++) {
                            animations[randomArray[randomCoutner] * 5][j].beginElement();
                        }
                        randomCoutner++;
                    }
                });
                animations[1][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[2].length; i++) {
                        animations[2][i].beginElement();
                    }
                });
                animations[2][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[3].length; i++) {
                        animations[3][i].beginElement();
                    }
                });
                animations[3][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[4].length; i++) {
                        animations[4][i].beginElement();
                    }
                });
                //Second Icon
                animations[5][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[6].length; i++) {
                        animations[6][i].beginElement();
                    }
                    if (randomCoutner < 15) {
                        for (var j = 0; j < animations[randomArray[randomCoutner] * 5].length; j++) {
                            animations[randomArray[randomCoutner] * 5][j].beginElement();
                        }
                        randomCoutner++;
                    }
                });
                animations[6][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[7].length; i++) {
                        animations[7][i].beginElement();
                    }
                });
                animations[7][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[8].length; i++) {
                        animations[8][i].beginElement();
                    }
                });
                animations[8][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[9].length; i++) {
                        animations[9][i].beginElement();
                    }
                });
                //Thrid Icon
                animations[10][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[11].length; i++) {
                        animations[11][i].beginElement();
                    }
                    if (randomCoutner < 15) {
                        for (var j = 0; j < animations[randomArray[randomCoutner] * 5].length; j++) {
                            animations[randomArray[randomCoutner] * 5][j].beginElement();
                        }
                        randomCoutner++;
                    }
                });
                animations[11][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[12].length; i++) {
                        animations[12][i].beginElement();
                    }
                });
                animations[12][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[13].length; i++) {
                        animations[13][i].beginElement();
                    }
                });
                animations[13][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[14].length; i++) {
                        animations[14][i].beginElement();
                    }
                });
                //Forth Icon
                animations[15][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[16].length; i++) {
                        animations[16][i].beginElement();
                    }
                    if (randomCoutner < 15) {
                        for (var j = 0; j < animations[randomArray[randomCoutner] * 5].length; j++) {
                            animations[randomArray[randomCoutner] * 5][j].beginElement();
                        }
                        randomCoutner++;
                    }
                });
                animations[16][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[17].length; i++) {
                        animations[17][i].beginElement();
                    }
                });
                animations[17][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[18].length; i++) {
                        animations[18][i].beginElement();
                    }
                });
                animations[18][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[19].length; i++) {
                        animations[19][i].beginElement();
                    }
                });
                //Fifth Icon
                animations[20][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[21].length; i++) {
                        animations[21][i].beginElement();
                    }
                    if (randomCoutner < 15) {
                        for (var j = 0; j < animations[randomArray[randomCoutner] * 5].length; j++) {
                            animations[randomArray[randomCoutner] * 5][j].beginElement();
                        }
                        randomCoutner++;
                    }
                });
                animations[21][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[22].length; i++) {
                        animations[22][i].beginElement();
                    }
                });
                animations[22][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[23].length; i++) {
                        animations[23][i].beginElement();
                    }
                });
                animations[23][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[24].length; i++) {
                        animations[24][i].beginElement();
                    }
                });
                //Sixth Icon
                animations[25][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[26].length; i++) {
                        animations[26][i].beginElement();
                    }
                    if (randomCoutner < 15) {
                        for (var j = 0; j < animations[randomArray[randomCoutner] * 5].length; j++) {
                            animations[randomArray[randomCoutner] * 5][j].beginElement();
                        }
                        randomCoutner++;
                    }
                });
                animations[26][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[27].length; i++) {
                        animations[27][i].beginElement();
                    }
                });
                animations[27][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[28].length; i++) {
                        animations[28][i].beginElement();
                    }
                });
                animations[28][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[28].length; i++) {
                        animations[29][i].beginElement();
                    }
                });
                //Seventh Icon
                animations[30][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[31].length; i++) {
                        animations[31][i].beginElement();
                    }
                    if (randomCoutner < 15) {
                        for (var j = 0; j < animations[randomArray[randomCoutner] * 5].length; j++) {
                            animations[randomArray[randomCoutner] * 5][j].beginElement();
                        }
                        randomCoutner++;
                    }
                });
                animations[31][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[32].length; i++) {
                        animations[32][i].beginElement();
                    }
                });
                animations[32][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[33].length; i++) {
                        animations[33][i].beginElement();
                    }
                });
                animations[33][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[34].length; i++) {
                        animations[34][i].beginElement();
                    }
                });
                //Eighth Icon
                animations[35][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[36].length; i++) {
                        animations[36][i].beginElement();
                    }
                    if (randomCoutner < 15) {
                        for (var j = 0; j < animations[randomArray[randomCoutner] * 5].length; j++) {
                            animations[randomArray[randomCoutner] * 5][j].beginElement();
                        }
                        randomCoutner++;
                    }
                });
                animations[36][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[37].length; i++) {
                        animations[37][i].beginElement();
                    }
                });
                animations[37][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[38].length; i++) {
                        animations[38][i].beginElement();
                    }
                });
                animations[38][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[39].length; i++) {
                        animations[39][i].beginElement();
                    }
                });
                //Ninth Icon
                animations[40][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[41].length; i++) {
                        animations[41][i].beginElement();
                    }
                    if (randomCoutner < 15) {
                        for (var j = 0; j < animations[randomArray[randomCoutner] * 5].length; j++) {
                            animations[randomArray[randomCoutner] * 5][j].beginElement();
                        }
                        randomCoutner++;
                    }
                });
                animations[41][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[42].length; i++) {
                        animations[42][i].beginElement();
                    }
                });
                animations[42][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[43].length; i++) {
                        animations[43][i].beginElement();
                    }
                });
                animations[43][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[44].length; i++) {
                        animations[44][i].beginElement();
                    }
                });
                //Tenth Icon
                animations[45][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[46].length; i++) {
                        animations[46][i].beginElement();
                    }
                    if (randomCoutner < 15) {
                        for (var j = 0; j < animations[randomArray[randomCoutner] * 5].length; j++) {
                            animations[randomArray[randomCoutner] * 5][j].beginElement();
                        }
                        randomCoutner++;
                    }
                });
                animations[46][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[47].length; i++) {
                        animations[47][i].beginElement();
                    }
                });
                animations[47][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[48].length; i++) {
                        animations[48][i].beginElement();
                    }
                });
                animations[48][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[49].length; i++) {
                        animations[49][i].beginElement();
                    }
                });

                //Eleventh Icon
                animations[50][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[51].length; i++) {
                        animations[51][i].beginElement();
                    }
                    if (randomCoutner < 15) {
                        for (var j = 0; j < animations[randomArray[randomCoutner] * 5].length; j++) {
                            animations[randomArray[randomCoutner] * 5][j].beginElement();
                        }
                        randomCoutner++;
                    }
                });
                animations[51][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[52].length; i++) {
                        animations[52][i].beginElement();
                    }
                });
                animations[52][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[53].length; i++) {
                        animations[53][i].beginElement();
                    }
                });
                animations[53][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[54].length; i++) {
                        animations[54][i].beginElement();
                    }
                });
                //Twelfth Icon
                animations[55][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[56].length; i++) {
                        animations[56][i].beginElement();
                    }
                    if (randomCoutner < 15) {
                        for (var j = 0; j < animations[randomArray[randomCoutner] * 5].length; j++) {
                            animations[randomArray[randomCoutner] * 5][j].beginElement();
                        }
                        randomCoutner++;
                    }
                });
                animations[56][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[57].length; i++) {
                        animations[57][i].beginElement();
                    }
                });
                animations[57][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[58].length; i++) {
                        animations[58][i].beginElement();
                    }
                });
                animations[58][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[59].length; i++) {
                        animations[59][i].beginElement();
                    }
                });
                //Thirteenth Icon
                animations[60][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[61].length; i++) {
                        animations[61][i].beginElement();
                    }
                    if (randomCoutner < 15) {
                        for (var j = 0; j < animations[randomArray[randomCoutner] * 5].length; j++) {
                            animations[randomArray[randomCoutner] * 5][j].beginElement();
                        }
                        randomCoutner++;
                    }
                });
                animations[61][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[62].length; i++) {
                        animations[62][i].beginElement();
                    }
                });
                animations[62][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[63].length; i++) {
                        animations[63][i].beginElement();
                    }
                });
                animations[63][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[64].length; i++) {
                        animations[64][i].beginElement();
                    }
                });
                //Fourteenth Icon
                animations[65][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[66].length; i++) {
                        animations[66][i].beginElement();
                    }
                    if (randomCoutner < 15) {
                        for (var j = 0; j < animations[randomArray[randomCoutner] * 5].length; j++) {
                            animations[randomArray[randomCoutner] * 5][j].beginElement();
                        }
                        randomCoutner++;
                    }
                });
                animations[66][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[67].length; i++) {
                        animations[67][i].beginElement();
                    }
                });
                animations[67][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[68].length; i++) {
                        animations[68][i].beginElement();
                    }
                });
                animations[68][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[69].length; i++) {
                        animations[69][i].beginElement();
                    }
                });
                //Fifteenth Icon
                animations[70][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[71].length; i++) {
                        animations[71][i].beginElement();
                    }
                    if (randomCoutner < 15) {
                        for (var j = 0; j < animations[randomArray[randomCoutner] * 5].length; j++) {
                            animations[randomArray[randomCoutner] * 5][j].beginElement();
                        }
                        randomCoutner++;
                    }
                });
                animations[71][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[72].length; i++) {
                        animations[72][i].beginElement();
                    }
                });
                animations[72][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[73].length; i++) {
                        animations[73][i].beginElement();
                    }
                });
                animations[73][1].addEventListener('endEvent', () => {
                    for (var i = 0; i < animations[74].length; i++) {
                        animations[74][i].beginElement();
                    }
                });

                for (var inc = 0; inc < animations[randomArray[0] * 5].length; inc++) {
                    animations[randomArray[0] * 5][inc].beginElement();
                }
                setAnimated(true);
            }
        }, 200);
        
    }

};

export default InfoGraphic;
