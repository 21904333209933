import React, { useEffect } from 'react';
import { bctClient } from '../api/bctClient';
import { IReturn, ResponseStatus } from '../api/bct.dtos';

export enum RequestState {
    None = 0,
    Pending = 1,
    Success = 2,
    Failed = 3
}

type DtoReturnType<T> = T extends IReturn<infer S> ? S : never;

const useBctGet = <DtoType extends IReturn<any>>(request: DtoType): [RequestState, DtoReturnType<DtoType> | null, ResponseStatus | null] => {
    const [state, setState] = React.useState(RequestState.None);
    
    const [response, setResponse] = React.useState<DtoReturnType<DtoType> | null>(null);
    const [error, setError] = React.useState<ResponseStatus | null>(null);
    
    useEffect(() => {
        setState(RequestState.Pending);

        bctClient
            .get(request)
            .then(response => {
                setState(RequestState.Success);
                setResponse(response);
            })
            .catch(error => {
                setState(RequestState.Failed);

                if (error && error.responseStatus) {
                    setError(error.responseStatus)
                }
            });
    }, [request])

    return [
        state,
        response,
        error
    ]
}

export default useBctGet;