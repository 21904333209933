
import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { classNames } from '@servicestack/client';


interface ISocialButtonProps {

}

interface ISocialButtonStyleProps {

}

const useStyles = makeStyles((theme: Theme) => ({
    social: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },

        width: "100%",
        overFlow: "hidden",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginTop: '20px',
        },

        '& :after': {
            content: '',
            display: 'inline-block',
            width: '100%',
            height: 0,
            fontSize: 0,
            lineHeight: 0,
            overflow: 'hidden'
        }
    },
    socialIcon: {
        
        display: "inline-flex",
        padding: theme.spacing(1),

        '& a': {
            borderRadius: '1rem',
            width: '28px',
            height: '28px',
            lineHeight: '24px',
            display: 'inline-flex',
            justifyContent: 'center',

            '& i': {
                color: theme.palette.primary.main
            }
        }
    },
}));

const SocialButtons: React.FunctionComponent<ISocialButtonProps> = () => {

    const classes = useStyles({});

    const socialButtonClass = classNames(classes.social, 'no-gutters')

    return <div className={socialButtonClass}>
        <div className={classes.socialIcon}>
            <a href="https://www.facebook.com/BreastCancerTrials" target="_blank" rel="noreferrer noopener" className="btn btn-circle btn-light text-secondary">
                <i className="fa fa-fw fa-facebook-official" aria-hidden="true"></i>
            </a>
        </div>
            
        <div className={classes.socialIcon}>
            <a href="https://www.youtube.com/channel/UCoVl5WNMfqrJSpggZVzsa6g" target="_blank" rel="noreferrer noopener" className="btn btn-circle btn-light text-secondary">
                <i className="fa fa-fw fa-youtube-play" aria-hidden="true"></i>
            </a>
        </div> 
        <div className={classes.socialIcon}>
            <a href="https://www.twitter.com/BCTrialsANZ" target="_blank" rel="noreferrer noopener" className="btn btn-circle btn-light text-secondary">
                <i className="fa fa-fw fa-twitter" aria-hidden="true"></i>
            </a>
        </div>
        <div className={classes.socialIcon}>
            <a href="https://www.instagram.com/breastcancertrials/" target="_blank" rel="noreferrer noopener" className="btn btn-circle btn-light text-secondary">
                <i className="fa fa-fw fa-instagram" aria-hidden="true"></i>
            </a>
        </div> 
        <div className={classes.socialIcon}>
            <a href="https://www.linkedin.com/company/5057154/" target="_blank" rel="noreferrer noopener" className="btn btn-circle btn-light text-secondary">
                <i className="fa fa-fw fa-linkedin" aria-hidden="true"></i>
            </a>
        </div> 
        <div className={classes.socialIcon}>
            <a href="https://www.whooshkaa.com/shows/breast-cancer-trials" target="_blank" rel="noreferrer noopener" className="btn btn-circle btn-light text-secondary">
                <i className="fa fa-fw fa-headphones" aria-hidden="true"></i>
            </a>
        </div> 
    </div>

};
export default SocialButtons;